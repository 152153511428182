import { GeneratorEnum } from "Context/OrderContext";
import { ENDPOINT } from "Global/Constants/environment";
import axios from "axios";

interface GeneratorOptions {
    generatorFileName: string;
    activityName: string;
    isCheck?: boolean;
    url: string;
}
export async function runGenerator(
    generator: GeneratorEnum,
    browserConnectionID: string,
    formValues: any,
    orderNumber: string,
    uuid?: string,
    isCheck?: boolean
) {
    const url = `${ENDPOINT}/forge`;

    console.log(isCheck);
    const generatorOptions = getGeneratorOptions(generator, isCheck !== undefined ? isCheck : false);
    //TODO: Probably not the best way to handle this
    let data = {
        ...formValues,
        orderNumber,
    };

    if (uuid) {
        Object.assign(data, { data: uuid });
    }

    let response = await axios.post(`${url}/${generatorOptions.url}`, {
        ...generatorOptions,
        browserConnectionID,
        data: {
            ...data,
        },
    });
    if (response.status === 200) {
        return true;
    } else {
        return false;
    }
}

function getGeneratorOptions(generator: GeneratorEnum, isCheck: boolean): GeneratorOptions {
    let options: GeneratorOptions = {
        generatorFileName: "",
        activityName: "",
        isCheck: false,
        url: "",
    };
    switch (generator) {
        case GeneratorEnum.MultiTreads:
            options.generatorFileName = "unifiedTreadGenerator";
            options.activityName = "UnifiedTreadGeneratorActivity+dev";
            options.url = "treadWorkitem";
            break;
        case GeneratorEnum.Landing:
            options.generatorFileName = "landingGenerator";
            options.activityName = "LandingGeneratorActivity+dev";
            options.url = "landingWorkitem";
            break;
        case GeneratorEnum.LandingTread:
            options.generatorFileName = "landingTreadGenerator";
            options.activityName = "LandingTreadGeneratorActivity+dev";
            options.url = "landingTreadWorkitem";
            break;
        case GeneratorEnum.Riser:
            options.generatorFileName = "riserGenerator";
            options.activityName = "RiserGeneratorActivity+dev";
            options.url = "riserWorkitem";
            break;
        case GeneratorEnum.S4S:
            options.generatorFileName = "s4sGenerator";
            options.activityName = "S4SGeneratorActivity+dev";
            options.url = "s4sWorkitem";
            break;
        case GeneratorEnum.Gate:
            options.generatorFileName = "gateGenerator";
            options.activityName = "GateGeneratorActivity+dev";
            options.url = "gateWorkitem";
            break;
        case GeneratorEnum.Flight:
            if (isCheck) {
                options.generatorFileName = "flightGeneratorCheck";
                options.activityName = "FlightCheckActivity+dev";
                options.isCheck = true;
                options.url = "wallToWallWorkitem";
            } else {
                options.generatorFileName = "flightGenerator";
                options.activityName = "FlightGeneratorActivity+dev";
                options.url = "wallToWallWorkitem";
            }
            break;
        case GeneratorEnum.StraightFlight:
            if (isCheck) {
                options.generatorFileName = "straightFlightGeneratorCheck";
                options.activityName = "StraightFlightCheckActivity+dev";
                options.isCheck = true;
                options.url = "straightWorkitem";
            } else {
                options.generatorFileName = "straightFlightGenerator";
                options.activityName = "StraightFlightGeneratorActivity+dev";
                options.url = "straightWorkitem";
            }
            break;
        case GeneratorEnum.NinetyFlight:
            if (isCheck) {
                options.generatorFileName = "ninetyFlightGeneratorCheck";
                options.activityName = "NinetyFlightCheckActivity+dev";
                options.isCheck = true;
                options.url = "ninetyWorkitem";
            } else {
                options.generatorFileName = "ninetyFlightGenerator";
                options.activityName = "NinetyFlightGeneratorActivity+dev";
                options.url = "ninetyWorkitem";
            }
            break;
        case GeneratorEnum.SwitchbackFlight:
            if (isCheck) {
                options.generatorFileName = "switchbackFlightGeneratorCheck";
                options.activityName = "SwitchbackFlightCheckActivity+dev";
                options.isCheck = true;
                options.url = "switchbackWorkitem";
            } else {
                options.generatorFileName = "switchbackFlightGenerator";
                options.activityName = "SwitchbackFlightGeneratorActivity+dev";
                options.url = "switchbackWorkitem";
            }
            break;
        case GeneratorEnum.UFlight:
            if (isCheck) {
                options.generatorFileName = "uFlightGeneratorCheck";
                options.activityName = "UFlightCheckActivity+dev";
                options.isCheck = true;
                options.url = "uFlightWorkitem";
            } else {
                options.generatorFileName = "uFlightGenerator";
                options.activityName = "UFlightGeneratorActivity+dev";
                options.url = "uFlightWorkitem";
            }
            break;
        case GeneratorEnum.LevelRun:
            options.generatorFileName = "levelRunGenerator";
            options.activityName = "LevelRunGeneratorActivity+dev";
            options.url = "levelRunWorkitem";
            break;
    }
    return options;
}
