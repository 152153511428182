import * as THREE from "three";

export interface ViewableBubble {
    url: string;
    xform: {
        x: number;
        y: number;
        z: number;
    };
}

function afterViewerEvents(viewer: Autodesk.Viewing.GuiViewer3D, events: any) {
    let promises: any[] = [];
    events.forEach(function (event: any) {
        promises.push(
            new Promise(function (resolve, reject) {
                let handler = function () {
                    viewer.removeEventListener(event, handler);
                    console.log(`Removed event listener for ${event}`);
                    resolve();
                };
                viewer.addEventListener(event, handler);
                console.log(`Added event listener for ${event}`);
            })
        );
    });

    return Promise.all(promises);
}
function loadModel(viewer: Autodesk.Viewing.GuiViewer3D, bubble: ViewableBubble) {
    Autodesk.Viewing.Document.load(
        bubble.url,
        (doc) => {
            let viewables = doc.getRoot().getDefaultGeometry();
            viewer.loadDocumentNode(doc, viewables, {
                keepCurrentModels: true,
                globalOffset: { x: 0, y: 0, z: 0 },
            });
        },
        (error) => console.log(error)
    );
}

export const initializeViewer = async (bubbles: Array<ViewableBubble>) => {
    const viewerDiv: any = document.getElementById("MyViewerDiv");
    const viewer = new Autodesk.Viewing.GuiViewer3D(viewerDiv);

    var options = {
        env: "Local",
    };

    Autodesk.Viewing.Initializer(options, async function onInitialized() {
        viewer.start();
        const { MyExtension } = await import("./MyExtension");
        MyExtension.register();

        bubbles.map((bubble) => {
            console.log("loading bubble", bubble);
            loadModel(viewer, bubble);
        });

        await afterViewerEvents(viewer, [
            Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
            Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
        ]);
        viewer.loadExtension("MyExtension");
    });
};
