import React, { useEffect, useContext } from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { Grid, CircularProgress } from "@material-ui/core";
import { materials } from "../../Global/Constants/Materials";
import { getFinishOptions } from "../TreadBuilder/TreadFormHelpers";
import { Item, ReturnedItem, OrderContext, GeneratorEnum } from "../../Context/OrderContext";
import FormTextField from "../../Components/FormComponents/FormTextField";
import FormCheckbox from "../../Components/FormComponents/FormCheckbox";
import FormSelect from "../../Components/FormComponents/FormSelect";
import { MultiTreadFormValues } from "Global/Constants/itemTypes";
import { initialMultiTreadValues } from "Global/Constants/itemDefaults";
import useFormInversion from "Hooks/useFormInversion";
import { mapTreadToInitialValues } from "./MultiTreadHelpers";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "../../Components/Button";
import MultiTreadFormLogic from "./MultiTreadFormLogic";
import { runGenerator } from "Global/Helpers/RunGenerator";

// Parameters for calculations or OfficeDot
export interface ReturnedTread extends MultiTreadFormValues, Item {
    bullnose: boolean;
    finalWidth: number;
    finalDepth: number;
    finalThickness: number;
    boardFeet: number;
    chop: number;
    bunk: string;
    pdfUrl: string;
    rip: number;
    plane: number;
    chopQty: number;
    price: number;
}

//Form Control Functions & Filters *******
function MultiTreadForm(props: MultiTreadFormProps) {
    const { state, dispatch } = useContext(OrderContext);
    const { orderNumber } = state;
    const { loading, setLoading, connectedSocket, setReturnedItem } = props;

    const [initialValues] = React.useState(initialMultiTreadValues());
    const treadTypeOptions = [
        { label: "Normal/Thick", value: "Tread" },
        { label: "Replacement", value: "Replacement Tread" },
    ];

    useEffect(() => {
        if (state.uuid) {
            let item = state.items.find((item) => item.uuid === state.uuid) as ReturnedTread;
            reset(mapTreadToInitialValues(item));
        }
    }, [state.uuid]); //eslint-disable-line

    const methods = useForm<MultiTreadFormValues>({
        defaultValues: initialValues,
    });
    const { handleSubmit, watch, control, reset } = methods;
    const watchFields = watch();

    function handleCancelEdit() {
        dispatch({ type: "CancelEditItem" });
        reset(initialValues);
    }

    useFormInversion(watch(), props.values, props.onChange);

    // Form Submission Handler *************************
    const onSubmit: SubmitHandler<MultiTreadFormValues> = async (values) => {
        setReturnedItem(undefined);
        setLoading(true);

        if (connectedSocket && orderNumber) {
            let data = {
                bullnose: values.roundOver === 0.5,
                ...initialValues,
                ...values,
            };
            if (state.uuid) {
                Object.assign(data, { uuid: state.uuid });
            }
            delete data.layout;
            await runGenerator(GeneratorEnum.MultiTreads, connectedSocket.id, data, orderNumber);
        }
    };

    const expandIcon = <ExpandMoreIcon className="text-white" />;

    const [expanded, setExpanded] = React.useState<string | false>("panel1");

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    // Page Rendering ***************************
    return (
        <div id="form-wrapper" className="relative top-0 p-0 m-0 grid">
            <div className="w-full p-2 m-0 grid place-content-center">
                <Typography className="w-full text-primary" variant="h5">
                    Tread Generator
                </Typography>
            </div>

            <FormProvider {...methods}>
                <MultiTreadFormLogic>
                    {({ roundOverOptions }) => (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
                                <AccordionSummary
                                    className="accordion"
                                    expandIcon={expandIcon}
                                    aria-label="Expand"
                                    aria-controls="tread-dim-content"
                                    id="tread-dim-header"
                                >
                                    <div className="flex w-full p-2 text-white uppercase bg-primary">
                                        <span>Dimensions</span>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2} className="p-2">
                                        <Grid item xs={12}>
                                            <FormSelect
                                                name="treadType"
                                                label="Type"
                                                options={treadTypeOptions}
                                                control={control}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormTextField
                                                name="treadWidth"
                                                variant="outlined"
                                                label="Width (in.)"
                                                type="number"
                                                InputProps={{
                                                    inputProps: { min: 6, max: 96, step: 0.0001 },
                                                }}
                                                helperText="Does not include return widths"
                                                tooltip="Min 6 -- Max 96"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormTextField
                                                variant="outlined"
                                                name="treadDepth"
                                                label="Total Depth (in.)"
                                                type="number"
                                                InputProps={{
                                                    inputProps: { min: 8.5, max: 18, step: 0.0001 },
                                                }}
                                                helperText={
                                                    watchFields.treadType === "Replacement Tread"
                                                        ? `Body Depth: ${
                                                              watchFields.treadDepth - watchFields.nosingDepth
                                                          }
                                                `
                                                        : ""
                                                }
                                                tooltip="Min 8.5 -- Max 18"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormTextField
                                                variant="outlined"
                                                name="treadThickness"
                                                label="Thickness (in.)"
                                                type="number"
                                                InputProps={{
                                                    inputProps:
                                                        watchFields.treadType === "Tread"
                                                            ? { min: 0.75, max: 4, step: 0.25 }
                                                            : { min: 0.5, max: 1.75, step: 0.25 },
                                                }}
                                                tooltip={
                                                    watchFields.treadType === "Tread"
                                                        ? "Min 0.75 -- Max 4 -- Increments 0.25"
                                                        : "Min 0.5 -- Max 1.75 -- Increments 0.25"
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    className="accordion"
                                    expandIcon={expandIcon}
                                    aria-controls="tread-ret-content"
                                    id="tread-ret-header"
                                >
                                    <Grid item xs={12}>
                                        <div className="flex w-full p-2 text-white uppercase bg-primary">
                                            <span>Returns</span>
                                        </div>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2} className="p-2">
                                        <Grid item xs={6}>
                                            <FormCheckbox label="Left" name="returnLeft" />
                                            {watchFields.returnLeft === true && (
                                                <>
                                                    <FormTextField
                                                        name="returnLengthLeft"
                                                        variant="outlined"
                                                        label="Length (in.)"
                                                        type="number"
                                                        InputProps={{
                                                            inputProps:
                                                                watchFields.treadThickness >= 2
                                                                    ? { min: 0.25, max: 1.5, step: 0.0001 }
                                                                    : { min: 0, max: 1.5, step: 0.0001 },
                                                        }}
                                                        tooltip={
                                                            watchFields.treadThickness >= 2
                                                                ? "Min 0.25 -- Max 1.5"
                                                                : "Min 0 -- Max 1.5"
                                                        }
                                                    />
                                                </>
                                            )}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormCheckbox label="Right" name="returnRight" />
                                            {watchFields.returnRight === true && (
                                                <>
                                                    <FormTextField
                                                        name="returnLengthRight"
                                                        variant="outlined"
                                                        label="Length (in.)"
                                                        type="number"
                                                        InputProps={{
                                                            inputProps:
                                                                watchFields.treadThickness >= 2
                                                                    ? { min: 0.25, max: 1.5, step: 0.0001 }
                                                                    : { min: 0, max: 1.5, step: 0.0001 },
                                                        }}
                                                        tooltip={
                                                            watchFields.treadThickness >= 2
                                                                ? "Min 0.25 -- Max 1.5"
                                                                : "Min 0 -- Max 1.5"
                                                        }
                                                    />
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    className="accordion"
                                    expandIcon={expandIcon}
                                    aria-controls="tread-nose-content"
                                    id="tread-nose-header"
                                >
                                    <Grid item xs={12}>
                                        <div className="flex w-full p-2 text-white uppercase bg-primary">
                                            <span>Nosing</span>
                                        </div>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2} className="p-2">
                                        {watchFields.treadType === "Replacement Tread" && (
                                            <>
                                                <Grid item xs={6}>
                                                    <FormTextField
                                                        variant="outlined"
                                                        name="nosingDepth"
                                                        label="Depth (in.)"
                                                        type="number"
                                                        InputProps={{
                                                            inputProps: { min: 1, max: 1.5, step: 0.25 },
                                                        }}
                                                        tooltip="Min 1 -- Max 1.5 -- Increments 0.25"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormTextField
                                                        variant="outlined"
                                                        name="nosingThickness"
                                                        label="Thickness (in.)"
                                                        type="number"
                                                        InputProps={{
                                                            inputProps: { min: 0.75, max: 2.5, step: 0.25 },
                                                        }}
                                                        tooltip="Min 0.75 -- Max 2.5 -- Increments 0.25"
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                        <Grid item xs={6}>
                                            <FormCheckbox
                                                label="Left Roundover"
                                                name="roundOverLeft"
                                                disabled={watchFields.returnLeft}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormCheckbox
                                                label="Right Roundover"
                                                name="roundOverRight"
                                                disabled={watchFields.returnRight}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormCheckbox
                                                label="Front Roundover"
                                                name="roundOverFront"
                                                disabled={watchFields.treadType === "Replacement Tread" ? true : false}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormCheckbox
                                                label="Back Roundover"
                                                name="roundOverBack"
                                                disabled={
                                                    watchFields.treadType === "Replacement Tread" ||
                                                    watchFields.returnRight ||
                                                    watchFields.returnLeft ||
                                                    watchFields.treadThickness <= 1
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormSelect
                                                name="roundOver"
                                                label="Nosing Style"
                                                options={roundOverOptions}
                                                control={control}
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    className="accordion"
                                    expandIcon={expandIcon}
                                    aria-controls="tread-mat-content"
                                    id="tread-mat-header"
                                >
                                    <Grid item xs={12}>
                                        <div className="flex w-full p-2 text-white uppercase bg-primary">
                                            <span>Material</span>
                                        </div>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2} className="p-2">
                                        <Grid item xs={6}>
                                            <FormSelect
                                                name="woodSpecies"
                                                label="Wood Type"
                                                options={materials.map((material) => ({
                                                    label: material.name,
                                                    value: material.name,
                                                }))}
                                                control={control}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormSelect
                                                name="finishOption"
                                                label="Finish"
                                                options={getFinishOptions(watchFields.woodSpecies)}
                                                control={control}
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    className="accordion"
                                    expandIcon={expandIcon}
                                    aria-controls="landing-mat-content"
                                    id="landing-mat-header"
                                >
                                    <Grid item xs={12}>
                                        <div className="flex w-full p-2 text-white uppercase bg-primary">
                                            <span>Features</span>
                                        </div>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2} className="p-2">
                                        <Grid item xs={6}>
                                            <FormCheckbox label="Distressed" name="distressed" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormCheckbox label="Hand Scraped" name="handScraped" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormCheckbox label="Wirebrushed" name="wireBrushed" />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Grid item xs={12}>
                                <Grid item container className="relative p-2 place-content-end">
                                    {loading && (
                                        <Grid className="absolute grid place-items-end">
                                            <CircularProgress />
                                        </Grid>
                                    )}
                                    {state.uuid && (
                                        <Grid className="grid place-items-end">
                                            <Button onClick={() => handleCancelEdit()}>Cancel Edit</Button>
                                        </Grid>
                                    )}
                                    <Grid className="container grid place-items-end">
                                        <Button type="submit" tcolor="text-white">
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </MultiTreadFormLogic>
            </FormProvider>
        </div>
    );
}

interface MultiTreadFormProps {
    values?: any;
    onChange?: any;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    connectedSocket: SocketIOClient.Socket | undefined;
    returnedItem: ReturnedItem | undefined;
    setReturnedItem: React.Dispatch<React.SetStateAction<ReturnedItem | undefined>>;
}

export default MultiTreadForm;
