import { Coords3D, RunLayout, ItemLayout } from "Global/Constants/layout";
import { GeneratorEnum } from "Context/OrderContext";

export interface OD1_Product {
    ProductID: number;
    ProductName: string;
    ProductDescription: string;
    UnitPrice: number;
}

export enum GeneratedStatusEnum {
    Stale,
    Generating,
    Generated,
}

export interface LevelRunValues {
    uuid: string;
    generator: GeneratorEnum;
    generatedStatus: GeneratedStatusEnum;
    orderNumber: string;
    system: string;
    systemHeight: number;
    infill: string;
    handrail: string;
    handrailMaterialPass: string;
    handrailMaterial: string;
    handrailFinish: string;
    postTop: string;
    postFoot: string;
    postMaterial: string;
    postFinish: string;
    postInfo: string;
    handrailInfo: string;
    runLength: number;
    cornerOption: string;
    footCoverOption: boolean;
    mountingSurface: string;
    handrailScrewOption: string;
    corners: string;
    glassGap: string;
    handrailType: string;
    systemType: string;
    panelThickness: string;
    isInterior: boolean;
    material: string;
    glassPanels: string;
    structureInfo: string;
    layout?: RunLayout;
}

export interface FlightFormValues {
    generator: GeneratorEnum;
    generatedStatus: GeneratedStatusEnum;
    orderNumber: string;
    uuid: string;
    bottomFinishedFloorThickness: number;
    ceilingGap: number;
    ceilingHeight: number;
    customerTreads: boolean;
    fastenersFloor: string;
    fastenersWall: string;
    finishOption: string;
    formTreadRise: number;
    headerHeightLower: number;
    headerHeightUpper: number;
    revision: number;
    meetCode: boolean;
    stringerMaterial: string;
    subfloorToSubfloor: number;
    taperedTreads: boolean;
    topFinishedFloorThickness: number;
    topNosingDepth: number;
    treadThickness: number;
    treadWidth: number;
    wallToWall: number;
    enduranceTreads: boolean;
    treadMaterial: string;
    treadFinish: string;
    tubeSize: string;
    posts: string;
    handrail: string;
    handrailMaterial: string;
    handrailFinish: string;
    postInfill: string;
    handrailHeight: number;
    layout?: RunLayout;
}

export interface StraightFlightFormValues {
    generator: GeneratorEnum;
    generatedStatus: GeneratedStatusEnum;
    orderNumber: string;
    uuid: string;
    bottomFinishedFloorThickness: number;
    ceilingGap: number;
    ceilingHeight: number;
    customerTreads: boolean;
    enduranceTreads: boolean;
    fastenersFloor: string;
    fastenersHeader: string;
    finishOption: string;
    formTreadRise: number;
    handrail: string;
    handrailHeight: number;
    handrailMaterial: string;
    handrailFinish: string;
    headerHeight: number;
    limitRun: boolean;
    meetCode: boolean;
    mountToSubfloor: boolean;
    postInfill: string;
    posts: string;
    revision: number;
    spaceConstraint: number;
    stringerMaterial: string;
    subfloorToSubfloor: number;
    taperedTreads: boolean;
    topFinishedFloorThickness: number;
    topNosingDepth: number;
    topTreadFlush: boolean;
    tubeSize: string;
    treadCovers: boolean;
    treadDepth: number;
    treadFinish: string;
    treadMaterial: string;
    treadNosing: number;
    treadThickness: number;
    treadWidth: number;
    layout?: ItemLayout;
}

export interface NinetyFlightFormValues {
    generator: GeneratorEnum;
    generatedStatus: GeneratedStatusEnum;
    orderNumber: string;
    uuid: string;
    bottomFinishedFloorThickness: number;
    ceilingGap: number;
    ceilingHeight: number;
    customerTreads: boolean;
    drywallLower: number;
    drywallUpper: number;
    enduranceTreads: boolean;
    fastenersFloor: string;
    fastenersHeader: string;
    fastenersWall: string;
    finishOption: string;
    formTreadRise: number;
    handrail: string;
    handrailHeight: number;
    handrailMaterial: string;
    handrailFinish: string;
    headerHeight: number;
    headerToWall: number;
    hockeyStickMounting: string;
    meetCode: boolean;
    mountToSubfloor: boolean;
    limitRun: boolean;
    matchTreads: boolean;
    postInfill: string;
    posts: string;
    revision: number;
    spaceConstraint: number;
    stairDirection: string;
    stringerMaterial: string;
    subfloorToSubfloor: number;
    taperedTreads: boolean;
    topFinishedFloorThickness: number;
    topNosingDepth: number;
    topTreadFlush: boolean;
    treadCovers: boolean;
    treadDepth: number;
    treadFinish: string;
    treadMaterial: string;
    treadNosing: number;
    treadThickness: number;
    treadWidthLower: number;
    treadWidthUpper: number;
    tubeSize: string;
    wallGapLower: number;
    wallGapUpper: number;
    winderLower: boolean;
    layout?: RunLayout;
}

export interface SwitchbackFlightFormValues {
    generator: GeneratorEnum;
    generatedStatus: GeneratedStatusEnum;
    orderNumber: string;
    uuid: string;
    bottomFinishedFloorThickness: number;
    customerTreads: boolean;
    drywallLanding: number;
    drywallLower: number;
    drywallUpper: number;
    enduranceTreads: boolean;
    fastenersFloor: string;
    fastenersHeader: string;
    fastenersWall: string;
    finishOption: string;
    formTreadRise: number;
    handrail: string;
    handrailMount: string;
    handrailHeight: number;
    handrailMaterial: string;
    handrailFinish: string;
    headerHeight: number;
    headerToWall: number;
    hockeyStickMounting: string;
    maximizeTreadWidth: boolean;
    meetCode: boolean;
    mountToSubfloor: boolean;
    limitRun: boolean;
    landingDepth: number;
    postInfill: string;
    posts: string;
    revision: number;
    spaceConstraint: number;
    splitLanding: boolean;
    stairDirection: string;
    stringerMaterial: string;
    subfloorToSubfloor: number;
    taperedTreads: boolean;
    topFinishedFloorThickness: number;
    topNosingDepth: number;
    topTreadFlush: boolean;
    treadCovers: boolean;
    treadDepth: number;
    treadFinish: string;
    treadMaterial: string;
    treadNosing: number;
    treadThickness: number;
    treadWidthLower: number;
    treadWidthUpper: number;
    tubeSize: string;
    wallGapLanding: number;
    wallGapLower: number;
    wallGapUpper: number;
    wallToWall: number;
    winderLower: boolean;
    winderUpper: boolean;
    layout?: ItemLayout;
}
export interface UFlightFormValues {
    generator: GeneratorEnum;
    generatedStatus: GeneratedStatusEnum;
    orderNumber: string;
    uuid: string;
    bottomFinishedFloorThickness: number;
    customerTreads: boolean;
    drywallLower: number;
    drywallMiddle: number;
    drywallUpper: number;
    enduranceTreads: boolean;
    fastenersFloor: string;
    fastenersHeader: string;
    fastenersWall: string;
    finishOption: string;
    formTreadRise: number;
    handrail: string;
    handrailHeight: number;
    handrailMount: string;
    handrailMaterial: string;
    handrailFinish: string;
    headerHeight: number;
    headerToWall: number;
    hockeyStickMounting: string;
    mountToSubfloor: boolean;
    limitRun: boolean;
    matchLandingDepth: boolean;
    matchTreads: boolean;
    meetCode: boolean;
    postInfill: string;
    posts: string;
    revision: number;
    spaceConstraint: number;
    stairDirection: string;
    stringerMaterial: string;
    subfloorToSubfloor: number;
    taperedTreads: boolean;
    topFinishedFloorThickness: number;
    topNosingDepth: number;
    topTreadFlush: boolean;
    treadCovers: boolean;
    treadDepth: number;
    treadFinish: string;
    treadMaterial: string;
    treadNosing: number;
    treadThickness: number;
    treadWidthLower: number;
    treadWidthMiddle: number;
    treadWidthUpper: number;
    tubeSize: string;
    wallGapLower: number;
    wallGapMiddle: number;
    wallGapUpper: number;
    wallToWall: number;
    winderLower: boolean;
    winderUpper: boolean;
    layout?: RunLayout;
}

//

export interface TreadBuilderFormValues {
    generator: GeneratorEnum;
    orderNumber: string;
    width: number;
    depth: number;
    thickness: number;
    returnRight: boolean;
    returnLeft: boolean;
    returnLength: number;
    returnWidth: number;
    nosingRight: boolean;
    nosingLeft: boolean;
    nosingFront: boolean;
    nosingBack: boolean;
    treadNosing: number;
    nosingThickness: number;
    roundOver: number;
    material: string;
    finishOption: string;
    bullnose: boolean;
    finalWidth: number;
    finalDepth: number;
    finalThickness: number;
    layout?: ItemLayout;
}

export interface MultiTreadFormValues {
    generator: GeneratorEnum;
    generatedStatus: GeneratedStatusEnum;
    orderNumber: string;
    uuid: string;
    treadType: string;
    treadWidth: number;
    treadDepth: number;
    treadThickness: number;
    returnRight: boolean;
    returnLeft: boolean;
    returnLengthLeft: number;
    returnLengthRight: number;
    nosingDepth: number;
    nosingThickness: number;
    roundOverRight: boolean;
    roundOverLeft: boolean;
    roundOverFront: boolean;
    roundOverBack: boolean;
    roundOver: number;
    woodSpecies: string;
    finishOption: string;
    distressed: boolean;
    handScraped: boolean;
    wireBrushed: boolean;
    layout?: ItemLayout;
}

export interface LandingFormValues {
    generator: GeneratorEnum;
    generatedStatus: GeneratedStatusEnum;
    orderNumber: string;
    uuid: string;
    landingType: string;
    landingWidth: number;
    landingDepth: number;
    landingThickness: number;
    returnLeft: boolean;
    returnRight: boolean;
    returnLengthLeft: number;
    returnLengthRight: number;
    nosingDepth: number;
    nosingThickness: number;
    roundOverLeft: boolean;
    roundOverRight: boolean;
    roundOverFront: boolean;
    roundOverBack: boolean;
    roundOver: number;
    woodSpecies: string;
    finishOption: string;
    distressed: boolean;
    handScraped: boolean;
    wireBrushed: boolean;
    layout?: ItemLayout;
}
export interface LandingTreadFormValues {
    generator: GeneratorEnum;
    generatedStatus: GeneratedStatusEnum;
    orderNumber: string;
    uuid: string;
    treadWidth: number;
    treadBodyDepth: number;
    treadThickness: number;
    returnLeft: boolean;
    returnRight: boolean;
    returnLengthLeft: number;
    returnLengthRight: number;
    nosingDepth: number;
    nosingThickness: number;
    roundOverLeft: boolean;
    roundOverRight: boolean;
    roundOverFront: boolean;
    roundOver: number;
    woodSpecies: string;
    finishOption: string;
    distressed: boolean;
    handScraped: boolean;
    wireBrushed: boolean;
    layout?: ItemLayout;
}

export interface RiserFormValues {
    generator: GeneratorEnum;
    generatedStatus: GeneratedStatusEnum;
    orderNumber: string;
    uuid: string;
    riserWidth: number;
    riserThickness: number;
    riserDepth: number;
    woodSpecies: string;
    finishOption: string;
    distressed: boolean;
    handScraped: boolean;
    wireBrushed: boolean;
    layout?: ItemLayout;
}
export interface S4SFormValues {
    generator: GeneratorEnum;
    generatedStatus: GeneratedStatusEnum;
    orderNumber: string;
    uuid: string;
    width: number;
    thickness: number;
    depth: number;
    woodSpecies: string;
    finishOption: string;
    distressed: boolean;
    handScraped: boolean;
    wireBrushed: boolean;
    layout?: ItemLayout;
}

export interface GateBuilderFormValues {
    generator: GeneratorEnum;
    generatedStatus: GeneratedStatusEnum;
    orderNumber: string;
    uuid: string;
    systemHeight: number;
    openingWidth: number;
    doubleGate: boolean;
    material: string;
    systemType: string;
    infill: string;
    latchSide: string;
    finish: string;
    revision: number;
    engineer: string;
    layout?: ItemLayout;
}
