export const treadAddon = {
    board_foot_limit: 6,
    low_labor: 25,
    high_labor: 28,
    low_finish: 35,
    high_finish: 35,
    each_return: 4.99,
    width_addon: 3,
    depth_addon: 1.5,
    thickness_addon: 0.25,
    bullnose_depth_addon: 0,
    bullnose_thickness_addon: 0,
    hand_scraped: 15,
    distressed: 20,
};
export const replacementTread = {
    board_foot_limit: 4,
    low_labor: 30,
    high_labor: 40,
    low_finish: 35,
    high_finish: 35,
    each_return: 4.99,
    width_addon: 3,
    depth_addon: 1,
    thickness_addon: 0.25,
    bullnose_depth_addon: 0.25,
    bullnose_thickness_addon: 0.25,
    hand_scraped: 15,
    distressed: 20,
};
export const thickTread = {
    board_foot_limit: 8,
    low_labor: 20,
    high_labor: 25,
    low_finish: 45,
    high_finish: 45,
    each_return: 4.99,
    width_addon: 3,
    depth_addon: 1.5,
    thickness_addon: 0.25,
    bullnose_depth_addon: 0.25,
    bullnose_thickness_addon: 0.25,
    hand_scraped: 20,
    distressed: 20,
};

export const landingAddon = {
    board_foot_limit: 8,
    low_labor: 30,
    high_labor: 30,
    low_finish: 90,
    high_finish: 90,
    each_return: 14.99,
    width_addon: 3,
    depth_addon: 3,
    thickness_addon: 0.25,
    bullnose_depth_addon: 0,
    bullnose_thickness_addon: 0,
    hand_scraped: 45,
    distressed: 60,
};

export const landingThickAddon = {
    board_foot_limit: 10,
    low_labor: 22,
    high_labor: 22,
    low_finish: 90,
    high_finish: 90,
    each_return: 14.99,
    width_addon: 3,
    depth_addon: 3,
    thickness_addon: 0.25,
    bullnose_depth_addon: 0.25,
    bullnose_thickness_addon: 0.25,
    hand_scraped: 45,
    distressed: 60,
};
export const landingReplacementAddon = {
    board_foot_limit: 8,
    low_labor: 30,
    high_labor: 35,
    low_finish: 90,
    high_finish: 90,
    each_return: 14.99,
    width_addon: 3,
    depth_addon: 3,
    thickness_addon: 0.25,
    bullnose_depth_addon: 0.25,
    bullnose_thickness_addon: 0.25,
    hand_scraped: 45,
    distressed: 60,
};

export const riserAddon = {
    board_foot_limit: 4,
    low_labor: 10,
    high_labor: 10,
    low_finish: 20,
    high_finish: 20,
    each_return: 4.99,
    width_addon: 3,
    depth_addon: 1.5,
    thickness_addon: 0.25,
    bullnose_depth_addon: 0.0,
    bullnose_thickness_addon: 0.0,
    hand_scraped: 12,
    distressed: 12,
};

export const s4sAddon = {
    board_foot_limit: 4,
    low_labor: 10,
    high_labor: 10,
    low_finish: 15,
    high_finish: 25,
    each_return: 4.99,
    width_addon: 3,
    depth_addon: 1.5,
    thickness_addon: 0.25,
    bullnose_depth_addon: 0.0,
    bullnose_thickness_addon: 0.0,
    hand_scraped: 15,
    distressed: 20,
};

export const landingTreadAddon = {
    board_foot_limit: 4,
    low_labor: 15,
    high_labor: 27,
    low_finish: 20,
    high_finish: 30,
    each_return: 4.99,
    width_addon: 3,
    depth_addon: 1,
    thickness_addon: 0.25,
    bullnose_depth_addon: 0.25,
    bullnose_thickness_addon: 0.25,
    hand_scraped: 15,
    distressed: 20,
};
