import { ReturnedGate, GateItem } from "./GateBuilderForm";
import { GeneratorEnum } from "../../Context/OrderContext";
import { OD1_TOKEN, OD1_DEV, ENDPOINT } from "../../Global/Constants/environment";
import axios from "axios";
import { GateMaterials, GateFinishes } from "../../Global/Constants/Gates";
import { OD1_Item } from "Global/Constants/OD1_Types";
import { GeneratedStatusEnum } from "Global/Constants/itemTypes";
import { getGlassPrice } from "Global/Constants/Glass";
import { accurateRound } from "Global/Helpers/Math";

export function processReturnedGate(data: any): ReturnedGate {
    const orderNumberParam = data.orderNumber;
    const uuid = data.uuid;
    const systemHeightParam = data.systemHeight;
    const systemTypeParam = data.systemType;
    const openingWidthParam = data.openingWidth;
    const doubleGateParam = data.doubleGate;
    const materialParam = data.material;
    const infillParam = data.infill;
    const glassThickness = data.glassThickness;
    const latchSideParam = data.latchSide;
    const finish = data.finish;
    const priceParam = calculatePrice(openingWidthParam, systemHeightParam, materialParam, finish, doubleGateParam);
    const pdfUrlParam = data.pdfUrl;
    const bomStringParam = data.bomString;
    const glassStringParam = data.glassString;
    const revision = data.revision;
    const engineer = data.engineer;
    const gateString = data.gateString;

    let items: GateItem[] = [];
    let bomItems: Array<{ productID: string; quantity: number }> = [];

    // Parse the gate BOM
    let parsedBom = bomStringParam.split("|");
    parsedBom.forEach((item: string) => {
        let parsedItem = item.split(",");
        if (parsedItem[1]) {
            bomItems.push({
                productID: parsedItem[1],
                quantity: parseInt(parsedItem[0]),
            });
        }
    });

    // Query OD to fill out the rest of the product info
    let standardProductIds = bomItems.map((item) => item.productID);
    getProducts(`ProductIDs=${standardProductIds.toString()}`).then((results) => {
        if (results.products) {
            results.products.forEach((product: any) => {
                let foundProduct = bomItems.find((item) => parseInt(item.productID) === product.ProductID);
                if (foundProduct) {
                    console.log(product.ProductID);
                    if (product.ProductID === 328186 || product.ProductID === 474713) {
                        items.push({
                            productId: product.ProductID,
                            name: product.ProductName,
                            description: product.ProductDescription,
                            quantity: foundProduct.quantity,
                            price: product.UnitPrice,
                            type: "InfillPack",
                        });
                    } else {
                        items.push({
                            productId: product.ProductID,
                            name: product.ProductName,
                            description: product.ProductDescription,
                            quantity: foundProduct.quantity,
                            price: product.UnitPrice,
                            type: "Hardware",
                        });
                    }
                }
            });
        }
    });
    // Parse the gate glass panels
    if (glassStringParam.length > 0) {
        let parsedPanels = glassStringParam.split("|");
        parsedPanels.forEach((item: string) => {
            // [ quantity, level/angle, sq.ft, width, height ]
            let parsedItem = item.split(",");
            let price = glassThickness ? accurateRound(getGlassPrice(glassThickness, parseFloat(parsedItem[2])), 2) : 0;
            items.push({
                productId: 443743,
                name: "Custom Glass Panel",
                quantity: parseInt(parsedItem[0]),
                description: `Custom Glass Panel ${parsedItem[3]} x ${parsedItem[4]}`,
                price: price,
                type: "Glass",
            });
        });
    }
    return {
        generatedStatus: GeneratedStatusEnum.Generated,
        orderNumber: orderNumberParam,
        systemHeight: systemHeightParam,
        systemType: systemTypeParam,
        openingWidth: openingWidthParam,
        doubleGate: doubleGateParam,
        material: materialParam,
        infill: infillParam,
        latchSide: latchSideParam,
        price: priceParam,
        generator: GeneratorEnum.Gate,
        uuid: uuid,
        name: "Custom Gate",
        pdfUrl: pdfUrlParam,
        bomString: bomStringParam,
        quantity: 1,
        items: items,
        finish: finish,
        revision: revision,
        engineer: engineer,
        gateString: gateString,
    };
}

function calculatePrice(width: number, height: number, material: string, finish: string, doubleGate: boolean) {
    let basePrice = 0;
    let widthModifier = 0;
    let heightModifier = 0;
    let finishModifier = 0;

    // get basePrice for material
    switch (material) {
        case "Aluminum":
            basePrice = 799.99;
            break;
        case "2205":
            basePrice = 1299.99;
            break;
        case "304":
            basePrice = 1099.99;
            break;
        default:
            break;
    }

    // get width modifier
    if (width > 39 && width <= 39) {
        widthModifier = 25.0;
    } else if (width > 39 && width <= 42) {
        widthModifier = 50.0;
    } else if (width > 42 && width <= 45) {
        widthModifier = 75.0;
    } else if (width > 45 && width <= 48) {
        widthModifier = 100.0;
    }  else if (width > 48) {
        widthModifier = 125.0;
    }else {
        widthModifier = 0.0;
    }

    // get height modifier
    if (height === 36) {
        heightModifier = 0.0;
    } else if (height === 39) {
        heightModifier = 50.0;
    } else if (height === 42) {
        heightModifier = 75.0;
    } else if (height > 42) {
        heightModifier = 100.0;
    } else  {
        heightModifier = 0.0;
    }

    let finishOption = getFinish(finish);
    if (finishOption) {
        finishModifier = finishOption.price;
    }

    let totalPrice = basePrice + widthModifier + heightModifier + finishModifier;
    if (doubleGate) {
        totalPrice = totalPrice * 1.75;
    }
    return totalPrice;
}

export async function formatGateForOD1(returnedGate: ReturnedGate): Promise<Array<OD1_Item>> {
    //get gate product ID
    let gateProductID = 418964;

    let gateProducts: any[] = [];
    let pdfUrl = await updateUrl(returnedGate.orderNumber, returnedGate.uuid);

    gateProducts.push({
        ProductID: gateProductID,
        Quantity: returnedGate.quantity,
        Description: "Custom Gate",
        Price: returnedGate.price,
        FinishOptionID: getFinish(returnedGate.finish)?.id,
        MaterialID: getMaterial(returnedGate.material)?.id,
        PostCNCProgram: returnedGate.gateString,
        filename: `${returnedGate.uuid}.pdf`,
        fileURL: pdfUrl,
    });

    returnedGate.items.forEach((item) => {
        switch (item.type) {
            case "Hardware":
                gateProducts.push({
                    ProductID: item.productId,
                    Quantity: item.quantity * returnedGate.quantity,
                    Description: item.description,
                    FinishOptionID: "",
                    fileURL: "",
                });
                break;
            case "InfillPack":
                gateProducts.push({
                    ProductID: item.productId,
                    Quantity: item.quantity * returnedGate.quantity,
                    Description: item.description,
                    FinishOptionID: "",
                    filename: `${returnedGate.uuid}.pdf`,
                    fileURL: pdfUrl,
                });
                break;
            case "Glass":
                gateProducts.push({
                    ProductID: item.productId,
                    Quantity: item.quantity * returnedGate.quantity,
                    Description: item.description,
                    InHouseNotes: item.description,
                    FinishOptionID: "",
                    fileURL: "",
                });
                break;
        }
    });

    return gateProducts;
}

async function getProducts(queryParams: string) {
    const config = {
        headers: {
            "X-api-auth": OD1_TOKEN,
        },
    };

    let response = await fetch(OD1_DEV + `/products_get_info.cfm?${queryParams}`, config);

    let results = await response.json();

    return results;
}

const updateUrl = async (orderNumber: string, uuid: string) => {
    const data = {
        orderNumber: orderNumber,
        fileName: `${uuid}.pdf`,
        generator: "gateGenerator",
    };
    //get url
    let response = await axios.post(ENDPOINT + "/forge/s3Url", data);
    return response.data.url;
};

export function getGateFinishes(material_name: string) {
    if (!material_name) {
        return [];
    }
    let material_id = GateMaterials.find((material) => material.generatorName === material_name)?.id;

    if (!material_id) {
        return [];
    }

    let options = GateFinishes.filter((option) => option.materialIDs.toString().includes(material_id!.toString()));

    return options.map((option) => {
        return { label: option.name, value: option.name };
    });
}

function getFinish(finish_name: string) {
    return GateFinishes.find((finish) => finish.name === finish_name);
}
function getMaterial(material_name: string) {
    return GateMaterials.find((material) => material.generatorName === material_name);
}
