import { GeneratorEnum } from "Context/OrderContext";

export const MetalMaterials = [
    { id: 73, name: "Aluminum", generatorName: "Aluminum", generators: [GeneratorEnum.Gate, GeneratorEnum.LevelRun] },
    {
        id: 75,
        name: "2205 Stainless Steel",
        generatorName: "2205",
        generators: [GeneratorEnum.Gate, GeneratorEnum.LevelRun],
    },
    {
        id: 71,
        name: "304 Stainless Steel",
        generatorName: "304",
        generators: [GeneratorEnum.Gate, GeneratorEnum.LevelRun],
    },
    {
        id: 72,
        name: "Mild Steel",
        generatorName: "Mild",
        generators: [
            GeneratorEnum.Flight,
            GeneratorEnum.StraightFlight,
            GeneratorEnum.SwitchbackFlight,
            GeneratorEnum.StraightFlight,
            GeneratorEnum.NinetyFlight,
            GeneratorEnum.UFlight,
        ],
    },
    {
        id: 71,
        name: "Stainless Steel",
        generatorName: "Stainless",
        generators: [
            GeneratorEnum.Flight,
            GeneratorEnum.StraightFlight,
            GeneratorEnum.SwitchbackFlight,
            GeneratorEnum.StraightFlight,
            GeneratorEnum.NinetyFlight,
            GeneratorEnum.UFlight,
        ],
    },
];

export const MetalFinishes = [
    {
        id: 231,
        name: "PC: Black",
        materialIDs: [73, 75, 71, 72],
        price: 0,
    },
    {
        id: 421,
        name: "PC Fluoropolymer: Black",
        materialIDs: [73, 75, 71, 72],
        price: 25,
    },
    {
        id: 440,
        name: "PC Fluoropolymer: Apollo White",
        materialIDs: [73, 75, 71, 72],
        price: 50,
    },
    {
        id: 459,
        name: "PC: Colonial Grey",
        materialIDs: [73, 75, 71, 72],
        price: 25,
    },
    {
        id: 465,
        name: "PC: Apollo White",
        materialIDs: [73, 75, 71, 72],
        price: 25,
    },
    {
        id: 466,
        name: "PC: Bone White",
        materialIDs: [73, 75, 71, 72],
        price: 25,
    },
    {
        id: 467,
        name: "PC: Fashion Grey",
        materialIDs: [73, 75, 71, 72],
        price: 25,
    },
    {
        id: 468,
        name: "PC: Copper Vein",
        materialIDs: [73, 75, 71, 72],
        price: 25,
    },
    {
        id: 523,
        name: "PC: Hunter Green",
        materialIDs: [73, 75, 71, 72],
        price: 25,
    },
    {
        id: 576,
        name: "PC: Sandstone",
        materialIDs: [73, 75, 71, 72],
        price: 25,
    },
    {
        id: 578,
        name: "PC: Light Blue",
        materialIDs: [73, 75, 71, 72],
        price: 25,
    },
    {
        id: 579,
        name: "PC: Super Bronze",
        materialIDs: [73, 75, 71, 72],
        price: 25,
    },
    {
        id: 622,
        name: "PC: Charcoal",
        materialIDs: [73, 75, 71, 72],
        price: 25,
    },
    {
        id: 626,
        name: "PC Fluoropolymer: Colonial Grey",
        materialIDs: [73, 75, 71, 72],
        price: 50,
    },
    {
        id: 708,
        name: "PC: Speedboat Silver",
        materialIDs: [73, 75, 71, 72],
        price: 25,
    },
    {
        id: 711,
        name: "PC Fluoropolymer: Speedboat Silver",
        materialIDs: [73, 75, 71, 72],
        price: 50,
    },
    {
        id: 712,
        name: "PC Fluoropolymer: Fashion Grey",
        materialIDs: [73, 75, 71, 72],
        price: 50,
    },
    {
        id: 713,
        name: "PC Fluoropolymer: Bone White",
        materialIDs: [73, 75, 71, 72],
        price: 50,
    },
    {
        id: 717,
        name: "PC: Rust Spice",
        materialIDs: [73, 75, 71, 72],
        price: 25,
    },
    {
        id: 840,
        name: "Raw Aluminum - No Finish",
        materialIDs: [73],
        price: 0,
    },
    {
        id: 434,
        name: "Brushed Stainless",
        materialIDs: [75, 71, 72],
        price: 0,
    },
];

export function getMetalMaterials(generator: GeneratorEnum): Array<{ label: string; value: string }> {
    let materials: Array<{ label: string; value: string }> = [];
    MetalMaterials.forEach((material) => {
        if (material.generators.includes(generator))
            materials.push({
                label: material.name,
                value: material.generatorName,
            });
    });
    return materials;
}

export function findMetalMaterial(materialName: string) {
    let material = MetalMaterials.find((material) => material.generatorName === materialName);
    return material;
}

export function getMetalFinishes(materialName: string) {
    let finishes: Array<{ label: string; value: string }> = [];

    let material = MetalMaterials.find((material) => material.generatorName === materialName);

    if (material) {
        MetalFinishes.filter((finish) => finish.materialIDs.includes(material!.id)).forEach((finish) => {
            finishes.push({
                label: finish.name,
                value: finish.name,
            });
        });
    }

    return finishes;
}

export function findMetalFinish(finishName: string) {
    let finish = MetalFinishes.find((finish) => finish.name === finishName);
    return finish;
}
