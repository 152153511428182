import { ButtonProps } from "@material-ui/core";
import React from "react";

function Button(props: StyledButtonProps) {
    const { color = "text-white", bcolor = "bg-primary", type, ...other } = props;
    return (
        <button
            {...other}
            type={type ? type : "button"}
            className={`grid ${bcolor} ${color} block p-2 m-2 border-2 rounded-2xl uppercase font-extrabold font-sans text-base`}
        ></button>
    );
}

interface StyledButtonProps extends ButtonProps {
    tcolor?: "text-white" | "text-blue" | "text-secondary";
    bcolor?: "bg-primary" | "bg-default" | "bg-secondary" | "bg-secondaryLite";
}
export default Button;
