// Added for Drawer interface/Dashboard
import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import OrderContextProvider from "./Context/OrderContext";
import VoeAppbar from "./Components/Appbar";
// import "./App.css";
import Generators from "./Pages/Generators";
import { ModalProvider } from "Context/ModalContext";
import GeneratorSocket from "Context/GeneratorSocket";
import LoaderCover from "Components/LoaderCover";
import Home from "Pages/Home";
import useKonamiCode from "Hooks/useKonamiCode";
import _ from "lodash";
import { SnackbarProvider } from "notistack";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            height: "100vh",
            flexDirection: "column",
        },
    })
);

export default function App() {
    const classes = useStyles();

    useKonamiCode(() => {
        alert(_.sample(["Visual. Order. Entry.", "Phone Call", "Working Remotely", "Three boards", "FOLT"]));
    });

    return (
        <div className={classes.root}>
            <SnackbarProvider>
                <ModalProvider>
                    <OrderContextProvider>
                        <GeneratorSocket>
                            <Router>
                                <CssBaseline />
                                <VoeAppbar />
                                <Suspense fallback={<LoaderCover />}>
                                    <Switch>
                                        <Route path="/generators" component={Generators} />
                                        <Route path="/" component={Home} />
                                    </Switch>
                                </Suspense>
                            </Router>
                        </GeneratorSocket>
                    </OrderContextProvider>
                </ModalProvider>
            </SnackbarProvider>
        </div>
    );
}
