import React from "react";
import OrderNumberConfirm from "Components/OrderNumberConfirm";
import { Container, Box } from "@material-ui/core";

const Home = () => (
    <Container maxWidth={false} className="container block">
        <Box pt={4}>
            <OrderNumberConfirm />
        </Box>
    </Container>
);

export default Home;
