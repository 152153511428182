import { materials, finishOptions } from "./Materials";
import { MetalMaterials, MetalFinishes } from "./MetalMaterials";

export const HandrailWoodMaterials = [
    { id: 1, name: "Red Oak" },
    { id: 2, name: "White Oak" },
    { id: 3, name: "Hard Maple" },
    { id: 4, name: "Walnut" },
    { id: 5, name: "Hickory" },
    { id: 6, name: "Poplar" },
    { id: 7, name: "African Mahogany" },
    { id: 8, name: "Ash" },
    { id: 9, name: "American Cherry" },
    { id: 12, name: "Brazilian Cherry" },
    { id: 15, name: "Sapele" },
    { id: 16, name: "Alder" },
    { id: 17, name: "Quartersawn White Oak" },
    { id: 26, name: "Tigerwood" },
];

export const HandrailMetalMaterials = [{ id: 73, name: "Aluminum" }];

export const allHandrailMaterials = HandrailWoodMaterials.concat(HandrailMetalMaterials);

export function getHandrailMaterial(type: string) {
    if (type === "wood") {
        return HandrailWoodMaterials;
    } else if (type === "metal") {
        return HandrailMetalMaterials;
    } else {
        return [];
    }
}
export function findHandrailMaterial(name: string, type: string) {
    switch (type) {
        case "wood":
            return HandrailWoodMaterials.find((material) => material.name === name);
        case "metal":
            return HandrailMetalMaterials.find((material) => material.name === name);
        default:
            return null;
    }
}

export const HandrailStyle = [
    { name: "6000", type: "wood" },
    { name: "6004", type: "wood" },
    { name: "Aluminum", type: "metal" },
];

export function getHandrailStyle(name: string) {
    const foundStyle = HandrailStyle.find((style) => style.name === name);

    if (foundStyle) return foundStyle;
    return null;
}

export function findHandrailFinish(handrailStyle: string, material: string, handrailFinish: string) {
    if (handrailStyle === "Aluminum") {
        let finish = MetalFinishes.find((finish) => finish.name === handrailFinish);
        return finish;
    } else {
        let finish = finishOptions.find((finish) => finish.name === handrailFinish);
        return finish;
    }
}

export function getHandrailFinishOptions(materialName: string, handrailStyle: string) {
    let style = HandrailStyle.filter((style) => style.name === handrailStyle);
    if (style[0].type === "wood") {
        let material_id = materials.find((material) => material.name === materialName)?.id;
        if (!material_id) {
            return [];
        }
        let finishes = finishOptions.filter((finish) => finish.material_id === material_id);
        return finishes.map((finish) => {
            return { label: finish.name, value: finish.name };
        });
    } else if (style[0].type === "metal") {
        let material_id = MetalMaterials.find((material) => material.name === materialName)?.id;
        if (!material_id) {
            return [];
        }
        let finishes = MetalFinishes.filter((finish) => finish.materialIDs.includes(material_id!));

        return finishes.map((finish) => {
            return { label: finish.name, value: finish.name };
        });
    } else {
        return [];
    }
}
