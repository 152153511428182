import { finishOptions } from "./Materials";

export const BreakPoints = [36, 42, 48, 54];
export const FlightPricing = [
    {
        id: 1,
        name: "Red Oak",
        treadBreaks: [
            {
                length: 36,
                price: 192.87,
            },
            {
                length: 42,
                price: 213.45,
            },
            {
                length: 48,
                price: 230.04,
            },
            {
                length: 54,
                price: 248.63,
            },
        ],
        landingBreaks: [
            {
                length: 36,
                price: 572.76,
            },
            {
                length: 42,
                price: 711.89,
            },
            {
                length: 48,
                price: 1089.68,
            },
            {
                length: 54,
                price: 1269.44,
            },
        ],
        nosingBreaks: [
            {
                length: 36,
                price: 91.13,
            },
            {
                length: 42,
                price: 95.85,
            },
            {
                length: 48,
                price: 100.68,
            },
            {
                length: 54,
                price: 113.81,
            },
        ],
    },
    {
        id: 2,
        name: "White Oak",
        treadBreaks: [
            {
                length: 36,
                price: 305.54,
            },
            {
                length: 42,
                price: 336.93,
            },
            {
                length: 48,
                price: 374.42,
            },
            {
                length: 54,
                price: 408.86,
            },
        ],
        landingBreaks: [
            {
                length: 36,
                price: 904.35,
            },
            {
                length: 42,
                price: 1159.08,
            },
            {
                length: 48,
                price: 1658.88,
            },
            {
                length: 54,
                price: 1988.27,
            },
        ],
        nosingBreaks: [
            {
                length: 36,
                price: 114.86,
            },
            {
                length: 42,
                price: 123.68,
            },
            {
                length: 48,
                price: 132.39,
            },
            {
                length: 54,
                price: 148.88,
            },
        ],
    },
    {
        id: 3,
        name: "Hard Maple",
        treadBreaks: [
            {
                length: 36,
                price: 218.39,
            },
            {
                length: 42,
                price: 241.07,
            },
            {
                length: 48,
                price: 263.64,
            },
            {
                length: 54,
                price: 286.32,
            },
        ],
        landingBreaks: [
            {
                length: 36,
                price: 572.45,
            },
            {
                length: 42,
                price: 714.2,
            },
            {
                length: 48,
                price: 1084.22,
            },
            {
                length: 54,
                price: 1267.44,
            },
        ],
        nosingBreaks: [
            {
                length: 36,
                price: 88.82,
            },
            {
                length: 42,
                price: 93.86,
            },
            {
                length: 48,
                price: 98.69,
            },
            {
                length: 54,
                price: 111.5,
            },
        ],
    },
    {
        id: 4,
        name: "Walnut",
        treadBreaks: [
            {
                length: 36,
                price: 399.09,
            },
            {
                length: 42,
                price: 457.79,
            },
            {
                length: 48,
                price: 580.53,
            },
            {
                length: 54,
                price: 641.96,
            },
        ],
        landingBreaks: [
            {
                length: 36,
                price: 1285.82,
            },
            {
                length: 42,
                price: 1670.54,
            },
            {
                length: 48,
                price: 2318.91,
            },
            {
                length: 54,
                price: 2816.3,
            },
        ],
        nosingBreaks: [
            {
                length: 36,
                price: 144.68,
            },
            {
                length: 42,
                price: 158.01,
            },
            {
                length: 48,
                price: 171.24,
            },
            {
                length: 54,
                price: 191.72,
            },
        ],
    },
    {
        id: 5,
        name: "Hickory",
        treadBreaks: [
            {
                length: 36,
                price: 211.46,
            },
            {
                length: 42,
                price: 226.26,
            },
            {
                length: 48,
                price: 254.51,
            },
            {
                length: 54,
                price: 276.03,
            },
        ],
        landingBreaks: [
            {
                length: 36,
                price: 567.93,
            },
            {
                length: 42,
                price: 708.11,
            },
            {
                length: 48,
                price: 1076.45,
            },
            {
                length: 54,
                price: 1257.68,
            },
        ],
        nosingBreaks: [
            {
                length: 36,
                price: 88.5,
            },
            {
                length: 42,
                price: 93.33,
            },
            {
                length: 48,
                price: 98.27,
            },
            {
                length: 54,
                price: 110.87,
            },
        ],
    },
    {
        id: 8,
        name: "Ash",
        treadBreaks: [
            {
                length: 36,
                price: 236.97,
            },
            {
                length: 42,
                price: 262.17,
            },
            {
                length: 48,
                price: 285.9,
            },
            {
                length: 54,
                price: 310.37,
            },
        ],
        landingBreaks: [
            {
                length: 36,
                price: 665.16,
            },
            {
                length: 42,
                price: 838.52,
            },
            {
                length: 48,
                price: 1244.76,
            },
            {
                length: 54,
                price: 1468.73,
            },
        ],
        nosingBreaks: [
            {
                length: 36,
                price: 96.17,
            },
            {
                length: 42,
                price: 102.15,
            },
            {
                length: 48,
                price: 108.03,
            },
            {
                length: 54,
                price: 121.89,
            },
        ],
    },
    {
        id: 9,
        name: "American Cherry",
        treadBreaks: [
            {
                length: 36,
                price: 246.21,
            },
            {
                length: 42,
                price: 273.3,
            },
            {
                length: 48,
                price: 300.29,
            },
            {
                length: 54,
                price: 327.38,
            },
        ],
        landingBreaks: [
            {
                length: 36,
                price: 679.97,
            },
            {
                length: 42,
                price: 858.47,
            },
            {
                length: 48,
                price: 1270.49,
            },
            {
                length: 54,
                price: 1501.07,
            },
        ],
        nosingBreaks: [
            {
                length: 36,
                price: 97.32,
            },
            {
                length: 42,
                price: 103.31,
            },
            {
                length: 48,
                price: 109.5,
            },
            {
                length: 54,
                price: 123.47,
            },
        ],
    },
    {
        id: 12,
        name: "Brazilian Cherry",
        treadBreaks: [
            {
                length: 36,
                price: 361.4,
            },
            {
                length: 42,
                price: 391.64,
            },
            {
                length: 48,
                price: 449.6,
            },
            {
                length: 54,
                price: 493.8,
            },
        ],
        landingBreaks: [
            {
                length: 36,
                price: 952.23,
            },
            {
                length: 42,
                price: 1223.45,
            },
            {
                length: 48,
                price: 1741.73,
            },
            {
                length: 54,
                price: 2092.22,
            },
        ],
        nosingBreaks: [
            {
                length: 36,
                price: 118.64,
            },
            {
                length: 42,
                price: 127.88,
            },
            {
                length: 48,
                price: 137.22,
            },
            {
                length: 54,
                price: 154.13,
            },
        ],
    },
    {
        id: 15,
        name: "Sapele",
        treadBreaks: [
            {
                length: 36,
                price: 368.33,
            },
            {
                length: 42,
                price: 412.95,
            },
            {
                length: 48,
                price: 458.84,
            },
            {
                length: 54,
                price: 504.09,
            },
        ],
        landingBreaks: [
            {
                length: 36,
                price: 964.1,
            },
            {
                length: 42,
                price: 1239.3,
            },
            {
                length: 48,
                price: 1762.52,
            },
            {
                length: 54,
                price: 2118.15,
            },
        ],
        nosingBreaks: [
            {
                length: 36,
                price: 119.37,
            },
            {
                length: 42,
                price: 128.93,
            },
            {
                length: 48,
                price: 138.48,
            },
            {
                length: 54,
                price: 155.49,
            },
        ],
    },
    {
        id: 26,
        name: "Tigerwood",
        treadBreaks: [
            {
                length: 36,
                price: 445.92,
            },
            {
                length: 42,
                price: 504.62,
            },
            {
                length: 48,
                price: 563.1,
            },
            {
                length: 54,
                price: 621.69,
            },
        ],
        landingBreaks: [
            {
                length: 36,
                price: 1368.03,
            },
            {
                length: 42,
                price: 1780.79,
            },
            {
                length: 48,
                price: 2461.4,
            },
            {
                length: 54,
                price: 2994.9,
            },
        ],
        nosingBreaks: [
            {
                length: 36,
                price: 151.08,
            },
            {
                length: 42,
                price: 165.26,
            },
            {
                length: 48,
                price: 179.54,
            },
            {
                length: 54,
                price: 200.85,
            },
        ],
    },
];

export const FlightExtras = [
    {
        id: 1,
    },
];

export function getFlightTreadPrice(treadWidth: number, woodSpecies: string, treadFinish: string) {
    let flightTread = FlightPricing.find((tread) => tread.name === woodSpecies);
    let finishOption = finishOptions.find((option) => option.name === treadFinish);
    let finishPrice = 0;
    if (finishOption) {
        //check if clearcoat
        if (finishOption.id === 1 || finishOption.id === 289) {
            finishPrice = 45.0;
            //check if no finish
        } else if (finishOption.id === 0) {
            finishPrice = 0.0;
            // anything other than clearcoat or no finish
        } else {
            finishPrice = 67.5;
        }
    }

    if (!flightTread) {
        return 0.0;
    }
    let closestBreak = findClosestBreak(treadWidth);
    let treadBreaks = flightTread.treadBreaks;

    let priceBreak = treadBreaks.find((breakPoint) => breakPoint.length === closestBreak);
    if (!priceBreak) {
        return 0.0;
    }
    return priceBreak.price + finishPrice;
}
export function getFlightLandingPrice(landingWidth: number, woodSpecies: string, landingFinish: string) {
    let flightLanding = FlightPricing.find((landing) => landing.name === woodSpecies);
    let finishOption = finishOptions.find((option) => option.name === landingFinish);
    let finishPrice = 0;
    if (finishOption) {
        //check if clearcoat
        if (finishOption.id === 1 || finishOption.id === 289) {
            finishPrice = 110.0;
            //check if no finish
        } else if (finishOption.id === 0) {
            finishPrice = 0.0;
            // anything other than clearcoat or no finish
        } else {
            finishPrice = 170.0;
        }
    }

    if (!flightLanding) {
        return 0.0;
    }
    let closestBreak = findClosestBreak(landingWidth);
    let flightBreaks = flightLanding.landingBreaks;

    let priceBreak = flightBreaks.find((breakPoint) => breakPoint.length === closestBreak);
    if (!priceBreak) {
        return 0.0;
    }
    return priceBreak.price + finishPrice;
}
export function getFlightNosingPrice(nosingWidth: number, woodSpecies: string, nosingFinish: string) {
    let flightNosing = FlightPricing.find((nosing) => nosing.name === woodSpecies);
    let finishOption = finishOptions.find((option) => option.name === nosingFinish);
    let finishPrice = 0;
    if (finishOption) {
        //check if clearcoat
        if (finishOption.id === 1 || finishOption.id === 289) {
            finishPrice = 20;
            //check if no finish
        } else if (finishOption.id === 0) {
            finishPrice = 0.0;
            // anything other than clearcoat or no finish
        } else {
            finishPrice = 30;
        }
    }

    if (!flightNosing) {
        return 0.0;
    }
    let closestBreak = findClosestBreak(nosingWidth);
    let flightBreaks = flightNosing.nosingBreaks;

    let priceBreak = flightBreaks.find((breakPoint) => breakPoint.length === closestBreak);
    if (!priceBreak) {
        return 0.0;
    }
    return priceBreak.price + finishPrice;
}
function findClosestBreak(width: number) {
    let closest = BreakPoints.reduce((prev, curr) => {
        return Math.abs(curr - width) < Math.abs(prev - width) ? curr : prev;
    });
    return closest;
}
