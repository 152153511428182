import React, { useState } from "react";
import FormTextField from "Components/FormComponents/FormTextField";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { useLocation } from "react-router-dom";

type BugReportForm = {
    message: string;
    email: string;
};
function BugReport() {
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const methods = useForm();
    const { handleSubmit } = methods;
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const onSubmit: SubmitHandler<BugReportForm> = async (values) => {
        const pathSplit = location.pathname.split("/");
        const page = pathSplit[pathSplit.length - 1];

        const reportUrl = process.env.REACT_APP_SLACK!;
        const body = {
            blocks: [
                {
                    type: "header",
                    text: {
                        type: "plain_text",
                        text: `Users Page - ${page}`,
                    },
                },
                {
                    type: "section",
                    text: {
                        type: "plain_text",
                        text: `User -- ${values.email} \n Message: \n ${values.message}`,
                    },
                },
            ],
        };
        const response = await fetch(reportUrl, {
            method: "POST",
            mode: "no-cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        handleClose();
    };
    return (
        <>
            <button onClick={handleOpen} className="px-2 py-1 rounded-lg bg-danger">
                Bug Report
            </button>
            {open && (
                <FormProvider {...methods}>
                    <div
                        className="fixed inset-0 z-10 overflow-y-auto"
                        aria-labelledby="modal-title"
                        role="dialog"
                        aria-modal="true"
                    >
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                                <div
                                    className="fixed inset-0 bg-opacity-75 transition-opacity"
                                    aria-hidden="true"
                                ></div>

                                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                                    &#8203;
                                </span>

                                <div className="inline-block overflow-hidden text-left align-bottom rounded-lg shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                    <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                <h3
                                                    className="pt-4 text-lg font-medium text-primeDark leading-6"
                                                    id="modal-title"
                                                >
                                                    Bug Report
                                                </h3>
                                                <div className="pt-4 mt-2">
                                                    <FormTextField
                                                        variant="outlined"
                                                        name="email"
                                                        placeholder="Please enter your email so we can get back to you"
                                                        required
                                                    />
                                                </div>
                                                <div className="pt-4 mt-2">
                                                    <FormTextField
                                                        variant="outlined"
                                                        multiline
                                                        rows="4"
                                                        name="message"
                                                        required
                                                        placeholder="Bug Report Message"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                        <button
                                            type="submit"
                                            className="inline-flex justify-center w-full px-4 py-2 text-base font-medium border border-transparent bg-primary rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                        >
                                            Submit
                                        </button>
                                        <button
                                            type="button"
                                            onClick={handleClose}
                                            className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium border border-gray-300 text-primeDark rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </FormProvider>
            )}
        </>
    );
}

export default BugReport;
