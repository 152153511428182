import { CoordsAny, Coords2D, Coords3D, Line } from "Global/Constants/layout";

/**
 * Returns `angle2`, in relation to `angle1`, as opposed to the origin angle 0
 */
export const getAngleDiff = (angle1: number, angle2: number) =>
    ((angle2 - angle1 + Math.PI * 3) % (Math.PI * 2)) - Math.PI;

/**
 * Gets the general direction difference between two angles
 *
 * @return     {-1 | 0 | 1}  -1 === Left | 0 === Straight | 1 === Right
 */
export const getAngleDirection = (angle1: number, angle2: number) => {
    angle1 %= Math.PI * 2;
    angle2 %= Math.PI * 2;
    if (angle2 < angle1) angle2 += Math.PI * 2;

    if (0 === accurateRound(angle2 - angle1, 8)) return 0;
    if (angle2 - Math.PI > angle1) return -1;
    return 1;
};

/**
 * Gets the general direction one line is going in relation to another line
 *
 * @return     {-1 | 0 | 1}  -1 === Left | 0 === Straight | 1 === Right
 */
export const getLinesDirection = (line1: Line, line2: Line) => {
    return getVectorDirection(
        {
            x: accurateRound(line1.p2.x - line1.p1.x, 8),
            y: accurateRound(line1.p2.y - line1.p1.y, 8),
        },
        {
            x: accurateRound(line2.p2.x - line2.p1.x, 8),
            y: accurateRound(line2.p2.y - line2.p1.y, 8),
        }
    );
};

/**
 * Gets the general direction of a vector in relation to another
 *
 * @return     {-1 | 0 | 1}  -1 === Left | 0 === Straight | 1 === Right
 */
export const getVectorDirection = (v1: CoordsAny, v2: CoordsAny) => {
    let dot = -v1.x * v2.y + v1.y * v2.x;

    if (0 < dot) return 1;
    if (0 > dot) return -1;
    return 0;
};

/**
 * Returns the shortest length from p to line v-w
 * @param  {object} p Point coordinate
 * @param  {object} v Line start coordinate
 * @param  {object} w Line end coordinate
 */
export const distToSegment = (p: CoordsAny, v: CoordsAny, w: CoordsAny) => {
    return Math.sqrt(distToSegmentSquared(p, v, w));
};
const distToSegmentSquared = (p: CoordsAny, v: CoordsAny, w: CoordsAny) => {
    var l2 = dist2(v, w);
    if (0 === l2) return dist2(p, v);

    var t = ((p.x - v.x) * (w.x - v.x) + (p.y - v.y) * (w.y - v.y)) / l2;
    t = Math.max(0, Math.min(1, t));

    return dist2(p, {
        x: v.x + t * (w.x - v.x),
        y: v.y + t * (w.y - v.y),
    });
};
const dist2 = (v: CoordsAny, w: CoordsAny) => (v.x - w.x) ** 2 + (v.y - w.y) ** 2;

/**
 * Gets the vertical angle of 2 points
 *
 * @param      {object}   p1                 The p 1
 * @param      {object}   p2                 The p 2
 * @param      {boolean}  [inRadians=false]  In radians
 * @return     {float}    The vertical angle.
 */
export const getVerticalAngle = (p1: Coords3D, p2: Coords3D, inRadians = true) => {
    const run = distance2D(p1, p2);
    const rise = p2.z - p1.z;
    const angle = Math.atan(rise / run);

    return inRadians ? angle : radiansToDegrees(angle);
};

/**
 * Gets the angle from a top-down viewpoint
 *
 * 0 === positive direction on x axis
 * rotation is clockwise (makes babylon.js logic easier to reason about)
 */
export const getHorizontalAngle = (p1: CoordsAny, p2: CoordsAny, isDegrees = false) => {
    const radians = Math.atan2(p1.y - p2.y, p2.x - p1.x);

    return isDegrees ? radiansToDegrees(radians) : radians;
};

export const distance2D = (p1: Coords2D, p2: Coords2D) => Math.hypot(p2.x - p1.x, p2.y - p1.y);

export const distance3D = (p1: Coords3D, p2: Coords3D) => {
    let a = Math.pow(p2.x - p1.x, 2),
        b = Math.pow(p2.y - p1.y, 2),
        c = Math.pow(p2.z - p1.z, 2);

    return Math.sqrt(a + b + c);
};

export const accurateRound = (num: number, decimalPlaces: number) => {
    let factor = 10 ** decimalPlaces;
    return Math.round(num * factor) / factor;
};

export const roundToMultiple = (num: number, m: number) => accurateRound(Math.round(num / m) * m, 4);

export const degreesToRadians = (degrees: number): number => (degrees * Math.PI) / 180;
export const radiansToDegrees = (radians: number): number => (radians * 180) / Math.PI;

export const rotatePoint = (angle: number, point: Coords2D, rotationPoint: Coords2D = { x: 0, y: 0 }) => {
    let cos = Math.cos(angle);
    let sin = Math.sin(angle);
    let dx = point.x - rotationPoint.x;
    let dy = point.y - rotationPoint.y;

    return {
        x: cos * dx - sin * dy + rotationPoint.x,
        y: sin * dx + cos * dy + rotationPoint.y,
    };
};

export const generateArc = ({
    centerX,
    centerY,
    radius,
    angleStart,
    angleEnd,
    tessellation,
}: {
    centerX: number;
    centerY: number;
    radius: number;
    angleStart: number;
    angleEnd: number;
    tessellation: number;
}) => {
    // // add 1 to tesselation since we're returning points, and tesselation concerns faces/edges
    // tessellation += 1

    let path = [];

    // get the angle difference
    let diff = angleEnd - angleStart;

    let inc = diff / tessellation;
    let n = tessellation + 1;
    var x, y, rad;

    for (var i = 0; i < n; i++) {
        rad = angleStart + inc * i;
        x = centerX + radius * Math.cos(rad);
        y = centerY + radius * Math.sin(rad);

        path.push({ x, y });
    }

    return path;
};
