import { ReturnedLandingTread } from "./LandingTreadForm";
import { GeneratedStatusEnum } from "Global/Constants/itemTypes";
import { landingTreadAddon } from "Global/Constants/Pricing";
import { materials, finishOptions, FinishOperatorEnum, materialSizes } from "Global/Constants/Materials";
import { OD1_Item } from "Global/Constants/OD1_Types";
import { ENDPOINT } from "Global/Constants/environment";
import axios from "axios";

export function processReturnedLandingTread(data: any): ReturnedLandingTread {
    const generator = data.generator;
    const generatedStatus = GeneratedStatusEnum.Generated;
    const orderNumber = data.orderNumber;
    const uuid = data.uuid;
    const treadWidth = data.treadWidth;
    const treadBodyDepth = data.treadBodyDepth;
    const treadThickness = data.treadThickness;
    const returnLeft = data.returnLeft;
    const returnRight = data.returnRight;
    const returnLengthRight = data.returnLengthRight;
    const returnLengthLeft = data.returnLengthLeft;
    const nosingDepth = data.nosingDepth;
    const nosingThickness = data.nosingThickness;
    const roundOverLeft = data.roundOverLeft;
    const roundOverRight = data.roundOverRight;
    const roundOverFront = data.roundOverFront;
    const roundOver = data.roundOver;
    const woodSpecies = data.woodSpecies;
    const distressed = data.distressed;
    const handScraped = data.handScraped;
    const wireBrushed = data.wireBrushed;
    const finishOption = data.finishOption;
    const bullnose = data.bullnose;
    const finalWidth = data.finalWidth;
    const finalDepth = data.finalDepth;
    const finalThickness = data.finalThickness;
    const boardFeet = data.boardFeet;
    const chop = data.chop;
    const bunk = data.bunk;
    const pdfUrl = data.pdfUrl;
    const rip = data.rip;
    const plane = data.plane;
    const chopQty = data.chopQty;
    const quantity = 1;

    let landingTread: ReturnedLandingTread = {
        generator,
        generatedStatus,
        orderNumber,
        uuid,
        name: `Custom Landing Tread (${treadWidth} x ${treadBodyDepth} x ${treadThickness})`,
        treadWidth,
        treadBodyDepth,
        treadThickness,
        returnLeft,
        returnRight,
        returnLengthLeft,
        returnLengthRight,
        nosingDepth,
        nosingThickness,
        roundOverLeft,
        roundOverRight,
        roundOverFront,
        roundOver,
        woodSpecies,
        finishOption,
        distressed,
        handScraped,
        wireBrushed,
        bullnose,
        finalWidth,
        finalDepth,
        finalThickness,
        boardFeet,
        chop,
        bunk,
        pdfUrl,
        rip,
        plane,
        chopQty,
        price: 0,
        quantity,
    };
    const price = calculateLandingTreadPrice(landingTread);
    landingTread.price = price;

    console.log(price);
    return landingTread;
}

export function calculateLandingTreadPrice(landingTread: Omit<ReturnedLandingTread, "price">) {
    let material = materials.find((material) => material.name === landingTread.woodSpecies);
    let finishOption = finishOptions.find((option) => option.name === landingTread.finishOption);

    if (!material || !finishOption) return 0.0;

    let depth = landingTread.treadBodyDepth + landingTread.nosingDepth;
    let width = landingTread.treadWidth;
    let thickness = landingTread.treadThickness;
    let board_feet = 0;
    let labor_total = 0;
    let finish_price = 0;
    let wood_total = 0;

    depth += landingTreadAddon.depth_addon;
    width += landingTreadAddon.width_addon;
    thickness += landingTreadAddon.thickness_addon;

    if (thickness < 1) {
        thickness = 1;
    }

    let bullnose_bf = 0;
    let main_bf = 0;
    let bn_depth = landingTread.nosingDepth + landingTreadAddon.bullnose_depth_addon;
    let bn_thickness =
        landingTread.nosingThickness + landingTreadAddon.bullnose_thickness_addon - landingTread.treadThickness;

    main_bf = depth * width * thickness;
    bullnose_bf = bn_depth * bn_thickness * width;
    board_feet = (main_bf + bullnose_bf) / 144;

    if (board_feet < landingTreadAddon.board_foot_limit) {
        labor_total = landingTreadAddon.low_labor;
        finish_price = landingTreadAddon.low_finish;
    } else {
        labor_total = landingTreadAddon.high_labor;
        finish_price = landingTreadAddon.high_finish;
    }

    wood_total = material.pricing.LandingTread * board_feet;

    let finish_total = 0;

    if (finishOption.finish_operator === FinishOperatorEnum.ADD) {
        finish_total = finish_price + finish_price * finishOption.finish_multiplier;
    } else {
        finish_total = finish_price - finish_price * finishOption.finish_multiplier;
    }

    let custom_features_price = 0;
    if (landingTread.distressed) {
        custom_features_price += landingTreadAddon.distressed;
    }
    if (landingTread.handScraped) {
        custom_features_price += landingTreadAddon.hand_scraped;
    }

    let total_cost = wood_total + labor_total + finish_total + custom_features_price;
    let materialIncrease = 1.1;
    if (material.id === 2) materialIncrease = 1.3;
    const nonWirebrushModifier = landingTread.wireBrushed ? 1.0 : 1.0;
    const inflationIncrease = 1.03;
    return total_cost * 1.15 * materialIncrease * nonWirebrushModifier * inflationIncrease;
}

export async function formatLandingTreadForOD1(returnedLandingTread: ReturnedLandingTread): Promise<OD1_Item> {
    let productID = 448783;
    let material = materials.find((material) => material.name === returnedLandingTread.woodSpecies);

    let finishOption = finishOptions
        .filter((option) => option.material_id === material?.id)
        .find((option) => option.name === returnedLandingTread.finishOption);

    let materialSize = materialSizes.find((size) => size.name === returnedLandingTread.bunk);

    let pdfUrl = await updateUrl(returnedLandingTread.orderNumber, returnedLandingTread.uuid);

    return {
        ProductID: productID,
        Quantity: returnedLandingTread.quantity,
        MaterialID: material?.id,
        MaterialSizeID: materialSize?.id,
        FinishOptionID: finishOption?.id === 0 ? "" : finishOption?.id,
        filename: "Landing Print.pdf",
        fileURL: pdfUrl,
        Price: returnedLandingTread.price.toFixed(2),
        Description: "",
        InHouseNotes: returnedLandingTread.name,
        BoardFootage: returnedLandingTread.boardFeet,
        WoodDimRipWidth1: returnedLandingTread.rip,
        WoodDimPlaneThickness1: returnedLandingTread.plane,
        WoodDimPRCChop1: returnedLandingTread.chop,
        WoodDimChopQuantity1: returnedLandingTread.chopQty,
        WoodDimFinalLength1: returnedLandingTread.finalDepth,
        WoodDimFinalWidth1: returnedLandingTread.finalWidth,
        WoodDimFinalHeight1: returnedLandingTread.finalThickness,
        WoodDimGlueType1: "",
    };
}
export function getFinishOptions(material_name: string) {
    if (!material_name) {
        return [];
    }
    let material_id = materials.find((material) => material.name === material_name)?.id;

    if (!material_id) {
        return [];
    }

    let options = finishOptions.filter((option) => option.material_id === material_id);

    return options.map((option) => {
        return { label: option.name, value: option.name };
    });
}
const updateUrl = async (orderNumber: string, uuid: string) => {
    const data = {
        orderNumber: orderNumber,
        fileName: `${uuid}.pdf`,
        generator: "landingTreadGenerator",
    };
    //get url
    let response = await axios.post(ENDPOINT + "/forge/s3Url", data);
    return response.data.url;
};
