import React, { useContext, useState } from "react";
import {
    Grid,
    Accordion,
    AccordionSummary,
    Typography,
    makeStyles,
    Theme,
    createStyles,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Button,
    AccordionActions,
    IconButton,
    Icon,
    CircularProgress,
    AccordionDetails,
    TextField,
    List,
    Box,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { OrderContext, ReturnedItem, GeneratorEnum, OrderContextState } from "../Context/OrderContext";
import { useHistory } from "react-router-dom";
import { ENDPOINT, OD1_DEV, OD1_TOKEN } from "../Global/Constants/environment";
import { useFetch } from "use-http";
import { formatTreadForOD1 } from "../Pages/TreadBuilder/MultiTreadHelpers";
import { ReturnedFlight } from "../Pages/Flight/FlightForm";
import { ReturnedGate } from "../Pages/GateBuilder/GateBuilderForm";
import { formatGateForOD1 } from "../Pages/GateBuilder/GateFormHelpers";
import { FlightItem } from "../Pages/Flight/FlightTypes";
import { StraightReturnedFlight } from "../Pages/Flight/StraightFlightForm";
import { ReturnedTread } from "../Pages/TreadBuilder/MultiTreadForm";
import { formatLandingForOD1 } from "../Pages/Landing/LandingHelpers";
import { ReturnedLanding } from "../Pages/Landing/LandingForm";
import { WarehouseItem } from "Pages/Warehouse";
import { formatWarehouseItemForOd1 } from "Pages/Warehouse/WarehouseHelpers";
import { ReturnedSwitchbackFlight } from "Pages/Flight/SwitchbackFlightForm";
import { ReturnedNinetyFlight } from "Pages/Flight/NinetyFlightForm";
import { ReturnedUFlight } from "Pages/UFlight/UFlightForm";
import { formatFlightItemsForOD1 } from "Pages/Flight/FlightParsing";
import { OD1_Item } from "Global/Constants/OD1_Types";
import { formatRiserForOD1 } from "Pages/Riser/RiserHelpers";
import { ReturnedRiser } from "Pages/Riser/RiserForm";
import { formatS4SForOD1 } from "Pages/S4S/S4SHelpers";
import { ReturnedS4S } from "Pages/S4S/S4SForm";
import { ReturnedLevelRun, formatLevelRunForOD1 } from "Pages/LevelRun/LevelRunHelpers";
import delegateItemEdit from "Logic/delegators/deleteItem";
import { GeneratedStatusEnum } from "Global/Constants/itemTypes";
import { getGeneratorName } from "Global/Constants/GeneratorNameMap";
import { formatLandingTreadForOD1 } from "Pages/LandingTreads/LandingTreadHelpers";
import { ReturnedLandingTread } from "Pages/LandingTreads/LandingTreadForm";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            overflowY: "auto",
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
    })
);

function OrderItems() {
    const classes = useStyles();
    const { state, dispatch } = useContext(OrderContext);
    const { items } = state;
    const history = useHistory();
    const { post, response } = useFetch(ENDPOINT);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [editing, setEditing] = useState<number | null>(null);
    const [editingValue, setEditingValue] = useState<number>(1);

    async function sendToOD1(order: OrderContextState) {
        const options = {
            headers: {
                "X-api-auth": OD1_TOKEN,
            },
        };
        setLoading(true);
        setError(false);
        if (!order.orderNumber) return;
        let { items } = order;
        let products: OD1_Item[] = [];

        await Promise.all(
            items.map(async (item) => {
                switch (item.generator) {
                    case GeneratorEnum.MultiTreads:
                        let multiTreads = await formatTreadForOD1(item as ReturnedTread);
                        products.push(multiTreads);
                        break;
                    case GeneratorEnum.Landing:
                        let landing = await formatLandingForOD1(item as ReturnedLanding);
                        products.push(landing);
                        break;
                    case GeneratorEnum.LandingTread:
                        let landingTread = await formatLandingTreadForOD1(item as ReturnedLandingTread);
                        products.push(landingTread);
                        break;
                    case GeneratorEnum.Riser:
                        let riser = await formatRiserForOD1(item as ReturnedRiser);
                        products.push(riser);
                        break;
                    case GeneratorEnum.S4S:
                        let s4s = await formatS4SForOD1(item as ReturnedS4S);
                        products.push(s4s);
                        break;
                    case GeneratorEnum.Flight:
                        let flightItems = await formatFlightItemsForOD1(item as ReturnedFlight);
                        flightItems.forEach((item) => {
                            products.push(item);
                        });
                        break;
                    case GeneratorEnum.StraightFlight:
                        let straightFlightItems = await formatFlightItemsForOD1(item as StraightReturnedFlight);
                        straightFlightItems.forEach((item) => {
                            products.push(item);
                        });
                        break;
                    case GeneratorEnum.NinetyFlight:
                        let ninetyFlightItems = await formatFlightItemsForOD1(item as ReturnedNinetyFlight);
                        ninetyFlightItems.forEach((item) => {
                            products.push(item);
                        });
                        break;
                    case GeneratorEnum.SwitchbackFlight:
                        let switchBackFlightItems = await formatFlightItemsForOD1(item as ReturnedSwitchbackFlight);
                        switchBackFlightItems.forEach((item) => {
                            products.push(item);
                        });
                        break;
                    case GeneratorEnum.UFlight:
                        let uFlightItems = await formatFlightItemsForOD1(item as ReturnedUFlight);
                        uFlightItems.forEach((item) => {
                            products.push(item);
                        });
                        break;
                    case GeneratorEnum.Gate:
                        let gateItems = await formatGateForOD1(item as ReturnedGate);
                        gateItems.forEach((item) => {
                            products.push(item);
                        });
                        break;
                    case GeneratorEnum.WarehouseItem:
                        let warehouseItem = formatWarehouseItemForOd1(item as WarehouseItem);
                        products.push(warehouseItem);
                        break;
                    case GeneratorEnum.LevelRun:
                        let levelRunItems = await formatLevelRunForOD1(item as ReturnedLevelRun);
                        levelRunItems.forEach((item) => products.push(item));
                        break;
                    default:
                        break;
                }
            })
        );

        let data = {
            OrderNumber: order.orderNumber,
            Products: products,
        };

        fetch(OD1_DEV + "/order_import_items.cfm", {
            method: "POST",
            ...options,
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                if (data.success === false) {
                    setLoading(false);
                    setError(true);
                }
                setLoading(false);
            });
    }

    function getUrl(generator: string) {
        if (generator === GeneratorEnum.MultiTreads) {
            return "/generators/multiTreads";
        } else if (generator === GeneratorEnum.Gate) {
            return "/generators/gates";
        } else {
            return "";
        }
    }
    function handleDelete(item: ReturnedItem, index: number) {
        delegateItemEdit({ order: state, item: item, index: index, dispatch: dispatch });
    }

    function handleEdit(item: ReturnedItem) {
        dispatch({ type: "EditOrderItem", uuid: item.uuid });
        let url = getUrl(item.generator);
        history.push(url);
    }
    async function handlePdf(item: ReturnedItem) {
        //get url
        const result = await post("/forge/s3Url", {
            orderNumber: item.orderNumber,
            fileName: `${item.uuid}.pdf`,
        });
        if (response.ok) {
            return result.url;
        }
    }

    return (
        <Card className={classes.root}>
            <CardHeader title={`Order Number: ${state.orderNumber}`} subheader={`Customer: ${state.customerName}`} />
            {items.length === 0 ? (
                <CardContent>
                    <Typography>No items on order</Typography>
                </CardContent>
            ) : (
                items.map((item, index) => {
                    let typedItem;
                    if (item.generator === GeneratorEnum.Flight) {
                        typedItem = item as ReturnedFlight;
                    } else if (item.generator === GeneratorEnum.Gate) {
                        typedItem = item as ReturnedGate;
                    } else if (item.generator === GeneratorEnum.StraightFlight) {
                        typedItem = item as StraightReturnedFlight;
                    } else if (item.generator === GeneratorEnum.SwitchbackFlight) {
                        typedItem = item as ReturnedSwitchbackFlight;
                    } else if (item.generator === GeneratorEnum.NinetyFlight) {
                        typedItem = item as ReturnedNinetyFlight;
                    } else if (item.generator === GeneratorEnum.UFlight) {
                        typedItem = item as ReturnedUFlight;
                    } else if (item.generator === GeneratorEnum.LevelRun) {
                        typedItem = item as ReturnedLevelRun;
                    }
                    return (
                        <Accordion key={index}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Grid container justify="space-between">
                                    <Grid item>
                                        <Typography className={classes.heading}>
                                            {item.name ? item.name : getGeneratorName(item.generator)}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.heading}>
                                            ${item.price && (item.quantity * item.price).toFixed(2)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                {item.generatedStatus === GeneratedStatusEnum.Stale ? (
                                    <Grid container spacing={1}>
                                        <Grid item>Item not Generated</Grid>
                                    </Grid>
                                ) : item.generatedStatus === GeneratedStatusEnum.Generating ? (
                                    <Grid container spacing={1}>
                                        <Grid item>Item Currently Generating</Grid>
                                    </Grid>
                                ) : (
                                    <Grid container spacing={1}>
                                        <Grid item container spacing={1} xs={12} justify="center" alignItems="center">
                                            <Grid item>Qty:</Grid>
                                            <Grid item>
                                                <TextField
                                                    size="small"
                                                    label="Quantity"
                                                    value={editing !== index ? item.quantity : editingValue}
                                                    variant="outlined"
                                                    type="number"
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                        setEditingValue(parseInt(e.target.value))
                                                    }
                                                    disabled={editing !== index}
                                                />
                                            </Grid>
                                            {editing === index ? (
                                                <>
                                                    <Grid item>
                                                        <IconButton
                                                            onClick={() => {
                                                                setEditingValue(item.quantity);
                                                                setEditing(null);
                                                            }}
                                                        >
                                                            <Icon>cancel</Icon>
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton
                                                            onClick={() => {
                                                                dispatch({
                                                                    type: "UpdateOrderItem",
                                                                    item: {
                                                                        ...item,
                                                                        quantity: editingValue,
                                                                    },
                                                                });
                                                                setEditing(null);
                                                            }}
                                                        >
                                                            <Icon>save</Icon>
                                                        </IconButton>
                                                    </Grid>
                                                </>
                                            ) : (
                                                <Grid item>
                                                    <IconButton
                                                        onClick={() => {
                                                            setEditingValue(item.quantity);
                                                            setEditing(index);
                                                        }}
                                                    >
                                                        <Icon>edit</Icon>
                                                    </IconButton>
                                                </Grid>
                                            )}
                                        </Grid>
                                        <Grid item container>
                                            <Grid item xs={12}>
                                                {typedItem && (
                                                    <List>
                                                        {typedItem.items &&
                                                            typedItem.items.map((subItem, index) => (
                                                                <OrderSubItem
                                                                    item={subItem}
                                                                    itemQuantity={item.quantity}
                                                                    key={index}
                                                                />
                                                            ))}
                                                    </List>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </AccordionDetails>
                            <AccordionActions>
                                {/* TODO add pdf for level runs */}
                                {item.generatedStatus === GeneratedStatusEnum.Generated && (
                                    <>
                                        <IconButton
                                            onClick={async () => {
                                                let pdf = await handlePdf(item);
                                                window.open(pdf);
                                            }}
                                        >
                                            <Icon>attachment</Icon>
                                        </IconButton>
                                        <IconButton onClick={() => handleEdit(item)}>
                                            <Icon>edit</Icon>
                                        </IconButton>
                                    </>
                                )}
                                <IconButton
                                    disabled={state.uuid === item.uuid}
                                    onClick={() => handleDelete(item, index)}
                                >
                                    <Icon>delete</Icon>
                                </IconButton>
                            </AccordionActions>
                        </Accordion>
                    );
                })
            )}
            <CardActions>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <Button disabled={items.length === 0} onClick={async () => await sendToOD1(state)}>
                        Send To Order
                    </Button>
                )}
                {error && <Typography color="error">Error sending to order</Typography>}
            </CardActions>
        </Card>
    );
}

function OrderSubItem(props: { item: FlightItem; itemQuantity: number }) {
    const { item, itemQuantity } = props;
    return (
        <Box my={1}>
            <Grid container justify="center" spacing={2}>
                <Grid item xs={2}>
                    {item.quantity * itemQuantity}
                </Grid>
                <Grid item xs={8}>
                    {item.name}
                </Grid>
                <Grid item xs={2}>
                    {item.price}
                </Grid>
            </Grid>
        </Box>
    );
}

export default OrderItems;
