import { ReturnedRiser } from "./RiserForm";
import { materials, finishOptions, FinishOperatorEnum, materialSizes } from "Global/Constants/Materials";
import { riserAddon } from "Global/Constants/Pricing";
import { ENDPOINT } from "Global/Constants/environment";
import axios from "axios";
import { GeneratedStatusEnum } from "Global/Constants/itemTypes";

export function processReturnedRiser(data: any): ReturnedRiser {
    const orderNumber = data.orderNumber;
    const generator = data.generator;
    const uuid = data.uuid;
    const riserWidth = data.riserWidth;
    const riserDepth = data.riserDepth;
    const riserThickness = data.riserThickness;
    const woodSpecies = data.woodSpecies;
    const finishOption = data.finishOption;
    const distressed = data.distressed;
    const handScraped = data.handScraped;
    const wireBrushed = data.wireBrushed;
    const finalWidth = data.finalWidth;
    const finalDepth = data.finalDepth;
    const finalThickness = data.finalThickness;
    const boardFeet = data.boardFeet;
    const rip = data.rip;
    const chop = data.chop;
    const plane = data.plane;
    const bunk = data.bunk;
    const chopQty = data.chopQty;
    const pdfUrl = data.pdfUrl;

    let riser: Omit<ReturnedRiser, "price"> = {
        generatedStatus: GeneratedStatusEnum.Generated,
        name: `Custom Riser (${riserWidth} x ${riserDepth} x ${riserThickness})`,
        orderNumber,
        generator,
        uuid,
        riserWidth,
        riserDepth,
        riserThickness,
        woodSpecies,
        finishOption,
        distressed,
        handScraped,
        wireBrushed,
        finalWidth,
        finalDepth,
        finalThickness,
        boardFeet,
        rip,
        chop,
        plane,
        bunk,
        chopQty,
        pdfUrl,
        quantity: 1,
    };

    const price = calculateRiserPrice(riser);
    return Object.assign(riser, { price: price });
}

export function calculateRiserPrice(riser: Omit<ReturnedRiser, "price">) {
    let material = materials.find((material) => material.name === riser.woodSpecies);
    let finishOption = finishOptions.find((option) => option.name === riser.finishOption);

    if (!material || !finishOption) return 0.0;

    let depth = riser.riserDepth;
    let width = riser.riserWidth;
    let thickness = riser.riserThickness;
    let board_feet = 0;
    let labor_total = 0;
    let finish_price = 0;
    let wood_total = 0;

    depth += riserAddon.depth_addon;
    width += riserAddon.width_addon;
    thickness += riserAddon.thickness_addon;

    if (thickness < 1) {
        thickness = 1;
    }

    board_feet = (depth * width * thickness) / 144;

    if (board_feet < riserAddon.board_foot_limit) {
        labor_total = riserAddon.low_labor;
        finish_price = riserAddon.low_finish;
    } else {
        labor_total = riserAddon.high_labor;
        finish_price = riserAddon.high_finish;
    }

    wood_total = material ? material.pricing.Riser * board_feet : 0;

    let finish_total = 0;

    if (finishOption.finish_operator === FinishOperatorEnum.ADD) {
        finish_total = finish_price + finish_price * finishOption.finish_multiplier;
    } else {
        finish_total = finish_price - finish_price * finishOption.finish_multiplier;
    }

    let custom_features_price = 0;
    if (riser.distressed) {
        custom_features_price += riserAddon.distressed;
    }
    if (riser.handScraped) {
        custom_features_price += riserAddon.hand_scraped;
    }

    let total_cost = wood_total + labor_total + finish_total + custom_features_price;
    let materialIncrease = 1.1;
    if (material.id === 2) materialIncrease = 1.3;

    const nonWirebrushModifier = riser.wireBrushed ? 1.0 : 1.0;
    const inflationIncrease = 1.03;
    return total_cost * 1.15 * materialIncrease * nonWirebrushModifier * inflationIncrease;
}

export async function formatRiserForOD1(riser: ReturnedRiser) {
    const productID = 436111;

    let material = materials.find((material) => material.name === riser.woodSpecies);

    let finishOption = finishOptions
        .filter((option) => option.material_id === material?.id)
        .find((option) => option.name === riser.finishOption);

    let materialSize = materialSizes.find((size) => size.name === riser.bunk);

    let pdfUrl = await updateUrl(riser.orderNumber, riser.uuid);

    return {
        ProductID: productID,
        Quantity: riser.quantity,
        MaterialID: material?.id,
        MaterialSizeID: materialSize?.id,
        FinishOptionID: finishOption?.id === 0 ? "" : finishOption?.id,
        filename: "Custom Riser Print.pdf",
        fileURL: pdfUrl,
        Price: riser.price.toFixed(2),
        Description: "",
        InHouseNotes: riser.name,
        BoardFootage: riser.boardFeet,
        WoodDimRipWidth1: riser.rip,
        WoodDimPlaneThickness1: riser.plane,
        WoodDimPRCChop1: riser.chop,
        WoodDimChopQuantity1: riser.chopQty,
        WoodDimFinalLength1: riser.finalDepth,
        WoodDimFinalWidth1: riser.finalWidth,
        WoodDimFinalHeight1: riser.finalThickness,
        WoodDimGlueType1: "",
    };
}
const updateUrl = async (orderNumber: string, uuid: string) => {
    const data = {
        orderNumber: orderNumber,
        fileName: `${uuid}.pdf`,
        generator: "treadGenerator",
    };
    //get url
    let response = await axios.post(ENDPOINT + "/forge/s3Url", data);
    return response.data.url;
};
