import { GeneratorEnum } from "../../Context/OrderContext";
import { StraightReturnedFlight } from "./StraightFlightForm";
import { parseFlightItems } from "./FlightParsing";
import { FlightItem } from "./FlightTypes";
import { accurateRound } from "Global/Helpers/Math";
import { GeneratedStatusEnum } from "Global/Constants/itemTypes";

export async function processStraightReturnedFlight(data: any): Promise<StraightReturnedFlight> {
    const name = "Floor to Wall Flight";
    const orderNumber = data.orderNumber;
    const generator = GeneratorEnum.StraightFlight;
    const uuid = data.uuid;

    const bottomFinishedFloorThickness = parseFloat(data.bottomFinishedFloorThickness);
    const ceilingGap = data.ceilingGap;
    const ceilingHeight = data.ceilingHeight;
    const customerTreads = data.customerTreads;
    const enduranceTreads = data.enduranceTreads;
    const fastenersFloor = data.fastenersFloor;
    const fastenersHeader = data.fastenersHeader;
    const finishOption = data.finishOption;
    const formTreadRise = data.formTreadRise;
    const handrail = data.handrail;
    const handrailHeight = data.handrailHeight;
    const handrailMaterial = data.handrailMaterial;
    const handrailFinish = data.handrailFinish;
    const headerHeight = data.headerHeight;
    const limitRun = data.limitRun;
    const meetCode = data.meetCode;
    const mountToSubfloor = data.mountToSubfloor;
    const posts = data.posts;
    const postInfill = data.postInfill;
    const revision = data.revision;
    const spaceConstraint = data.spaceConstraint;
    const stringerMaterial = data.stringerMaterial;
    const subfloorToSubfloor = data.subfloorToSubfloor;
    const taperedTreads = data.taperedTreads;
    const topFinishedFloorThickness = data.topFinishedFloorThickness;
    const topNosingDepth = data.topNosingDepth;
    const topTreadFlush = data.topTreadFlush;
    const treadCovers = data.treadCovers;
    const treadDepth = data.treadDepth;
    const treadFinish = data.treadFinish;
    const treadMaterial = data.treadMaterial;
    const treadNosing = data.treadNosing;
    const treadThickness = data.treadThickness;
    const treadWidth = data.treadWidth;
    const tubeSize = data.tubeSize;
    const handrailQty = data.handrailQty;
    const bomString = data.bomString;
    const postString = data.postString;
    const warnings = data.warnings;
    const notes = data.notes;
    const sendIt = data.sendIt;
    let price = 0.0;
    const pdfUrl = data.pdfUrl;
    const quantity = 1;

    let items: Array<FlightItem> = await parseFlightItems({
        bomString,
        postString,
        handrailQty,
        treadDepth,
        treadWidth,
        treadThickness,
        treadMaterial,
        treadFinish,
        handrail,
        handrailHeight,
        handrailMaterial,
        handrailFinish,
        postInfill,
    });
    items.forEach((item) => {
        price += item.price * item.quantity;
    });

    const returnedFlight: StraightReturnedFlight = {
        generatedStatus: GeneratedStatusEnum.Generated,
        name,
        orderNumber,
        generator,
        uuid,
        bottomFinishedFloorThickness,
        ceilingGap,
        ceilingHeight,
        customerTreads,
        enduranceTreads,
        fastenersFloor,
        fastenersHeader,
        finishOption,
        formTreadRise,
        handrail,
        handrailHeight,
        handrailQty,
        handrailMaterial,
        handrailFinish,
        headerHeight,
        limitRun,
        meetCode,
        mountToSubfloor,
        posts,
        postInfill,
        revision,
        spaceConstraint,
        stringerMaterial,
        subfloorToSubfloor,
        taperedTreads,
        topFinishedFloorThickness,
        topNosingDepth,
        topTreadFlush,
        treadCovers,
        treadDepth,
        treadFinish,
        treadMaterial,
        treadNosing,
        treadThickness,
        treadWidth,
        tubeSize,
        warnings,
        notes,
        sendIt,
        price: accurateRound(price, 2),
        pdfUrl,
        quantity,
        items: items,
    };

    return returnedFlight;
}
export async function formatStraightFlightForOD1(returnedFlight: StraightReturnedFlight) {
    let items = returnedFlight.items;
    let od1Items: any[] = [];

    items.forEach((item) => {
        let formattedItem = {};

        switch (item.type) {
            case "Standard Post":
                formattedItem = {
                    ProductID: item.productId,
                    Quantity: item.quantity,
                    Description: "",
                    InHouseNotes: item.description,
                    FinishOptionID: "",
                    fileURL: "",
                    //add material
                };
                break;
            case "Custom Post":
                formattedItem = {
                    ProductID: item.productId,
                    Quantity: item.quantity,
                    Price: item.price,
                    Description: "",
                    InHouseNotes: item.description,
                    FinishOptionID: "",
                    fileURL: "",
                    //add material
                };
                break;
            case "Handrail":
                formattedItem = {
                    ProductID: item.productId,
                    Quantity: item.quantity,
                    Description: "",
                    InHouseNotes: item.description,
                    FinishOptionID: "",
                    fileURL: "",
                    //add material
                };
                break;
            case "Hardware":
                formattedItem = {
                    ProductID: item.productId,
                    Quantity: item.quantity,
                    Description: "",
                    InHouseNotes: item.description,
                    FinishOptionID: "",
                    fileURL: "",
                };
                break;
            case "Stringer":
                formattedItem = {
                    ProductID: item.productId,
                    Quantity: item.quantity,
                    Price: item.price,
                    Description: "",
                    InHouseNotes: item.description,
                    FinishOptionID: "",
                    fileURL: "",
                    //add material
                };
                break;
            case "Nosing":
                formattedItem = {
                    ProductID: item.productId,
                    Quantity: item.quantity,
                    Price: item.price,
                    Description: "",
                    InHouseNotes: item.description,
                    FinishOptionID: "",
                    fileURL: "",
                };
                break;
            case "Nosing Insert":
                formattedItem = {
                    ProductID: item.productId,
                    Quantity: item.quantity,
                    Price: item.price,
                    Description: "",
                    InHouseNotes: item.description,
                    FinishOptionID: "",
                    fileURL: "",
                };
                break;
            case "Tread":
                formattedItem = {
                    ProductID: item.productId,
                    Quantity: item.quantity,
                    Price: item.price,
                    Description: "",
                    InHouseNotes: item.description,
                    FinishOptionID: "",
                    fileURL: "",
                };
                break;
            default:
                break;
        }

        od1Items.push(formattedItem);
    });
    return od1Items;
}

export function processFlightCheck(data: any) {
    const warnings = data.warnings;
    const notes = data.notes;
    const sendIt = data.sendIt;

    return {
        warnings,
        notes,
        sendIt,
    };
}
