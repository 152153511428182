import React, { useContext } from "react";
import { Grid, Button } from "@material-ui/core";
import { OrderContext, ReturnedItem } from "../Context/OrderContext";
import ForgeViewer from "Components/ForgeViewer";
import ReactDOM from "react-dom";

function GeneratorResults(props: GeneratorResultsProps) {
    const { returnedItem } = props;
    const { state, dispatch } = useContext(OrderContext);

    const [open, setOpen] = React.useState(false);

    function handleAdd() {
        if (returnedItem) {
            dispatch({ type: "AddOrderItem", item: returnedItem });
        }
    }

    function handleCancel() {
        dispatch({ type: "EditOrderItem", uuid: null });
    }
    function handleUpdate() {
        if (returnedItem) {
            dispatch({ type: "UpdateOrderItem", item: returnedItem });
        }
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                {state.uuid ? (
                    <Grid container>
                        <Grid item>
                            <Button onClick={handleCancel}>Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleUpdate} disabled={!returnedItem}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        <Button onClick={handleAdd} disabled={!returnedItem}>
                            Add to Cart
                        </Button>
                    </>
                )}
            </Grid>
        </Grid>
    );
}

interface GeneratorResultsProps {
    returnedItem: ReturnedItem | undefined;
}

interface PortalProps {
    children: React.ReactNode;
    isOpen: boolean;
    onClose: () => void;
}
const PortalModal = (props: PortalProps) => {
    const { children, isOpen, onClose } = props;
    if (!isOpen) return null;
    return ReactDOM.createPortal(
        <div className="fixed top-1/2 left-1/2 z-50 w-1/3 h-1/3">
            {children}
            <button className="close" onClick={onClose}>
                Close
            </button>
        </div>,
        document.body
    );
};
export default GeneratorResults;
