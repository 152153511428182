import React from "react";
import { makeStyles, CircularProgress, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    base: {
        position: "absolute",
        width: "100%",
        height: "100%",
        left: "0",
        top: "0",
    },
}));

const LoadCover = () => {
    const classes = useStyles();

    return (
        <div className={classes.base}>
            <CircularProgress />
        </div>
    );
};

export default LoadCover;
