import { GeneratorEnum } from "Context/OrderContext";

export function getGeneratorName(generatorEnum: GeneratorEnum): string {
    switch (generatorEnum) {
        case GeneratorEnum.MultiTreads:
            return "Tread Generator";
        case GeneratorEnum.Landing:
            return "Landing Generator";
        case GeneratorEnum.S4S:
            return "S4S Generator";
        case GeneratorEnum.Riser:
            return "Riser Generator";
        case GeneratorEnum.Gate:
            return "Gate Generator";
        case GeneratorEnum.Flight:
            return "Wall to Wall Flight";
        case GeneratorEnum.StraightFlight:
            return "Straight Flight";
        case GeneratorEnum.NinetyFlight:
            return " 90 Flight";
        case GeneratorEnum.SwitchbackFlight:
            return "Switchback Flight";
        case GeneratorEnum.UFlight:
            return "U Flight";
        case GeneratorEnum.LevelRun:
            return "Level Run Generator";
        default:
            return "";
    }
}
