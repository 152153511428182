import QueryString from "query-string";

import { LevelRunValues, GeneratedStatusEnum, OD1_Product } from "Global/Constants/itemTypes";
import { Item } from "Context/OrderContext";
import { FlightItem } from "Pages/Flight/FlightTypes";
import { OD1_TOKEN, OD1_DEV } from "Global/Constants/environment";
import { findPostInfillID, findPostTopStyle, findPostFootStyle } from "Global/Constants/Posts";
import { OD1_Item } from "Global/Constants/OD1_Types";
import { MetalFinishes } from "Global/Constants/MetalMaterials";
import { findHandrailFinish } from "Global/Constants/Handrail";
import { accurateRound } from "Global/Helpers/Math";
import { getGlassPrice } from "Global/Constants/Glass";

export interface ReturnedLevelRun extends LevelRunValues, Omit<Item, "pdfUrl"> {
    price: number;
    items: FlightItem[];
}

// export type OmittedReturnedLevelRun = Omit<
//     ReturnedLevelRun,
//     | "corners"
//     | "glassGap"
//     | "handrailType"
//     | "systemType"
//     | "panelThickness"
//     | "isInterior"
//     | "material"
//     | "glassPanels"
// >;

export async function processReturnedLevelRun(data: any) {
    const generator = data.generator;
    const name = "Level Run";
    const orderNumber = data.orderNumber;
    const uuid = data.uuid;
    const quantity = 1;

    const system = data.system;
    const systemHeight = data.systemHeight;
    const infill = data.infill;
    const handrail = data.handrail;
    const handrailInfo = data.handrailInfo;
    const handrailMaterialPass = data.handrailMaterialPass;
    const handrailMaterial = data.handrailMaterial;
    const handrailFinish = data.handrailFinish;
    const postTop = data.postTop;
    const postFoot = data.postFoot;
    const postMaterial = data.postMaterial;
    const postFinish = data.postFinish;
    const postInfo = data.postInfo;
    const runLength = data.runLength;
    const cornerOption = data.cornerOption;
    const footCoverOption = data.footCoverOption;
    const mountingSurface = data.mountingSurface;
    const handrailScrewOption = data.handrailScrewOption;
    //these fields from glass generator
    const corners = data.corners;
    const glassGap = data.glassGap;
    const handrailType = data.handrailType;
    const systemType = data.systemType;
    const panelThickness = data.panelThickness;
    const isInterior = data.isInterior;
    const material = data.material;
    const glassPanels = data.glassPanels;
    const structureInfo = data.structureInfo;
    const bomString = data.bomString;
    let price = 0.0;

    let returnedLevelRun: ReturnedLevelRun = {
        generator,
        generatedStatus: GeneratedStatusEnum.Generated,
        name,
        orderNumber,
        uuid,
        quantity,
        system,
        systemHeight,
        infill,
        handrail,
        handrailInfo,
        handrailMaterialPass,
        handrailMaterial,
        handrailFinish,
        postTop,
        postFoot,
        postInfo,
        postMaterial,
        postFinish,
        runLength,
        cornerOption,
        footCoverOption,
        mountingSurface,
        handrailScrewOption,
        corners,
        glassGap,
        handrailType,
        systemType,
        panelThickness,
        isInterior,
        material,
        glassPanels,
        structureInfo,
        price: price,
        items: [],
    };

    //parse bom
    let items = await parseLevelRunBOM(bomString, returnedLevelRun);
    returnedLevelRun.items = [...items];
    //accumulate price
    items.forEach((item) => {
        price += item.price * item.quantity;
    });
    return Object.assign(returnedLevelRun, { price: price });
}

async function parseLevelRunBOM(bomString: string, returnedLevelRun: ReturnedLevelRun) {
    // [quantity, productID]
    const bomItems = bomString.split("|");
    const items: Array<FlightItem> = [];

    //parse Posts
    if (returnedLevelRun.postInfo.length > 0) {
        let postItems = await parsePosts(returnedLevelRun);
        postItems.forEach((item) => items.push(item));
    }

    //parseHandrail
    if (returnedLevelRun.handrailInfo.length > 0) {
        let handrailItems = await parseHandrail(
            returnedLevelRun.handrailInfo,
            returnedLevelRun.handrail,
            returnedLevelRun.handrailMaterialPass,
            returnedLevelRun.handrailFinish
        );
        handrailItems.forEach((handrail) => items.push(handrail));
    }

    //parse glass panels
    if (returnedLevelRun.glassPanels.length > 0) {
        let glassPanelItems = await parseGlassPanels(returnedLevelRun);
        glassPanelItems.forEach((item) => items.push(item));
    }

    //parse structure items
    if (returnedLevelRun.structureInfo.length > 0) {
        let structureInfoItems = await parseStructureInfo(returnedLevelRun);
        structureInfoItems.forEach((item) => items.push(item));
    }

    //parse Standard Products
    let standardItems = await parseStandardProducts(bomItems);
    standardItems.forEach((item) => items.push(item));

    return items;
}

async function parseStandardProducts(standardProductStrings: Array<string>): Promise<Array<FlightItem>> {
    let standardProducts: Array<{ productID: string; quantity: string }> = [];
    let items: Array<FlightItem> = [];

    standardProductStrings.forEach((item) => {
        const product = item.split(",");
        standardProducts.push({ productID: product[1], quantity: product[0] });
    });

    const standardProductIds = standardProducts.map((product) => product.productID);
    const results = await getProducts(`ProductIDs=${standardProductIds.toString()}`);
    if (results) {
        if (results.products) {
            results.products.forEach((product: OD1_Product) => {
                let foundProduct = standardProducts.find((prod) => parseInt(prod.productID) === product.ProductID);
                if (foundProduct) {
                    items.push({
                        productId: product.ProductID,
                        name: product.ProductName,
                        description: product.ProductDescription,
                        quantity: parseInt(foundProduct.quantity),
                        price: product.UnitPrice,
                        type: "Hardware",
                    });
                }
            });
        }
    }

    return items;
}
async function parsePosts(returnedLevelRun: ReturnedLevelRun): Promise<Array<FlightItem>> {
    if (!(returnedLevelRun.postInfo.length > 0)) {
        return [];
    }

    let items: Array<FlightItem> = [];
    const postArray = returnedLevelRun.postInfo.split("|");
    let postTopStyle = findPostTopStyle(returnedLevelRun.postTop);
    let postFootStyle = findPostFootStyle(returnedLevelRun.postFoot);

    for (let i = 0; i < postArray.length; i++) {
        // [post type, quantity]
        const postSplit = postArray[i].split(",");
        const postConfig = findPostInfillID(returnedLevelRun.infill);
        let productTypeGroupID, productTypeID;

        if (postConfig) {
            let foundTopStyle = postConfig.topStyle?.find((style) => style.name === postTopStyle?.name);
            let foundFootStyle = foundTopStyle?.footStyle.find((style) => style.name === postFootStyle?.name);
            productTypeGroupID = foundFootStyle?.productTypeGroupID;
            productTypeID = foundFootStyle?.productTypeID;
        }

        const postObject = {
            MaterialID: 73,
            PostFootStyleID: postFootStyle ? postFootStyle.PostFootStyleID : 1,
            PostTopStyleID: postTopStyle ? postTopStyle.PostTopStyleID : 1,
            PostConfiguration: postSplit[0],
            PostSystemHeight: returnedLevelRun.systemHeight,
            ProductTypeGroupID: productTypeGroupID,
            ProductTypeID: productTypeID,
        };

        let searchParams = QueryString.stringify(postObject, { encode: false });
        let results = await getProducts(searchParams);

        if (results) {
            if (results.products && results.products.length > 0) {
                let returnedPost = results.products[0];
                items.push({
                    productId: returnedPost.ProductID,
                    name: returnedPost.ProductName,
                    description: returnedPost.ProductDescription,
                    quantity: parseInt(postSplit[1]),
                    price: returnedPost.UnitPrice,
                    material: "Aluminum",
                    type: "Post",
                });
            }
        }
    }
    return items;
}
async function parseHandrail(
    handrailInfo: string,
    handrail: string,
    handrailMaterialPass: string,
    handrailFinish: string
): Promise<Array<FlightItem>> {
    let items: Array<FlightItem> = [];
    if (!(handrailInfo.length > 0)) {
        return [];
    }

    let handrailItems = handrailInfo.split("|");
    for (let i = 0; i < handrailItems.length; i++) {
        // [length, quantity]
        let handrailSplit = handrailItems[i].split(",");

        let handrailObject = {
            HandrailLength: parseFloat(handrailSplit[0]),
            HandrailProfile: handrail === "Aluminum" ? "1x2" : handrail,
        };
        let searchParams = QueryString.stringify(handrailObject, { encode: false });
        let results = await getProducts(searchParams);
        if (results) {
            if (results.products && results.products.length > 0) {
                let returnedHandrail = results.products[0];
                items.push({
                    productId: returnedHandrail.ProductID,
                    name: returnedHandrail.ProductName,
                    description: returnedHandrail.ProductDescription,
                    quantity: parseInt(handrailSplit[1]),
                    price: returnedHandrail.UnitPrice,
                    material: handrailMaterialPass,
                    finishOption: handrailFinish,
                    type: "Handrail",
                });
            }
        }
    }
    return items;
}

async function parseGlassPanels(returnedLevelRun: ReturnedLevelRun): Promise<Array<FlightItem>> {
    let glassPanelItems: FlightItem[] = [];

    let parsedPanels = returnedLevelRun.glassPanels.split("|");
    parsedPanels.forEach((parsedPanel) => {
        // [baseWidth, BaseHeight]
        let panel = parsedPanel.split(",");
        let panelSquareFeet = (parseFloat(panel[0]) * parseFloat(panel[1])) / 144;

        let price = returnedLevelRun.panelThickness
            ? accurateRound(getGlassPrice(returnedLevelRun.panelThickness, panelSquareFeet), 2)
            : 0;

        glassPanelItems.push({
            productId: 443743,
            name: "Custom Glass Panel",
            quantity: +panel[2] || 1,
            description: `Custom Glass Panel ${panel[0]} x ${panel[1]}`,
            price: price,
            type: "Glass",
        });
    });

    return glassPanelItems;
}

async function parseStructureInfo(returnedLevelRun: ReturnedLevelRun): Promise<Array<FlightItem>> {
    let structureItems: FlightItem[] = [];
    let structureInfoArray = returnedLevelRun.structureInfo.split("|");

    let structureProducts: Array<{ productID: string; quantity: string }> = [];

    structureInfoArray.forEach((item) => {
        //[qty, productId]
        const product = item.split(",");
        structureProducts.push({ productID: product[1], quantity: product[0] });
    });

    const structureProductIds = structureProducts.map((product) => product.productID);
    const results = await getProducts(`ProductIDs=${structureProductIds.toString()}`);
    if (results) {
        if (results.products) {
            results.products.forEach((product: OD1_Product) => {
                let foundProduct = structureProducts.find((prod) => parseInt(prod.productID) === product.ProductID);
                if (foundProduct) {
                    structureItems.push({
                        productId: product.ProductID,
                        name: product.ProductName,
                        description: product.ProductDescription,
                        quantity: parseInt(foundProduct.quantity),
                        price: product.UnitPrice,
                        type: "Hardware",
                    });
                }
            });
        }
    }

    return structureItems;
}

async function getProducts(queryParams: string) {
    const config = {
        headers: {
            "X-api-auth": OD1_TOKEN,
        },
    };

    let response = await fetch(OD1_DEV + `/products_get_info.cfm?${queryParams}`, config);

    let results = await response.json();

    return results;
}

export async function formatLevelRunForOD1(returnedLevelRun: ReturnedLevelRun): Promise<Array<OD1_Item>> {
    const levelRunItems = returnedLevelRun.items;
    let od1Items: OD1_Item[] = [];

    let postFinish = MetalFinishes.find((finish) => finish.name === returnedLevelRun.postFinish)?.id;
    let handrailFinish = findHandrailFinish(
        returnedLevelRun.handrail,
        returnedLevelRun.handrailMaterialPass,
        returnedLevelRun.handrailFinish
    )?.id;

    levelRunItems.forEach((item) => {
        switch (item.type) {
            case "Hardware":
                od1Items.push({
                    ProductID: item.productId,
                    Quantity: item.quantity,
                    Description: item.description,
                    FinishOptionID: "",
                    fileURL: "",
                });
                break;
            case "Handrail":
                //TODO: add handrail finish
                od1Items.push({
                    ProductID: item.productId,
                    Quantity: item.quantity,
                    Description: item.description,
                    FinishOptionID: handrailFinish ? handrailFinish : "",
                    fileURL: "",
                });
                break;
            case "Post":
                //TODO: add post finish
                od1Items.push({
                    ProductID: item.productId,
                    Quantity: item.quantity,
                    Description: item.description,
                    FinishOptionID: postFinish ? postFinish : "",
                    fileURL: "",
                });
                break;
            case "Glass":
                //TODO: add post finish
                od1Items.push({
                    ProductID: item.productId,
                    Quantity: item.quantity,
                    Description: item.description,
                    InHouseNotes: item.description,
                    Price: item.price,
                    FinishOptionID: "",
                    fileURL: "",
                });
                break;
        }
    });

    return od1Items;
}
