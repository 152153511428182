//price per square foot
export const GlassPanels = [
    {
        thickness: "1/2",
        price: 28.65,
    },
    {
        thickness: "3/8",
        price: 20.25,
    },
    {
        thickness: "9/16EVA",
        price: 48.85,
    },
    {
        thickness: "9/16SGP",
        price: 59.3,
    },
];

export function getGlassThicknesses() {
    return GlassPanels.map((price) => ({ label: price.thickness, value: price.thickness }));
}

export function getGlassPrice(thickness: string, squareFeet: number) {
    let panel = GlassPanels.find((glass) => glass.thickness === thickness);

    if (!panel) {
        return 0;
    }

    return panel.price * squareFeet;
}
