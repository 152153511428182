import { useEffect } from "react";
import isEqual from "lodash/isEqual";

type obj = any;

/**
 * Used to invert control of a `react-hook-form` component
 *
 * @param      {array}     watched   `react-hook-form` watch result
 * @param      {array}     values    Values being tracked
 * @param      {function}  onChange  What to do on change
 */
const useFormInversion = (watched: obj, values: obj, onChange: (args: any) => void) => {
    useEffect(() => {
        if (onChange && values) {
            const update = {} as { [key: string]: any };

            Object.entries(watched).forEach(([key, value]) => {
                if ("number" === typeof values[key]) {
                    value = Number(value);
                }

                if (!isEqual(values[key], value)) {
                    update[key] = value;
                }
            });

            if (Object.keys(update).length) onChange(update);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watched]);
};

export default useFormInversion;
