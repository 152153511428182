import { GeneratorEnum } from "../../Context/OrderContext";
import { ReturnedFlight } from "./FlightForm";
import { FlightItem } from "./FlightTypes";
import { parseFlightItems } from "./FlightParsing";
import { GeneratedStatusEnum } from "Global/Constants/itemTypes";

export async function processReturnedFlight(data: any): Promise<ReturnedFlight> {
    const name = "Wall to Wall Flight";
    const orderNumber = data.orderNumber.toString();
    const generator = GeneratorEnum.Flight;
    const uuid = data.uuid;
    const bottomFinishedFloorThickness = data.bottomFinishedFloorThickness;
    const ceilingGap = data.ceilingGap;
    const ceilingHeight = data.ceilingHeight;
    const customerTreads = data.customerTreads;
    const fastenersFloor = data.fastenersFloor;
    const fastenersWall = data.fastenersWall;
    const finishOption = data.finishOption;
    const formTreadRise = data.formTreadRise;
    const headerHeightLower = data.headerHeightLower;
    const headerHeightUpper = data.headerHeightUpper;
    const revision = data.revision;
    const meetCode = data.meetCode;
    const stringerMaterial = data.stringerMaterial;
    const subfloorToSubfloor = data.subfloorToSubfloor;
    const taperedTreads = data.taperedTreads;
    const topFinishedFloorThickness = data.topFinishedFloorThickness;
    const topNosingDepth = data.topNosingDepth;
    const treadDepth = data.treadDepth;
    const treadThickness = data.treadThickness;
    const treadWidth = data.treadWidth;
    const tubeSize = data.tubeSize;
    const wallToWall = data.wallToWall;
    const enduranceTreads = data.enduranceTreads;
    const treadMaterial = data.treadMaterial;
    const treadFinish = data.treadFinish;
    const posts = data.posts;
    const handrail = data.handrail;
    const handrailMaterial = data.handrailMaterial;
    const handrailFinish = data.handrailFinish;
    const postInfill = data.postInfill;
    const handrailHeight = data.handrailHeight;
    const handrailQty = data.handrailQty;
    const bomString = data.bomString;
    const postString = data.postString;
    const warnings = data.warnings;
    const notes = data.notes;
    const sendIt = data.sendIt;
    let price = 0.0;
    const pdfUrl = data.pdfUrl;
    const quantity = 1;

    let items: Array<FlightItem> = await parseFlightItems({
        bomString,
        postString,
        handrailQty,
        treadDepth,
        treadWidth,
        treadThickness,
        treadMaterial,
        treadFinish,
        handrail,
        handrailHeight,
        handrailMaterial,
        handrailFinish,
        postInfill,
    });
    items.forEach((item) => {
        price += item.price * item.quantity;
    });

    const returnedFlight = {
        name,
        orderNumber,
        generator,
        uuid,
        generatedStatus: GeneratedStatusEnum.Generated,
        bottomFinishedFloorThickness,
        ceilingGap,
        ceilingHeight,
        customerTreads,
        fastenersFloor,
        fastenersWall,
        finishOption,
        formTreadRise,
        headerHeightLower,
        headerHeightUpper,
        revision,
        meetCode,
        stringerMaterial,
        subfloorToSubfloor,
        taperedTreads,
        topFinishedFloorThickness,
        topNosingDepth,
        treadThickness,
        treadWidth,
        wallToWall,
        enduranceTreads,
        treadMaterial,
        treadFinish,
        tubeSize,
        posts,
        handrail,
        postInfill,
        handrailHeight,
        handrailQty,
        handrailMaterial,
        handrailFinish,
        warnings,
        notes,
        sendIt,
        price,
        pdfUrl,
        quantity,
        items: items,
    };

    return returnedFlight;
}

export function processFlightCheck(data: any) {
    const status = data.status;
    const warnings = data.warnings;
    const notes = data.notes;
    const sendIt = data.sendIt;

    return {
        status,
        warnings,
        notes,
        sendIt,
    };
}
