import React from "react";
import { FormControlLabel, Checkbox, CheckboxProps } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";

function FormCheckbox(props: FormCheckboxProps) {
    const { name, label, ...other } = props;
    const { control, onChange = undefined } = useFormContext() as UseFormMethodsWithOnBlur;

    return (
        <FormControlLabel
            control={
                <Controller
                    name={name}
                    control={control}
                    render={({ onChange: onChangeHook, value }) => {
                        return (
                            <Checkbox
                                checked={value}
                                onChange={(e) => {
                                    onChangeHook(e.target.checked);
                                    if (onChange) {
                                        onChange({ name, value: e.target.checked });
                                    }
                                }}
                                {...other}
                            />
                        );
                    }}
                />
            }
            label={label}
            name={name}
        />
    );
}

interface FormCheckboxProps extends CheckboxProps {
    label: string;
    name: string;
}
export default FormCheckbox;
