export enum GateSystemType {
    "Flat Top",
    "Universal Top",
}

export enum GateInfill {
    "Stainless",
    "Onyx",
}

export enum GateLatchSide {
    "Left",
    "Right",
}

export interface GateFinish {
    id: number;
    name: string;
    materialIDs: Array<number>;
    price: number;
}
export const GateFinishes: GateFinish[] = [
    {
        id: 231,
        name: "PC: Black",
        materialIDs: [73, 75, 71],
        price: 0,
    },
    {
        id: 421,
        name: "PC Fluoropolymer: Black",
        materialIDs: [73, 75, 71],
        price: 25,
    },
    {
        id: 440,
        name: "PC Fluoropolymer: Apollo White",
        materialIDs: [73, 75, 71],
        price: 50,
    },
    {
        id: 459,
        name: "PC: Colonial Grey",
        materialIDs: [73, 75, 71],
        price: 25,
    },
    {
        id: 465,
        name: "PC: Apollo White",
        materialIDs: [73, 75, 71],
        price: 25,
    },
    {
        id: 466,
        name: "PC: Bone White",
        materialIDs: [73, 75, 71],
        price: 25,
    },
    {
        id: 467,
        name: "PC: Fashion Grey",
        materialIDs: [73, 75, 71],
        price: 25,
    },
    {
        id: 468,
        name: "PC: Copper Vein",
        materialIDs: [73, 75, 71],
        price: 25,
    },
    {
        id: 523,
        name: "PC: Hunter Green",
        materialIDs: [73, 75, 71],
        price: 25,
    },
    {
        id: 576,
        name: "PC: Sandstone",
        materialIDs: [73, 75, 71],
        price: 25,
    },
    {
        id: 578,
        name: "PC: Light Blue",
        materialIDs: [73, 75, 71],
        price: 25,
    },
    {
        id: 579,
        name: "PC: Super Bronze",
        materialIDs: [73, 75, 71],
        price: 25,
    },
    {
        id: 622,
        name: "PC: Charcoal",
        materialIDs: [73, 75, 71],
        price: 25,
    },
    {
        id: 626,
        name: "PC Fluoropolymer: Colonial Grey",
        materialIDs: [73, 75, 71],
        price: 50,
    },
    {
        id: 708,
        name: "PC: Speedboat Silver",
        materialIDs: [73, 75, 71],
        price: 25,
    },
    {
        id: 711,
        name: "PC Fluoropolymer: Speedboat Silver",
        materialIDs: [73, 75, 71],
        price: 50,
    },
    {
        id: 712,
        name: "PC Fluoropolymer: Fashion Grey",
        materialIDs: [73, 75, 71],
        price: 50,
    },
    {
        id: 713,
        name: "PC Fluoropolymer: Bone White",
        materialIDs: [73, 75, 71],
        price: 50,
    },
    {
        id: 717,
        name: "PC: Rust Spice",
        materialIDs: [73, 75, 71],
        price: 25,
    },
    {
        id: 840,
        name: "Raw Aluminum - No Finish",
        materialIDs: [73],
        price: 0,
    },
    {
        id: 434,
        name: "Brushed Stainless",
        materialIDs: [75, 71],
        price: 0,
    },
];

export interface GateMaterial {
    id: number;
    name: string;
    generatorName: string;
}

export const GateMaterials = [
    { id: 73, name: "Aluminum", generatorName: "Aluminum" },
    { id: 75, name: "2205 Stainless Steel", generatorName: "2205" },
    { id: 71, name: "304 Stainless Steel", generatorName: "304" },
];

export const GateMaterialSize = [{ id: 24, name: "Aluminum" }];
