// eslint-disable-next-line
import { Box, Container, Grid } from "@material-ui/core";
import TestViewerFunc from "Components/ForgeViewer/TestViewerFunc";
import useGeneratorSocket from "Hooks/useGeneratorSocket";
import React, { useContext, useEffect, useMemo } from "react";
import { Route, RouteComponentProps, Switch, useHistory } from "react-router-dom";
import OrderItems from "../Components/OrderItems"; // eslint-disable-next-line
import { OrderContext } from "../Context/OrderContext";
import GateBuilderForm from "./GateBuilder/GateBuilderForm";
import GeneratorResults from "./GeneratorResults";
import LandingForm from "./Landing/LandingForm";
import LandingTreadForm from "./LandingTreads/LandingTreadForm";
import RiserForm from "./Riser/RiserForm";
import S4SForm from "./S4S/S4SForm";
import MultiTreadForm from "./TreadBuilder/MultiTreadForm";

function Generators(props: GeneratorsProps) {
    const { match } = props;
    // eslint-disable-next-line
    const { state } = useContext(OrderContext);
    const {
        loading,
        setLoading,
        connectedSocket,
        flightCheck,
        returnedItem,
        setReturnedItem,
        selectedId,
        setSelectedId,
    } = useGeneratorSocket();
    const history = useHistory();
    useEffect(() => {
        setSelectedId(null);
        setReturnedItem(undefined);
    }, [history.location.pathname]); //eslint-disable-line

    const testMemo = useMemo(() => {
        return viewablesToBubbles(selectedId ? [selectedId] : [], state.orderNumber ? state.orderNumber : "");
    }, [selectedId, state.orderNumber]);
    // DOM rendering ************************************
    return (
        <Container maxWidth={false} className="container block">
            <Box pt={4}>
                <Grid container spacing={2}>
                    <Grid item container xs={3} spacing={2}>
                        <Grid item xs={12}>
                            <Switch>
                                <Route exact path={`${match.path}/gates`}>
                                    <GateBuilderForm
                                        loading={loading}
                                        setLoading={setLoading}
                                        connectedSocket={connectedSocket}
                                        setReturnedItem={setReturnedItem}
                                        returnedItem={returnedItem}
                                        setSelectedId={setSelectedId}
                                    />
                                </Route>
                                <Route exact path={`${match.path}/multiTreads`}>
                                    <MultiTreadForm
                                        setReturnedItem={setReturnedItem}
                                        returnedItem={returnedItem}
                                        loading={loading}
                                        setLoading={setLoading}
                                        connectedSocket={connectedSocket}
                                    />
                                </Route>
                                <Route exact path={`${match.path}/landing`}>
                                    <LandingForm
                                        setReturnedLanding={setReturnedItem}
                                        returnedLanding={returnedItem}
                                        loading={loading}
                                        setLoading={setLoading}
                                        connectedSocket={connectedSocket}
                                    />
                                </Route>
                                <Route exact path={`${match.path}/landingTreads`}>
                                    <LandingTreadForm
                                        setReturnedItem={setReturnedItem}
                                        returnedItem={returnedItem}
                                        loading={loading}
                                        setLoading={setLoading}
                                        connectedSocket={connectedSocket}
                                    />
                                </Route>
                                <Route exact path={`${match.path}/riser`}>
                                    <RiserForm
                                        setReturnedItem={setReturnedItem}
                                        returnedItem={returnedItem}
                                        loading={loading}
                                        setLoading={setLoading}
                                        connectedSocket={connectedSocket}
                                    />
                                </Route>
                                <Route exact path={`${match.path}/s4s`}>
                                    <S4SForm
                                        setReturnedItem={setReturnedItem}
                                        returnedItem={returnedItem}
                                        loading={loading}
                                        setLoading={setLoading}
                                        connectedSocket={connectedSocket}
                                    />
                                </Route>
                            </Switch>
                        </Grid>
                    </Grid>
                    <Grid item container xs={6} spacing={2}>
                        <Grid item xs={12}>
                            {selectedId && state.orderNumber && <TestViewerFunc bubbles={testMemo} />}
                        </Grid>
                        <Grid item>
                            <GeneratorResults returnedItem={returnedItem} />
                        </Grid>
                    </Grid>
                    <Grid item container xs={3} spacing={2}>
                        <Grid item xs={12}>
                            <OrderItems />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

function viewablesToBubbles(ids: Array<string>, orderNumber: string) {
    console.log("in viewables");
    return ids.map((id, index) => ({
        url: `https://s3.us-east-2.amazonaws.com/voe.stairsupplies.com/${orderNumber}/${
            id.split(".")[0]
        }/viewables/bubble.json`,
        xform: { x: 0, y: 50 * index, z: 0 },
    }));
}
interface GeneratorsProps extends RouteComponentProps {}
export default Generators;
