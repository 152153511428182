import { Dispatch } from "react";
import { GeneratorEnum, Item, OrderContextState } from "Context/OrderContext";
import { OrderContextAction } from "Context/OrderReducer";

import levelRunDelegator from "./levelRun";

const delegatorMap: Record<string, (p: DelegatorProps) => void> = {
    [GeneratorEnum.LevelRun]: levelRunDelegator,
    // "MultiTreads" = "unifiedTreadGenerator",
    // "Gate" = "Gate",
    // "GlassPanel" = "GlassPanel",
    // "FlightEconomy" = "FlightEconomy",
    // "FlightCheck" = "FlightCheck",
    // "Flight" = "flightGenerator",
    // "StraightFlight" = "straightFlightGenerator",
    // "NinetyFlight" = "ninetyFlightGenerator",
    // "SwitchbackFlight" = "switchbackFlightGenerator",
    // "UFlight" = "uFlight",
    // "Landing" = "landingGenerator",
    // "Riser" = "riserGenerator",
    // "S4S" = "s4sGenerator",
    // "WarehouseItem" = "WarehouseItem",
};

const delegateItemEdit = (props: DelegatorProps) => {
    if ("string" === typeof props.item) {
        props.item = props.order.items.find(({ uuid }) => uuid === props.item);
    }

    if (delegatorMap[props.item.generator]) {
        return delegatorMap[props.item.generator](props);
    } else {
        console.log("calling dispatch");
        return props.dispatch({ type: "RemoveOrderItem", uuid: props.item.uuid, index: props.index! });
    }
};

export interface DelegatorProps {
    order: OrderContextState;
    item: any;
    dispatch: Dispatch<OrderContextAction>;
    index?: number;
}

export default delegateItemEdit;
