import { ReturnedNinetyFlight } from "./NinetyFlightForm";
import { GeneratorEnum } from "Context/OrderContext";
import { FlightItem } from "./FlightTypes";
import { parseFlightItems } from "./FlightParsing";
import { GeneratedStatusEnum } from "Global/Constants/itemTypes";

export async function processReturnedNinetyFlight(data: any): Promise<ReturnedNinetyFlight> {
    const name = "Ninety Flight";
    const generator = GeneratorEnum.NinetyFlight;
    const orderNumber = data.orderNumber.toString();
    const uuid = data.uuid;
    const pdfUrl = data.pdfUrl;
    const bottomFinishedFloorThickness = data.bottomFinishedFloorThickness;
    const ceilingGap = data.ceilingGap;
    const ceilingHeight = data.ceilingHeight;
    const customerTreads = data.customerTreads;
    const drywallLower = data.drywallLower;
    const drywallUpper = data.drywallUpper;
    const enduranceTreads = data.enduranceTreads;
    const fastenersFloor = data.fastenersFloor;
    const fastenersHeader = data.fastenersHeader;
    const fastenersWall = data.fastenersWall;
    const finishOption = data.finishOption;
    const formTreadRise = data.formTreadRise;
    const handrail = data.handrail;
    const handrailFinish = data.handrailFinish;
    const handrailHeight = data.handrailHeight;
    const handrailMaterial = data.handrailMaterial;
    const headerHeight = data.headerHeight;
    const headerToWall = data.headerToWall;
    const hockeyStickMounting = data.hockeyStickMounting;
    const limitRun = data.limitRun;
    const matchTreads = data.matchTreads;
    const meetCode = data.meetCode;
    const mountToSubfloor = data.mountToSubfloor;
    const postInfill = data.postInfill;
    const posts = data.posts;
    const revision = data.revision;
    const spaceConstraint = data.spaceConstraint;
    const stairDirection = data.stairDirection;
    const stringerMaterial = data.stringerMaterial;
    const subfloorToSubfloor = data.subfloorToSubfloor;
    const taperedTreads = data.taperedTreads;
    const topFinishedFloorThickness = data.topFinishedFloorThickness;
    const topNosingDepth = data.topNosingDepth;
    const topTreadFlush = data.topTreadFlush;
    const treadCovers = data.treadCovers;
    const treadDepth = data.treadDepth;
    const treadFinish = data.treadFinish;
    const treadMaterial = data.treadMaterial;
    const treadNosing = data.treadNosing;
    const treadThickness = data.treadThickness;
    const treadWidthLower = data.treadWidthLower;
    const treadWidthUpper = data.treadWidthUpper;
    const tubeSize = data.tubeSize;
    const wallGapLower = data.wallGapLower;
    const wallGapUpper = data.wallGapUpper;
    const winderLower = data.winderLower;
    const handrailQty = data.handrailQty;
    const stringerLength = data.stringerLength;
    const bomString = data.bomString;
    const postString = data.postString;
    const notes = data.notes;
    const warnings = data.warnings;
    const sendIt = data.sendIt;
    const quantity = 1;
    let price = 0;

    let items: Array<FlightItem> = await parseFlightItems({
        bomString,
        postString,
        handrailQty,
        treadDepth,
        treadWidthLower,
        treadWidthUpper,
        treadThickness,
        treadMaterial,
        treadFinish,
        handrail,
        handrailHeight,
        handrailMaterial,
        handrailFinish,
        postInfill,
    });

    items.forEach((item) => {
        price += item.price * item.quantity;
    });

    const returnedNinetyFlight: ReturnedNinetyFlight = {
        name,
        generator,
        generatedStatus: GeneratedStatusEnum.Generated,
        uuid,
        orderNumber,
        bottomFinishedFloorThickness,
        ceilingGap,
        ceilingHeight,
        customerTreads,
        drywallLower,
        drywallUpper,
        enduranceTreads,
        fastenersFloor,
        fastenersHeader,
        fastenersWall,
        finishOption,
        formTreadRise,
        handrail,
        handrailFinish,
        handrailHeight,
        handrailMaterial,
        headerHeight,
        headerToWall,
        hockeyStickMounting,
        limitRun,
        matchTreads,
        meetCode,
        mountToSubfloor,
        postInfill,
        posts,
        revision,
        spaceConstraint,
        stairDirection,
        stringerMaterial,
        subfloorToSubfloor,
        taperedTreads,
        topFinishedFloorThickness,
        topNosingDepth,
        topTreadFlush,
        treadCovers,
        treadDepth,
        treadFinish,
        treadMaterial,
        treadNosing,
        treadThickness,
        treadWidthLower,
        treadWidthUpper,
        tubeSize,
        wallGapLower,
        wallGapUpper,
        winderLower,
        stringerLength,
        notes,
        warnings,
        sendIt,
        pdfUrl,
        quantity,
        price,
        items: items,
    };

    return returnedNinetyFlight;
}
