import React, { useContext, useEffect, useState } from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import useFetch from "use-http";
import { ENDPOINT } from "../../Global/Constants/environment";
import { materials } from "../../Global/Constants/Materials";
import { Item, ReturnedItem, OrderContext, GeneratorEnum } from "../../Context/OrderContext";
import FormTextField from "../../Components/FormComponents/FormTextField";
import FormCheckbox from "../../Components/FormComponents/FormCheckbox";
import FormSelect from "../../Components/FormComponents/FormSelect";
import { getFinishOptions } from "../TreadBuilder/TreadFormHelpers";
import { initialLandingValues } from "Global/Constants/itemDefaults";
import { LandingFormValues } from "Global/Constants/itemTypes";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "../../Components/Button";
import { runGenerator } from "Global/Helpers/RunGenerator";

export interface ReturnedLanding extends LandingFormValues, Item {
    finalWidth: number;
    finalDepth: number;
    finalThickness: number;
    boardFeet: number;
    chop: number;
    bunk: string;
    rip: number;
    plane: number;
    chopQty: number;
    price: number;
    quantity: number;
    bullnose: boolean;
}
const LandingFormSchema = yup.object().shape({
    landingType: yup.string(),
    nosingThickness: yup.number(),
    landingThickness: yup.number().when("landingType", {
        is: "Replacement Landing",
        then: yup.number().lessThan(yup.ref("nosingThickness"), "Must be less than Nosing Thickness"),
    }),
});

//Form Control Functions & Filters *********
function LandingForm(props: LandingFormProps) {
    const { state } = useContext(OrderContext);
    const { orderNumber } = state;
    const { loading, setLoading, connectedSocket } = props;
    const [initialValues] = React.useState(initialLandingValues());
    const methods = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(LandingFormSchema),
    });
    const { handleSubmit, control, watch, setValue, errors } = methods;
    const { post, response } = useFetch(ENDPOINT);

    const watchFields = watch([
        "landingType",
        "landingWidth",
        "landingDepth",
        "landingThickness",
        "returnRight",
        "returnLeft",
        "returnLengthRight",
        "returnLengthLeft",
        "nosingDepth",
        "nosingThickness",
        "roundOverLeft",
        "roundOverRight",
        "roundOverFront",
        "roundOverBack",
        "roundOver",
        "woodSpecies",
        "finishOption",
    ]);

    const landingTypeOptions = [
        { label: "Landing", value: "Landing" },
        { label: "Replacement Landing", value: "Replacement Landing" },
    ];

    const [roundOverOptions, setRoundoverOptions] = useState([
        { label: "Square Edges", value: 0 },
        { label: '1/8" Roundover', value: 0.125 },
        { label: '1/4" Roundover', value: 0.25 },
        { label: "Bullnose", value: 0.5 },
    ]);

    //Input field filters *****************

    //RoundOver Right & Left
    useEffect(() => {
        if (watchFields.returnLeft) {
            setValue("roundOverLeft", true);
        } else {
            setValue("returnLengthLeft", 0);
        }
        if (watchFields.returnRight) {
            setValue("roundOverRight", true);
        } else {
            setValue("returnLengthRight", 0);
        }
    }, [watchFields.returnLeft, watchFields.returnRight, setValue]);

    useEffect(() => {
        if (watchFields.landingType === "Landing") {
            console.log(watchFields.landingThickness);
            if (watchFields.landingThickness === 1) {
                setRoundoverOptions((prevOptions) => [
                    { label: "Square Edges", value: 0 },
                    { label: '1/8" Roundover', value: 0.125 },
                    { label: '1/4" Roundover', value: 0.25 },
                    { label: "Bullnose", value: 0.5 },
                ]);
            } else if (watchFields.landingThickness === 0.75) {
                setRoundoverOptions((prevOptions) => [
                    { label: "Square Edges", value: 0 },
                    { label: '1/8" Roundover', value: 0.125 },
                    { label: '1/4" Roundover', value: 0.25 },
                ]);
            } else {
                setRoundoverOptions((prevOptions) => [
                    { label: "Square Edges", value: 0 },
                    { label: '1/8" Roundover', value: 0.125 },
                    { label: '1/4" Roundover', value: 0.25 },
                ]);
            }
        }
    }, [watchFields.landingThickness, watchFields.landingType]);

    useEffect(() => {
        if (watchFields.landingType === "Replacement Landing") {
            if (watchFields.nosingThickness === 1) {
                setRoundoverOptions((prevOptions) => [
                    { label: "Square Edges", value: 0 },
                    { label: '1/8" Roundover', value: 0.125 },
                    { label: '1/4" Roundover', value: 0.25 },
                    { label: "Bullnose", value: 0.5 },
                ]);
            } else if (watchFields.nosingThickness === 0.75) {
                setRoundoverOptions((prevOptions) => [
                    { label: "Square Edges", value: 0 },
                    { label: '1/8" Roundover', value: 0.125 },
                    { label: '1/4" Roundover', value: 0.25 },
                ]);
            } else {
                setRoundoverOptions((prevOptions) => [
                    { label: "Square Edges", value: 0 },
                    { label: '1/8" Roundover', value: 0.125 },
                    { label: '1/4" Roundover', value: 0.25 },
                ]);
            }
        }
    }, [watchFields.landingType, watchFields.nosingThickness]);

    // Finish Options **********************************
    useEffect(() => {
        setValue("finishOption", "No Finish");
    }, [watchFields.woodSpecies, setValue]);

    // Form Submission Handler *************************
    const onSubmit: SubmitHandler<LandingFormValues> = async (values) => {
        if (
            values.landingType === "Replacement Landing" &&
            values.roundOver === 0.5 &&
            values.landingThickness === 0.75 &&
            values.nosingThickness === 1
        ) {
            window.alert("This cannot be done in the generator. Draw Manually");
            return;
        }
        setLoading(true);

        if (connectedSocket && orderNumber) {
            let data = {
                bullnose: values.roundOver === 0.5,
                ...initialValues,
                ...values,
                treadSurfaceTreatment: values.wireBrushed ? "Wirebrushed" : "",
            };

            if (state.uuid) {
                Object.assign(data, { uuid: state.uuid });
            }

            delete data.layout;
            if (
                values.landingType === "Replacement Landing" &&
                !(
                    values.roundOverFront === true ||
                    values.roundOverBack === true ||
                    values.roundOverLeft === true ||
                    values.roundOverRight === true ||
                    values.returnLeft === true ||
                    values.returnRight === true
                )
            ) {
                alert("Must select a nosing or return option");
                return;
            }

            await runGenerator(GeneratorEnum.Landing, connectedSocket.id, data, orderNumber);
        }
    };

    //Backroundover disabled
    useEffect(() => {
        if (watchFields.returnRight || watchFields.returnLeft) {
            setValue("roundOverBack", false);
        }
    }, [setValue, watchFields.returnLeft, watchFields.returnRight]);

    const expandIcon = <ExpandMoreIcon className="text-white" />;

    const [expanded, setExpanded] = React.useState<string | false>("panel1");

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    // DOM Rendering *************************
    return (
        <div id="form-wrapper" className="relative top-0 p-0 m-0 grid">
            <div className="w-full p-2 m-0 grid place-content-center">
                <Typography className="w-full text-primary" variant="h5">
                    Landing Generator
                </Typography>
            </div>

            <FormProvider {...methods}>
                <form name="generatorForm" onSubmit={handleSubmit(onSubmit)}>
                    <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
                        <AccordionSummary
                            className="accordion"
                            expandIcon={expandIcon}
                            aria-label="Expand"
                            aria-controls="landing-dim-content"
                            id="landing-dim-header"
                        >
                            <div className="flex w-full p-2 text-white uppercase bg-primary">
                                <span>Dimensions</span>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} className="p-2">
                                <Grid item xs={12}>
                                    <FormSelect
                                        name="landingType"
                                        label="Type"
                                        options={landingTypeOptions}
                                        control={control}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name="landingWidth"
                                        variant="outlined"
                                        label="Width (in.)"
                                        type="number"
                                        InputProps={{
                                            inputProps: {
                                                min: 30,
                                                max: 72,
                                                step: 0.0001,
                                            },
                                        }}
                                        helperText="Does not include return widths"
                                        tooltip="Min 30 -- Max 72"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name="landingDepth"
                                        variant="outlined"
                                        label="Depth (in.)"
                                        type="number"
                                        InputProps={{
                                            inputProps: {
                                                min: 18,
                                                max: 50,
                                                step: 0.0001,
                                            },
                                        }}
                                        helperText={
                                            watchFields.landingType === "Replacement Landing"
                                                ? `Body Depth: ${watchFields.landingDepth - watchFields.nosingDepth}
                                                `
                                                : ""
                                        }
                                        tooltip="Min 30 -- Max 50, If over 50, will need to be a split landing contact PM."
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name="landingThickness"
                                        variant="outlined"
                                        label="Thickness (in.)"
                                        type="number"
                                        InputProps={{
                                            inputProps:
                                                watchFields.landingType === "Landing"
                                                    ? { min: 0.75, max: 4, step: 0.25 }
                                                    : { min: 0.5, max: 1.75, step: 0.25 },
                                        }}
                                        error={!!errors["landingThickness"]}
                                        helperText={
                                            !!errors["landingThickness"] ? errors["landingThickness"].message : ""
                                        }
                                        tooltip={
                                            watchFields.landingType === "Landing"
                                                ? "Min 0.75 -- Max 4 -- Increments 0.25."
                                                : "Min 0.5 -- Max 1.75 -- Increments 0.25"
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            className="accordion"
                            expandIcon={expandIcon}
                            aria-controls="landing-ret-content"
                            id="landing-ret-header"
                        >
                            <Grid item xs={12}>
                                <div className="flex w-full p-2 text-white uppercase bg-primary">
                                    <span>Returns</span>
                                </div>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} className="p-2">
                                <Grid item xs={6}>
                                    <FormCheckbox label="Left" name="returnLeft" />
                                    {watchFields.returnLeft === true && (
                                        <>
                                            <FormTextField
                                                name="returnLengthLeft"
                                                variant="outlined"
                                                label="Length (in.)"
                                                type="number"
                                                InputProps={{
                                                    inputProps:
                                                        watchFields.landingThickness >= 2
                                                            ? { min: 0.25, max: 1.5, step: 0.0001 }
                                                            : { min: 0, max: 1.5, step: 0.0001 },
                                                }}
                                                tooltip={
                                                    watchFields.landingThickness >= 2
                                                        ? "Min 0.25 -- Max 1.5"
                                                        : "Min 0 -- Max 1.5"
                                                }
                                            />
                                        </>
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    <FormCheckbox label="Right" name="returnRight" />
                                    {watchFields.returnRight === true && (
                                        <>
                                            <FormTextField
                                                name="returnLengthRight"
                                                variant="outlined"
                                                label="Length (in.)"
                                                type="number"
                                                InputProps={{
                                                    inputProps:
                                                        watchFields.landingThickness >= 2
                                                            ? { min: 0.25, max: 1.5, step: 0.0001 }
                                                            : { min: 0, max: 1.5, step: 0.0001 },
                                                }}
                                                tooltip={
                                                    watchFields.landingThickness >= 2
                                                        ? "Min 0.25 -- Max 1.5"
                                                        : "Min 0 -- Max 1.5"
                                                }
                                            />
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            className="accordion"
                            expandIcon={expandIcon}
                            aria-controls="landing-nose-content"
                            id="landing-nose-header"
                        >
                            <Grid item xs={12}>
                                <div className="flex w-full p-2 text-white uppercase bg-primary">
                                    <span>Nosing</span>
                                </div>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} className="p-2">
                                {watchFields.landingType === "Replacement Landing" && (
                                    <>
                                        <Grid item xs={6}>
                                            <FormTextField
                                                variant="outlined"
                                                name="nosingDepth"
                                                label="Depth (in.)"
                                                type="number"
                                                InputProps={{
                                                    inputProps: { min: 1, max: 1.5, step: 0.25 },
                                                }}
                                                tooltip="Min 1 -- Max 1.5 -- Increments 0.25"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormTextField
                                                variant="outlined"
                                                name="nosingThickness"
                                                label="Thickness (in.)"
                                                type="number"
                                                InputProps={{
                                                    inputProps: { min: 0.75, max: 2.5, step: 0.25 },
                                                }}
                                                tooltip="Min 0.75 -- Max 2.5 -- Increments 0.25"
                                            />
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={6}>
                                    <FormCheckbox label="Left Roundover" name="roundOverLeft" />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormCheckbox label="Right Roundover" name="roundOverRight" />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormCheckbox label="Front Roundover" name="roundOverFront" />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormCheckbox
                                        label="Back Roundover"
                                        name="roundOverBack"
                                        disabled={watchFields.returnLeft || watchFields.returnRight}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSelect
                                        name="roundOver"
                                        label="Nosing Style"
                                        options={roundOverOptions}
                                        control={control}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            className="accordion"
                            expandIcon={expandIcon}
                            aria-controls="landing-mat-content"
                            id="landing-mat-header"
                        >
                            <Grid item xs={12}>
                                <div className="flex w-full p-2 text-white uppercase bg-primary">
                                    <span>Material</span>
                                </div>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} className="p-2">
                                <Grid item xs={6}>
                                    <FormSelect
                                        name="woodSpecies"
                                        label="Wood Type"
                                        options={materials.map((material) => ({
                                            label: material.name,
                                            value: material.name,
                                        }))}
                                        control={control}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormSelect
                                        name="finishOption"
                                        label="Finish"
                                        options={getFinishOptions(watchFields.woodSpecies)}
                                        control={control}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            className="accordion"
                            expandIcon={expandIcon}
                            aria-controls="landing-mat-content"
                            id="landing-mat-header"
                        >
                            <Grid item xs={12}>
                                <div className="flex w-full p-2 text-white uppercase bg-primary">
                                    <span>Features</span>
                                </div>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} className="p-2">
                                <Grid item xs={6}>
                                    <FormCheckbox label="Distressed" name="distressed" />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormCheckbox label="Hand Scraped" name="handScraped" />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormCheckbox label="Wirebrushed" name="wireBrushed" />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Grid item xs={12}>
                        <Grid item container className="relative p-2 place-content-end">
                            {loading && (
                                <Grid className="absolute grid place-items-end">
                                    <CircularProgress />
                                </Grid>
                            )}
                            {state.uuid && <Grid className="grid place-items-end"></Grid>}
                            <Grid className="container grid place-items-end">
                                <Button type="submit" tcolor="text-white">
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </FormProvider>
        </div>
    );
}

interface LandingFormProps {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    connectedSocket: SocketIOClient.Socket | undefined;
    returnedLanding: ReturnedItem | undefined;
    setReturnedLanding: React.Dispatch<React.SetStateAction<ReturnedItem | undefined>>;
}

export default LandingForm;
