import React, { useEffect } from "react";
import "./TestViewer.css";
import { ViewableBubble, initializeViewer } from "./ViewerHelper";

interface TestViewerProps {
    bubbles: Array<ViewableBubble>;
}

function TestViewerFunc(props: TestViewerProps) {
    const { bubbles } = props;

    useEffect(() => {
        initializeViewer(bubbles);
    }, [bubbles]);

    return <div className="Viewer" id="MyViewerDiv" />;
}

export default TestViewerFunc;
