import React, { useContext, useState, useEffect } from "react";
import { Item, ReturnedItem, OrderContext, GeneratorEnum } from "Context/OrderContext";
import { runGenerator } from "Global/Helpers/RunGenerator";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { materials } from "../../Global/Constants/Materials";
import { getFinishOptions } from "../TreadBuilder/TreadFormHelpers";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "../../Components/Button";
import { LandingTreadFormValues } from "Global/Constants/itemTypes";
import { initialLandingTreadValues } from "Global/Constants/itemDefaults";
import { Typography, Accordion, AccordionSummary, AccordionDetails, Grid, CircularProgress } from "@material-ui/core";
import FormSelect from "Components/FormComponents/FormSelect";
import FormCheckbox from "../../Components/FormComponents/FormCheckbox";
import FormTextField from "Components/FormComponents/FormTextField";

// Parameters for calculations or OfficeDot
export interface ReturnedLandingTread extends LandingTreadFormValues, Item {
    bullnose: boolean;
    finalWidth: number;
    finalDepth: number;
    finalThickness: number;
    boardFeet: number;
    chop: number;
    bunk: string;
    pdfUrl: string;
    rip: number;
    plane: number;
    chopQty: number;
    price: number;
}

function LandingTreadForm(props: LandingsTreadsFormProps) {
    const { state, dispatch } = useContext(OrderContext);
    const { orderNumber } = state;
    const { loading, setLoading, connectedSocket, setReturnedItem } = props;
    const [expanded, setExpanded] = React.useState<string | false>("panel1");
    const [initialValues] = useState(initialLandingTreadValues());

    const methods = useForm({
        defaultValues: initialValues,
    });

    const { handleSubmit, watch, control, setValue, reset } = methods;

    const expandIcon = <ExpandMoreIcon className="text-white" />;

    const onSubmit: SubmitHandler<LandingTreadFormValues> = async (values) => {
        setReturnedItem(undefined);
        setLoading(true);

        if (connectedSocket && orderNumber) {
            let data = {
                bullnose: values.roundOver === 0.5,
                ...initialValues,
                ...values,
            };
            delete data.layout;
            if (state.uuid) {
                Object.assign(data, { uuid: state.uuid });
            }
            await runGenerator(GeneratorEnum.LandingTread, connectedSocket.id, data, orderNumber);
        }
    };

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    function handleCancelEdit() {
        dispatch({ type: "CancelEditItem" });
        reset(initialValues);
    }

    const [roundOverOptions, setRoundoverOptions] = useState([
        { label: "Square Edges", value: 0 },
        { label: '1/8" Roundover', value: 0.125 },
        { label: '1/4" Roundover', value: 0.25 },
        { label: "Bullnose", value: 0.5 },
    ]);

    const watchFields = watch();

    // RoundOver Nosing Style
    useEffect(() => {
        if (watchFields.nosingThickness === 1) {
            setRoundoverOptions((prevOptions) => [
                { label: "Square Edges", value: 0 },
                { label: '1/4" Roundover', value: 0.25 },
                { label: '1/8" Roundover', value: 0.125 },
                { label: "Bullnose", value: 0.5 },
            ]);
        } else if (watchFields.nosingThickness === 0.75) {
            setRoundoverOptions((prevOptions) => [
                { label: "Square Edges", value: 0 },
                { label: '1/8" Roundover', value: 0.125 },
            ]);
        } else {
            setRoundoverOptions((prevOptions) => [
                { label: "Square Edges", value: 0 },
                { label: '1/4" Roundover', value: 0.25 },
                { label: '1/8" Roundover', value: 0.125 },
            ]);
        }
    }, [watchFields.nosingThickness]);

    // Roundover and return always work in pairs
    useEffect(() => {
        setValue("roundOverLeft", watchFields.returnLeft);
        setValue("roundOverRight", watchFields.returnRight);
    }, [watchFields.returnLeft, watchFields.returnRight, setValue]);

    useEffect(() => {
        setValue("returnLeft", watchFields.roundOverLeft);
        setValue("returnRight", watchFields.roundOverRight);
    }, [watchFields.roundOverLeft, watchFields.roundOverRight, setValue]);

    //Handle Material Changes
    useEffect(() => {
        setValue("finishOption", "No Finish");
    }, [watchFields.woodSpecies, setValue]);

    return (
        <div id="form-wrapper" className="relative top-0 p-0 m-0 grid">
            <div className="w-full p-2 m-0 grid place-content-center">
                <Typography className="w-full text-primary" variant="h5">
                    Landing Treads
                </Typography>
            </div>

            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
                        <AccordionSummary
                            className="accordion"
                            expandIcon={expandIcon}
                            aria-label="Expand"
                            aria-controls="tread-dim-content"
                            id="tread-dim-header"
                        >
                            <div className="flex w-full p-2 text-white uppercase bg-primary">
                                <span>Tread Dimensions</span>
                            </div>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Grid container spacing={2} className="p-2">
                                <Grid item xs={12}>
                                    <FormTextField
                                        variant="outlined"
                                        name="treadWidth"
                                        label="Tread Width"
                                        type="number"
                                        InputProps={{
                                            inputProps: { min: 6, max: 120, step: 0.0001 },
                                        }}
                                        tooltip="Min 6 -- Max 120"
                                        helperText="Does not include return widths"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <FormTextField
                                        variant="outlined"
                                        name="treadBodyDepth"
                                        label="Tread Body Depth"
                                        type="number"
                                        InputProps={{
                                            inputProps: { min: 2.75, max: 7, step: 0.0001 },
                                        }}
                                        tooltip="Min 2.75 -- Max 7"
                                        helperText={`Total Depth: ${
                                            watchFields.treadBodyDepth + watchFields.nosingDepth
                                        }`}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <FormTextField
                                        variant="outlined"
                                        name="treadThickness"
                                        label="Tread Thickness"
                                        type="number"
                                        InputProps={{
                                            inputProps: { min: 0.5, max: 1.0, step: 0.25 },
                                        }}
                                        tooltip="Min 0.5 -- Max 1.0 -- Increments 0.25"
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    {/* TODO: CHECK THE CRAP OUT OF THESE VALUES WITH KIMBERLY AND NATE*/}
                    <Accordion>
                        <AccordionSummary
                            className="accordion"
                            expandIcon={expandIcon}
                            aria-label="Expand"
                            aria-controls="tread-return-content"
                            id="tread-return-header"
                        >
                            <div className="flex w-full p-2 text-white uppercase bg-primary">
                                <span>Returns</span>
                            </div>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Grid container spacing={1} className="p-2">
                                <Grid item xs={12}>
                                    <FormCheckbox label="Left" name="returnLeft" />

                                    {watchFields.returnLeft === true && (
                                        <>
                                            <FormTextField
                                                variant="outlined"
                                                name="returnLengthLeft"
                                                label="Length"
                                                type="number"
                                                InputProps={{
                                                    inputProps: { min: 0, max: 1.5, step: 0.0001 },
                                                }}
                                                tooltip={"Min 0 -- Max 1.5"}
                                            />
                                        </>
                                    )}
                                </Grid>

                                <Grid item xs={12}>
                                    <FormCheckbox label="Right" name="returnRight" />

                                    {watchFields.returnRight === true && (
                                        <>
                                            <FormTextField
                                                variant="outlined"
                                                name="returnLengthRight"
                                                label="Length"
                                                type="number"
                                                InputProps={{
                                                    inputProps: { min: 0, max: 1.5, step: 0.0001 },
                                                }}
                                                tooltip={"Min 0 -- Max 1.5"}
                                            />
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            className="accordion"
                            expandIcon={expandIcon}
                            aria-label="Expand"
                            aria-controls="nosing-content"
                            id="nosing-header"
                        >
                            <div className="flex w-full p-2 text-white uppercase bg-primary">
                                <span>Nosing</span>
                            </div>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Grid container spacing={2} className="p-2">
                                <Grid item xs={12}>
                                    <FormTextField
                                        variant="outlined"
                                        name="nosingDepth"
                                        label="Nosing Depth"
                                        type="number"
                                        InputProps={{
                                            inputProps: { min: 1, max: 1.5, step: 0.25 },
                                        }}
                                        tooltip={"Min 1 -- Max 1.5 -- Increments 0.25"}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <FormTextField
                                        variant="outlined"
                                        name="nosingThickness"
                                        label="Nosing Thickness"
                                        type="number"
                                        InputProps={{
                                            inputProps: { min: 0.75, max: 2.5, step: 0.25 },
                                        }}
                                        tooltip={"Min 0.75 -- Max 2.5 -- Increments 0.25"}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormCheckbox label="Left Roundover" name="roundOverLeft" />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormCheckbox label="Right Roundover" name="roundOverRight" />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormCheckbox label="Front Roundover" name="roundOverFront" />
                                </Grid>

                                <Grid item xs={12}>
                                    <FormSelect
                                        name="roundOver"
                                        label="Nosing Style"
                                        options={roundOverOptions}
                                        control={control}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            className="accordion"
                            expandIcon={expandIcon}
                            aria-label="Expand"
                            aria-controls="material-content"
                            id="material-header"
                        >
                            <div className="flex w-full p-2 text-white uppercase bg-primary">
                                <span>Material</span>
                            </div>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Grid container spacing={2} className="p-2">
                                <Grid item xs={6}>
                                    <FormSelect
                                        name="woodSpecies"
                                        label="Wood Type"
                                        options={materials.map((material) => ({
                                            label: material.name,
                                            value: material.name,
                                        }))}
                                        control={control}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <FormSelect
                                        name="finishOption"
                                        label="Finish"
                                        options={getFinishOptions(watchFields.woodSpecies)}
                                        control={control}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            className="accordion"
                            expandIcon={expandIcon}
                            aria-controls="landing-mat-content"
                            id="landing-mat-header"
                        >
                            <Grid item xs={12}>
                                <div className="flex w-full p-2 text-white uppercase bg-primary">
                                    <span>Features</span>
                                </div>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} className="p-2">
                                <Grid item xs={6}>
                                    <FormCheckbox label="Distressed" name="distressed" />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormCheckbox label="Hand Scraped" name="handScraped" />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormCheckbox label="Wirebrushed" name="wireBrushed" />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <Grid item xs={12}>
                        <Grid item container className="relative p-2 place-content-end">
                            {loading && (
                                <Grid className="absolute grid place-items-end">
                                    <CircularProgress />
                                </Grid>
                            )}
                            {state.uuid && (
                                <Grid className="grid place-items-end">
                                    <Button onClick={() => handleCancelEdit()}>Cancel Edit</Button>
                                </Grid>
                            )}
                            <Grid className="container grid place-items-end">
                                <Button type="submit" tcolor="text-white">
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </FormProvider>
        </div>
    );
}

interface LandingsTreadsFormProps {
    values?: any;
    onChange?: any;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    connectedSocket: SocketIOClient.Socket | undefined;
    returnedItem: ReturnedItem | undefined;
    setReturnedItem: React.Dispatch<React.SetStateAction<ReturnedItem | undefined>>;
}

export default LandingTreadForm;
