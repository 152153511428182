import React, { useState, useEffect, useContext } from "react";
import {
    TextField,
    Button,
    Paper,
    Box,
    Grid,
    Container,
    ListItem,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Icon,
    Link,
} from "@material-ui/core";
import CustomerCard, { Customer } from "./Customer";
import { useQuery } from "react-query";
import { getOrderSummary } from "../Pages/TreadBuilder/TreadFetches";
import { useHistory } from "react-router-dom";
import { OrderContext } from "../Context/OrderContext";

function OrderNumberConfirm() {
    const { state, dispatch } = useContext(OrderContext);
    const [orderNumber, setOrderNumber] = useState("");
    const history = useHistory();
    const initialCustomer: Customer = {
        name: "",
        email: "",
        address1: "",
        city: "",
        state: "",
        postalCode: "",
    };
    const [customer, setCustomer] = useState<Customer>(initialCustomer);
    const [customerLoaded, setCustomerLoaded] = useState(false);
    const { isLoading, refetch, data } = useQuery(orderNumber, getOrderSummary, {
        enabled: false,
    });

    useEffect(() => {
        if (!isLoading && data) {
            setCustomer({
                name: `${data.BillContactFirstName} ${data.BillContactLastName}`,
                email: data.BillEmails,
                address1: data.BillAddress1,
                city: data.BillCity,
                state: data.BillState,
                postalCode: data.BillPostalCode,
            });
            setCustomerLoaded(true);
        }
    }, [data, isLoading]);

    function onConfirm() {
        dispatch({ type: "ResetState" });
        dispatch({ type: "UpdateOrderDetails", orderNumber: orderNumber, customerName: customer.name });
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setOrderNumber(event.target.value);
    }

    const generatorList = [
        // {
        //     name: "Treads",
        //     route: "/generators/multiTreads",
        // },
        // {
        //     name: "Landings",
        //     route: "/generators/landing",
        // },
        // {
        //     name: "Landing Treads",
        //     route: "/generators/landingTreads",
        // },
        // {
        //     name: "Risers",
        //     route: "/generators/riser",
        // },
        // {
        //     name: "S4S",
        //     route: "/generators/s4s",
        // },
        {
            name: "Gates",
            route: "/generators/gates",
        },
    ];

    return (
        <Container maxWidth="md">
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={2}>
                                    <Grid container spacing={2} justify="space-between">
                                        <Grid item xs={9}>
                                            <TextField
                                                name="orderNumber"
                                                label="Order Number"
                                                variant="outlined"
                                                size="small"
                                                onChange={handleChange}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" onClick={() => refetch()}>
                                                Load
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <CustomerCard customer={customer} onConfirm={onConfirm} customerLoaded={customerLoaded} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper>
                        <Table size="medium">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Generators</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {state.orderNumber ? (
                                    <>
                                        <TableRow>
                                            <TableCell padding="none">
                                                <ListItem
                                                    button
                                                    onClick={() =>
                                                        (window.location.href =
                                                            "https://orders.viewrail.com/admin/treadbuilder")
                                                    }
                                                >
                                                    <Grid container alignItems="center" justify="space-between">
                                                        <Grid item>
                                                            Wood Generators can be found at{" "}
                                                            <Link href="https://orders.viewrail.com/admin/treadbuilder">
                                                                New Treadbuilder
                                                            </Link>
                                                        </Grid>
                                                        <Grid item>
                                                            <Icon>arrow_forward</Icon>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            </TableCell>
                                        </TableRow>
                                        {generatorList.map((generator, index) => (
                                            <TableRow key={index}>
                                                <TableCell padding="none">
                                                    <ListItem button onClick={() => history.push(`${generator.route}`)}>
                                                        <Grid container alignItems="center" justify="space-between">
                                                            <Grid item>{generator.name}</Grid>
                                                            <Grid item>
                                                                <Icon>arrow_forward</Icon>
                                                            </Grid>
                                                        </Grid>
                                                    </ListItem>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </>
                                ) : (
                                    <TableRow>
                                        <TableCell align="center">Confirm an order number</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

export default OrderNumberConfirm;
