import { ReturnedLanding } from "./LandingForm";
import { materials, finishOptions, FinishOperatorEnum, materialSizes } from "../../Global/Constants/Materials";
import { landingAddon, landingThickAddon, landingReplacementAddon } from "../../Global/Constants/Pricing";
import { ENDPOINT } from "../../Global/Constants/environment";
import axios from "axios";
import { OD1_Item } from "Global/Constants/OD1_Types";
import { GeneratedStatusEnum } from "Global/Constants/itemTypes";

export function processReturnedLanding(data: any): ReturnedLanding {
    const orderNumberParam = data.orderNumber;
    const generatorParam = data.generator;
    const uuidParam = data.uuid;
    const landingType = data.landingType;
    const landingWidth = data.landingWidth;
    const landingDepth = data.landingDepth;
    const landingThickness = data.landingThickness;
    const returnLeft = data.returnLeft;
    const returnRight = data.returnRight;
    const returnLengthLeft = data.returnLengthLeft;
    const returnLengthRight = data.returnLengthRight;
    const nosingDepth = data.nosingDepth;
    const nosingThickness = data.nosingThickness;
    const roundOverLeft = data.roundOverLeft;
    const roundOverRight = data.roundOverRight;
    const roundOverFront = data.roundOverFront;
    const roundOverBack = data.roundOverBack;
    const roundOver = data.roundOver;
    const woodSpecies = data.woodSpecies;
    const finishOption = data.finishOption;
    const distressed = data.distressed;
    const handScraped = data.handScraped;
    const wireBrushed = data.wireBrushed;
    const boardFeet = data.boardFeet;
    const rip = data.rip;
    const chop = data.chop;
    const plane = data.plane;
    const chopQty = data.chopQty;
    const bunk = data.bunk;
    const bullnose = data.bullnose;
    const finalWidth = data.finalWidth;
    const finalDepth = data.finalDepth;
    const finalThickness = data.finalThickness;
    const pdfUrl = data.pdfUrl;

    let landing: ReturnedLanding = {
        generatedStatus: GeneratedStatusEnum.Generated,
        name: `Custom Landing (${landingWidth} x ${landingDepth} x ${landingThickness})`,
        orderNumber: orderNumberParam,
        generator: generatorParam,
        uuid: uuidParam,
        landingType: landingType,
        landingWidth: landingWidth,
        landingDepth: landingDepth,
        landingThickness: landingThickness,
        returnLeft: returnLeft,
        returnRight: returnRight,
        returnLengthLeft: returnLengthLeft,
        returnLengthRight: returnLengthRight,
        nosingDepth: nosingDepth,
        nosingThickness: nosingThickness,
        roundOverLeft: roundOverLeft,
        roundOverRight: roundOverRight,
        roundOverFront: roundOverFront,
        roundOverBack: roundOverBack,
        roundOver: roundOver,
        woodSpecies: woodSpecies,
        finishOption: finishOption,
        distressed: distressed,
        handScraped: handScraped,
        wireBrushed: wireBrushed,
        boardFeet: boardFeet,
        rip: rip,
        chop: chop,
        plane: plane,
        chopQty: chopQty,
        bunk: bunk,
        finalWidth: finalWidth,
        finalDepth: finalDepth,
        finalThickness: finalThickness,
        price: 0,
        quantity: 1,
        bullnose: bullnose,
        pdfUrl,
    };
    const priceParam = calculateLandingPrice(landing);
    return Object.assign(landing, { price: priceParam });
}

export function calculateLandingPrice(landing: Omit<ReturnedLanding, "price">) {
    let material = materials.find((material) => material.name === landing.woodSpecies);
    let finishOption = finishOptions.find((option) => option.name === landing.finishOption);

    let depth = landing.landingDepth;
    let width = landing.landingWidth;
    let thickness = landing.landingThickness;
    let board_feet = 0;
    let labor_total = 0;
    let finish_price = 0;
    let wood_total = 0;
    let returns_total = 0;
    let returnsCount = [landing.returnLeft, landing.returnRight].filter((item) => item === true).length;

    let addon;
    let materialAddon;
    if (landing.landingThickness > 1) {
        addon = landingThickAddon;
        materialAddon = material ? material.pricing.ThickLanding : 0;
    } else {
        addon = landingAddon;
        materialAddon = material ? material.pricing.Landing : 0;
    }
    switch (landing.landingType) {
        case "Landing":
            depth += addon.depth_addon;
            width += addon.width_addon;
            thickness += addon.thickness_addon;

            board_feet = (depth * width * thickness) / 144;

            if (board_feet < addon.board_foot_limit) {
                labor_total = addon.low_labor;
                finish_price = addon.low_finish;
            } else {
                labor_total = addon.high_labor;
                finish_price = addon.high_finish;
            }

            returns_total = returnsCount * addon.each_return;
            wood_total = materialAddon * board_feet;
            break;

        case "Replacement Landing":
            depth += landingReplacementAddon.depth_addon;
            width += landingReplacementAddon.width_addon;
            thickness += landingReplacementAddon.thickness_addon;
            if (thickness < 1) {
                thickness = 1;
            }

            let bullnose_bf = 0;
            let main_bf = 0;
            let original_thickness = landing.landingThickness;
            let bn_depth = landing.nosingDepth + landingReplacementAddon.bullnose_depth_addon;
            let bn_thickness =
                landing.nosingThickness + landingReplacementAddon.bullnose_thickness_addon - original_thickness;

            main_bf = depth * width * thickness;
            bullnose_bf = bn_depth * bn_thickness * width;
            board_feet = (main_bf + bullnose_bf) / 144;

            if (board_feet < landingReplacementAddon.board_foot_limit) {
                labor_total = landingReplacementAddon.low_labor;
                finish_price = landingReplacementAddon.low_finish;
            } else {
                labor_total = landingReplacementAddon.high_labor;
                finish_price = landingReplacementAddon.high_finish;
            }

            returns_total = returnsCount * landingReplacementAddon.each_return;
            wood_total = material ? material.pricing.ReplacementLanding * board_feet : 0;

            break;
    }

    if (!material || !finishOption) return 0.0;

    let finish_total = 0;

    if (finishOption.finish_operator === FinishOperatorEnum.ADD) {
        finish_total = finish_price + finish_price * finishOption.finish_multiplier;
    } else {
        finish_total = finish_price - finish_price * finishOption.finish_multiplier;
    }

    //no custom features pricing yet
    let custom_features_price = 0;
    if (landing.distressed) {
        custom_features_price += addon.distressed;
    }
    if (landing.handScraped) {
        custom_features_price += addon.hand_scraped;
    }

    if (landing.landingType === "Replacement") {
        if (landing.landingWidth >= 52 || landing.landingDepth >= 52) {
            custom_features_price += 200;
        }
    }
    if (landing.landingType === "Landing" && landing.landingThickness > 1) {
        if (landing.landingWidth >= 48 || landing.landingDepth >= 48) {
            custom_features_price += 200;
        }
    }

    let total_cost = wood_total + labor_total + finish_total + returns_total + custom_features_price;
    let materialIncrease = 1.1;
    if (material.id === 2) materialIncrease = 1.3;
    const nonWirebrushModifier = landing.wireBrushed ? 1.0 : 1.0;
    const inflationIncrease = 1.03;
    return total_cost * 1.15 * materialIncrease * nonWirebrushModifier * inflationIncrease;
}

const updateUrl = async (orderNumber: string, uuid: string) => {
    const data = {
        orderNumber: orderNumber,
        fileName: `${uuid}.pdf`,
        generator: "landingGenerator",
    };
    //get url
    let response = await axios.post(ENDPOINT + "/forge/s3Url", data);
    return response.data.url;
};

export async function formatLandingForOD1(returnedLanding: ReturnedLanding): Promise<OD1_Item> {
    let productID: number;
    if (returnedLanding.landingType === "Landing") {
        if (returnedLanding.landingThickness > 1) {
            productID = 403752;
        } else {
            productID = 438989;
        }
    } else {
        productID = 438990;
    }
    let material = materials.find((material) => material.name === returnedLanding.woodSpecies);

    let finishOption = finishOptions
        .filter((option) => option.material_id === material?.id)
        .find((option) => option.name === returnedLanding.finishOption);

    let materialSize = materialSizes.find((size) => size.name === returnedLanding.bunk);

    let pdfUrl = await updateUrl(returnedLanding.orderNumber, returnedLanding.uuid);

    return {
        ProductID: productID,
        Quantity: returnedLanding.quantity,
        MaterialID: material?.id,
        MaterialSizeID: materialSize?.id,
        FinishOptionID: finishOption?.id === 0 ? "" : finishOption?.id,
        filename: "Landing Print.pdf",
        fileURL: pdfUrl,
        Price: returnedLanding.price.toFixed(2),
        Description: "",
        InHouseNotes: returnedLanding.name,
        BoardFootage: returnedLanding.boardFeet,
        WoodDimRipWidth1: returnedLanding.rip,
        WoodDimPlaneThickness1: returnedLanding.plane,
        WoodDimPRCChop1: returnedLanding.chop,
        WoodDimChopQuantity1: returnedLanding.chopQty,
        WoodDimFinalLength1: returnedLanding.finalDepth,
        WoodDimFinalWidth1: returnedLanding.finalWidth,
        WoodDimFinalHeight1: returnedLanding.finalThickness,
        WoodDimGlueType1: "",
    };
}
export function getFinishOptions(material_name: string) {
    if (!material_name) {
        return [];
    }
    let material_id = materials.find((material) => material.name === material_name)?.id;

    if (!material_id) {
        return [];
    }

    let options = finishOptions.filter((option) => option.material_id === material_id);

    return options.map((option) => {
        return { label: option.name, value: option.name };
    });
}
