import {
    GeneratedStatusEnum,
    FlightFormValues,
    StraightFlightFormValues,
    LandingFormValues,
    NinetyFlightFormValues,
    SwitchbackFlightFormValues,
    MultiTreadFormValues,
    LevelRunValues,
    RiserFormValues,
    S4SFormValues,
    UFlightFormValues,
    LandingTreadFormValues,
    GateBuilderFormValues,
    // GateBuilderFormValues,
} from "Global/Constants/itemTypes";
import { GeneratorEnum } from "Context/OrderContext";
import { v4 } from "uuid";

const defaultLayout = () => ({
    position: { x: 0, y: 0, z: 0 },
    direction: 0,
});

const defaultRunLayout = () => ({
    labelIDs: [],
    postIDs: [],
    startPostIDs: [false as false, false as false], // because typescript -_-
    endPostIDs: [false as false, false as false], // because typescript -_-
    position: { x: 0, y: 0, z: 0 },
    direction: 0,
});

export const initialLevelRunValues: () => LevelRunValues = () => ({
    uuid: v4(),
    generator: GeneratorEnum.LevelRun as GeneratorEnum,
    generatedStatus: GeneratedStatusEnum.Stale,
    orderNumber: "",
    system: "postNoGlass",
    systemHeight: 39,
    infill: "Cable",
    handrailInfo: "",
    handrail: "6000",
    handrailMaterial: "Wood",
    handrailMaterialPass: "Red Oak",
    handrailFinish: "",
    postTop: "Universal Top",
    postFoot: "Standard Foot",
    postMaterial: "Aluminum",
    postFinish: "PC: Black",
    postInfo: "",
    runLength: 144,
    cornerOption: "Inside",
    footCoverOption: true,
    mountingSurface: "extSurfaceMountPostIntoWoodLT3",
    handrailScrewOption: "Black",
    corners: "None",
    glassGap: "1",
    handrailType: "None",
    systemType: "Post",
    panelThickness: "1/2",
    isInterior: false,
    material: "Aluminum",
    glassPanels: "",
    structureInfo: "",
    layout: defaultRunLayout(),
});

export const initialMultiTreadValues: () => MultiTreadFormValues = () => ({
    generator: GeneratorEnum.MultiTreads as GeneratorEnum,
    generatedStatus: GeneratedStatusEnum.Stale,
    orderNumber: "",
    uuid: v4(),
    treadType: "Tread",
    treadWidth: 36,
    treadDepth: 10,
    treadThickness: 1,
    returnLeft: false,
    returnRight: false,
    returnLengthRight: 1,
    returnLengthLeft: 1,
    nosingDepth: 1.25,
    nosingThickness: 1,
    roundOverRight: true,
    roundOverLeft: true,
    roundOverFront: true,
    roundOverBack: false,
    roundOver: 0.25,
    woodSpecies: "Red Oak",
    finishOption: "",
    handScraped: false,
    distressed: false,
    wireBrushed: false,
    layout: defaultLayout(),
});

export const initialLandingValues: () => LandingFormValues = () => ({
    generator: GeneratorEnum.Landing as GeneratorEnum,
    generatedStatus: GeneratedStatusEnum.Stale,
    orderNumber: "",
    uuid: v4(),
    landingType: "Landing",
    landingWidth: 45,
    landingDepth: 20,
    landingThickness: 0.75,
    returnLeft: false,
    returnRight: false,
    returnLengthLeft: 0,
    returnLengthRight: 0,
    nosingDepth: 1.25,
    nosingThickness: 1,
    roundOverLeft: false,
    roundOverRight: false,
    roundOverFront: false,
    roundOverBack: false,
    roundOver: 0,
    woodSpecies: "Red Oak",
    finishOption: "No Finish",
    handScraped: false,
    distressed: false,
    wireBrushed: false,
    layout: defaultLayout(),
});

export const initialLandingTreadValues: () => LandingTreadFormValues = () => ({
    generator: GeneratorEnum.LandingTread as GeneratorEnum,
    generatedStatus: GeneratedStatusEnum.Stale,
    orderNumber: "",
    uuid: v4(),
    treadWidth: 36,
    treadBodyDepth: 4,
    treadThickness: 0.75,
    returnLeft: false,
    returnRight: false,
    returnLengthLeft: 0,
    returnLengthRight: 0,
    nosingDepth: 1.25,
    nosingThickness: 1.0,
    roundOverLeft: false,
    roundOverRight: false,
    roundOverFront: true,
    roundOver: 0.25,
    woodSpecies: "Red Oak",
    finishOption: "No Finish",
    handScraped: false,
    distressed: false,
    wireBrushed: false,
    layout: defaultLayout(),
});

export const initialRiserValues: () => RiserFormValues = () => ({
    generator: GeneratorEnum.Landing as GeneratorEnum,
    generatedStatus: GeneratedStatusEnum.Stale,
    orderNumber: "",
    uuid: v4(),
    riserWidth: 36,
    riserThickness: 0.5,
    riserDepth: 6,
    woodSpecies: "Red Oak",
    finishOption: "No Finish",
    handScraped: false,
    distressed: false,
    wireBrushed: false,
    layout: defaultLayout(),
});

export const initialS4SValues: () => S4SFormValues = () => ({
    generator: GeneratorEnum.Landing as GeneratorEnum,
    generatedStatus: GeneratedStatusEnum.Stale,
    orderNumber: "",
    uuid: v4(),
    width: 36,
    thickness: 1,
    depth: 4,
    woodSpecies: "Red Oak",
    finishOption: "No Finish",
    handScraped: false,
    distressed: false,
    wireBrushed: false,
    layout: defaultLayout(),
});

export const initialGateValues: () => GateBuilderFormValues = () => ({
    generator: GeneratorEnum.Gate as GeneratorEnum,
    generatedStatus: GeneratedStatusEnum.Stale,
    orderNumber: "",
    uuid: v4(),
    systemHeight: 36,
    openingWidth: 36,
    doubleGate: false,
    material: "Aluminum",
    systemType: "Universal Top",
    infill: "Stainless",
    latchSide: "Left",
    finish: "Raw Aluminum - No Finish",
    revision: 0,
    engineer: "VOE",
    layout: defaultLayout(),
});

export const initialFlightValues: () => FlightFormValues = () => ({
    generator: GeneratorEnum.Flight as GeneratorEnum,
    generatedStatus: GeneratedStatusEnum.Stale,
    orderNumber: "",
    uuid: v4(),
    bottomFinishedFloorThickness: 0.5,
    ceilingGap: 250,
    ceilingHeight: 200,
    customerTreads: false,
    enduranceTreads: false,
    fastenersFloor: "Wood Lags",
    fastenersWall: "Thru Bolts",
    finishOption: "PC: Black",
    formTreadRise: 7.5,
    handrail: "Aluminum",
    handrailHeight: 36,
    handrailMaterial: "Aluminum",
    handrailFinish: "",
    headerHeightLower: 12,
    headerHeightUpper: 12,
    meetCode: true,
    postInfill: "Cable",
    posts: "None",
    revision: 0,
    stringerMaterial: "Mild",
    subfloorToSubfloor: 120,
    taperedTreads: false,
    topFinishedFloorThickness: 0.5,
    topNosingDepth: 2,
    treadFinish: "No Finish",
    treadMaterial: "Red Oak",
    treadThickness: 4,
    treadWidth: 36,
    wallToWall: 160,
    tubeSize: "6X8",
    layout: defaultRunLayout(),
});

export const initialStraightFlightValues: () => StraightFlightFormValues = () => ({
    generator: GeneratorEnum.StraightFlight as GeneratorEnum,
    generatedStatus: GeneratedStatusEnum.Stale,
    orderNumber: "",
    uuid: v4(),
    bottomFinishedFloorThickness: 0.5,
    ceilingGap: 200,
    ceilingHeight: 110,
    customerTreads: false,
    enduranceTreads: false,
    fastenersHeader: "Thru Bolts",
    fastenersFloor: "Wood Lags",
    finishOption: "PC: Black",
    formTreadRise: 7.5,
    handrail: "Aluminum",
    handrailFinish: "",
    handrailHeight: 36,
    handrailMaterial: "Aluminum",
    headerHeight: 12,
    limitRun: false,
    meetCode: true,
    mountToSubfloor: false,
    posts: "None",
    postInfill: "Cable",
    revision: 0,
    spaceConstraint: 200,
    stringerMaterial: "Mild",
    subfloorToSubfloor: 120,
    taperedTreads: false,
    topFinishedFloorThickness: 0.5,
    topTreadFlush: false,
    topNosingDepth: 2,
    treadCovers: true,
    treadDepth: 11.25,
    treadFinish: "No Finish",
    treadMaterial: "Red Oak",
    treadNosing: 1.25,
    treadThickness: 4,
    treadWidth: 40,
    tubeSize: "6X8",
    layout: defaultRunLayout(),
});

export const initialNinetyFlightValues: () => NinetyFlightFormValues = () => ({
    generator: GeneratorEnum.NinetyFlight as GeneratorEnum,
    generatedStatus: GeneratedStatusEnum.Stale,
    orderNumber: "",
    uuid: v4(),
    bottomFinishedFloorThickness: 0.5,
    ceilingGap: 200,
    ceilingHeight: 120,
    customerTreads: false,
    drywallLower: 0.5,
    drywallUpper: 0.5,
    enduranceTreads: false,
    fastenersFloor: "Wood Lags",
    fastenersHeader: "Thru Bolts",
    fastenersWall: "Wood Lags",
    finishOption: "PC: Black",
    formTreadRise: 7.5,
    handrail: "Aluminum",
    handrailHeight: 36,
    handrailMaterial: "Aluminum",
    handrailFinish: "",
    headerHeight: 12,
    headerToWall: 120,
    hockeyStickMounting: "Upper Mount to Wall",
    meetCode: true,
    mountToSubfloor: false,
    limitRun: false,
    matchTreads: true,
    postInfill: "Cable",
    posts: "None",
    revision: 0,
    spaceConstraint: 200,
    stairDirection: "Right",
    stringerMaterial: "Mild",
    subfloorToSubfloor: 120,
    taperedTreads: false,
    topFinishedFloorThickness: 0.5,
    topNosingDepth: 2,
    topTreadFlush: false,
    treadCovers: true,
    treadDepth: 11.25,
    treadFinish: "No Finish",
    treadMaterial: "Red Oak",
    treadNosing: 1.25,
    treadThickness: 3.5,
    treadWidthLower: 36,
    treadWidthUpper: 38,
    tubeSize: "6X8",
    wallGapLower: 0.5,
    wallGapUpper: 0.5,
    winderLower: false,
    layout: defaultRunLayout(),
});
export const initialSwitchbackFlightValues: () => SwitchbackFlightFormValues = () => ({
    generator: GeneratorEnum.SwitchbackFlight as GeneratorEnum,
    generatedStatus: GeneratedStatusEnum.Stale,
    orderNumber: "",
    uuid: v4(),
    bottomFinishedFloorThickness: 0.5,
    customerTreads: false,
    drywallLower: 0.5,
    drywallUpper: 0.5,
    drywallLanding: 0.5,
    enduranceTreads: false,
    fastenersFloor: "Wood Lags",
    fastenersHeader: "Thru Bolts",
    fastenersWall: "Wood Lags",
    finishOption: "PC: Black",
    formTreadRise: 7.5,
    handrail: "Aluminum",
    handrailMount: "Surface",
    handrailHeight: 36,
    handrailMaterial: "Aluminum",
    handrailFinish: "",
    headerHeight: 12,
    headerToWall: 120,
    hockeyStickMounting: "Stringers to Wall",
    maximizeTreadWidth: true,
    meetCode: true,
    mountToSubfloor: false,
    limitRun: false,
    landingDepth: 36,
    postInfill: "Cable",
    posts: "None",
    revision: 0,
    spaceConstraint: 200,
    splitLanding: false,
    stairDirection: "Right",
    stringerMaterial: "Mild",
    subfloorToSubfloor: 120,
    taperedTreads: false,
    topFinishedFloorThickness: 0.5,
    topNosingDepth: 2,
    topTreadFlush: false,
    treadCovers: true,
    treadDepth: 11.25,
    treadFinish: "No Finish",
    treadMaterial: "Red Oak",
    treadNosing: 1.25,
    treadThickness: 4,
    treadWidthLower: 36,
    treadWidthUpper: 36,
    tubeSize: "6X8",
    wallGapLanding: 0.5,
    wallGapLower: 0.5,
    wallGapUpper: 0.5,
    wallToWall: 95,
    winderLower: false,
    winderUpper: false,
    layout: defaultRunLayout(),
});

export const initialUFlightValues: () => UFlightFormValues = () => ({
    generator: GeneratorEnum.SwitchbackFlight as GeneratorEnum,
    generatedStatus: GeneratedStatusEnum.Stale,
    orderNumber: "",
    uuid: v4(),
    bottomFinishedFloorThickness: 0.5,
    customerTreads: false,
    drywallLower: 0.5,
    drywallMiddle: 0.5,
    drywallUpper: 0.5,
    enduranceTreads: false,
    fastenersFloor: "Wood Lags",
    fastenersHeader: "Thru Bolts",
    fastenersWall: "Wood Lags",
    finishOption: "PC: Black",
    formTreadRise: 7.5,
    handrail: "Aluminum",
    handrailHeight: 36,
    handrailMaterial: "Aluminum",
    handrailMount: "Surface",
    handrailFinish: "",
    headerHeight: 12,
    headerToWall: 100,
    hockeyStickMounting: "Stringers To Wall",
    matchTreads: true,
    matchLandingDepth: true,
    meetCode: true,
    mountToSubfloor: false,
    limitRun: false,
    postInfill: "Cable",
    posts: "None",
    revision: 0,
    spaceConstraint: 200,
    stairDirection: "Right",
    stringerMaterial: "Mild",
    subfloorToSubfloor: 120,
    taperedTreads: false,
    topFinishedFloorThickness: 0.5,
    topNosingDepth: 2,
    topTreadFlush: false,
    treadCovers: true,
    treadDepth: 11.25,
    treadFinish: "No Finish",
    treadMaterial: "Red Oak",
    treadNosing: 1.25,
    treadThickness: 4,
    treadWidthLower: 36,
    treadWidthMiddle: 36,
    treadWidthUpper: 36,
    tubeSize: "6X8",
    wallGapLower: 0.5,
    wallGapMiddle: 0.5,
    wallGapUpper: 0.5,
    wallToWall: 110,
    winderLower: false,
    winderUpper: false,
    layout: defaultRunLayout(),
});

export const initialThickTreadValues = {
    generator: GeneratorEnum.MultiTreads as GeneratorEnum,
    generatedStatus: GeneratedStatusEnum.Stale,
    orderNumber: "",
    width: 36,
    depth: 10,
    thickness: 2,
    returnRight: false,
    returnLeft: false,
    returnLength: 0,
    returnWidth: 1.25,
    nosingRight: true,
    nosingLeft: true,
    nosingFront: true,
    nosingBack: true,
    treadNosing: 0,
    nosingThickness: 0,
    roundOver: 0.25,
    material: "Red Oak",
    finishOption: "",
    bullnose: false,
    finalWidth: 0,
    finalDepth: 0,
    finalThickness: 0,
    layout: defaultLayout(),
};

export const initialTreadValues = {
    generator: GeneratorEnum.MultiTreads as GeneratorEnum,
    generatedStatus: GeneratedStatusEnum.Stale,
    orderNumber: "",
    width: 36,
    depth: 10,
    thickness: 2,
    returnRight: false,
    returnLeft: false,
    returnLength: 0,
    returnWidth: 1.25,
    nosingRight: true,
    nosingLeft: true,
    nosingFront: true,
    nosingBack: true,
    treadNosing: 0,
    nosingThickness: 0,
    roundOver: 0.25,
    material: "Red Oak",
    finishOption: "",
    bullnose: false,
    finalWidth: 0,
    finalDepth: 0,
    finalThickness: 0,
};

export const initialReplacementTreadValues = {
    generator: GeneratorEnum.MultiTreads as GeneratorEnum,
    generatedStatus: GeneratedStatusEnum.Stale,
    orderNumber: "",
    width: 36,
    depth: 11.25,
    thickness: 0.5,
    returnRight: false,
    returnLeft: false,
    returnLength: 1,
    returnWidth: 1.25,
    nosingRight: false,
    nosingLeft: false,
    nosingFront: true,
    nosingBack: false,
    treadNosing: 0.75,
    nosingThickness: 1,
    roundOver: 0.25,
    material: "Red Oak",
    finishOption: "",
    bullnose: false,
    finalWidth: 0,
    finalDepth: 0,
    finalThickness: 0,
};

// export enum GeneratorEnum {
//   "ThickTread" = "ThickTread",
//   "ReplacementTread" = "ReplacementTread",
//   "Gate" = "Gate",
//   "GlassPanel" = "GlassPanel",
//   "FlightEconomy" = "FlightEconomy",
//   "FlightCheck" = "FlightCheck",
//   "Flight" = "flightGenerator",
//   "StraightFlight" = "straightFlightGenerator",
// }
