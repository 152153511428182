import React, { useContext, useState, useRef, useEffect } from "react";
import { Grid, CircularProgress, Typography } from "@material-ui/core";
import { GateMaterials } from "../../Global/Constants/Gates";
import { ReturnedItem, Item, OrderContext, GeneratorEnum } from "../../Context/OrderContext";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import FormTextField from "../../Components/FormComponents/FormTextField";
import FormCheckbox from "../../Components/FormComponents/FormCheckbox";
import FormSelect from "../../Components/FormComponents/FormSelect";
import { getGateFinishes } from "./GateFormHelpers";
import { GateBuilderFormValues } from "Global/Constants/itemTypes";
import { initialGateValues } from "Global/Constants/itemDefaults";
import useFormInversion from "Hooks/useFormInversion";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "../../Components/Button";
import { getGlassThicknesses } from "Global/Constants/Glass";
import { runGenerator } from "Global/Helpers/RunGenerator";

export interface GateItem {
    productId: number;
    name: string;
    quantity: number;
    description: string;
    price: number;
    material?: string;
    finishOption?: string;
    type: string;
}

export interface ReturnedGate extends GateBuilderFormValues, Item {
    price: number;
    bomString: string;
    gateString: string;
    items: Array<GateItem>;
}

function GateBuilderForm(props: GateBuilderFormProps) {
    const { state } = useContext(OrderContext);
    const { orderNumber } = state;
    const { connectedSocket, loading, setLoading, setReturnedItem } = props;
    /* const returnedItem = props.returnedItem as ReturnedGate; */
    const [initialValues] = useState(initialGateValues);
    const methods = useForm({
        defaultValues: initialValues,
    });
    const { handleSubmit, control, watch } = methods;
    const [gateWidthForm, setGateWidthForm] = useState({
        input: {
            min: 25.125,
            max: 55.125,
            step: 0.0001,
        },
        tooltip: "Min 25.125 -- Max 55.125",
    });

    const [systemTypes, setSystemTypes] = useState([
        {
            label: "Flat Top",
            value: "Flat Top",
        },
        {
            label: "Universal Top",
            value: "Universal Top",
        },
        {
            label: "Quick Slide",
            value: "Quick Slide",
        },
    ]);

    const watchFields = watch(["infill", "material", "doubleGate", "openingWidth"]);
    const watchAllFields = watch(["openingWidth", "doubleGate"]);

    const onSubmit: SubmitHandler<GateBuilderFormValues> = async (values) => {
        setReturnedItem(undefined);
        setLoading(true);

        if (connectedSocket && orderNumber) {
            let data = {
                ...initialValues,
                ...values,
            };
            delete data.layout;

            if (state.uuid) {
                Object.assign(data, { uuid: state.uuid });
            }

            await runGenerator(GeneratorEnum.Gate, connectedSocket.id, data, orderNumber);
        }
    };

    useFormInversion(watchAllFields, props.values, props.onChange);

    useEffect(() => {
        switch (watchFields.material) {
            case "Aluminum":
                watchFields.doubleGate
                    ? setGateWidthForm({
                          input: { min: 49.875, max: 109.875, step: 0.0001 },
                          tooltip: "Min 49.875 -- Max 109.875",
                      })
                    : setGateWidthForm({
                          input: { min: 25.125, max: 55.125, step: 0.0001 },
                          tooltip: "Min 25.125 -- Max 55.125",
                      });
                setSystemTypes((prev) => [
                    ...prev.filter((option) => option.value !== "Quick Slide"),
                    { label: "Quick Slide", value: "Quick Slide" },
                ]);
                break;
            case "2205":
                watchFields.doubleGate
                    ? setGateWidthForm({
                          input: { min: 49.875, max: 97.875, step: 0.0001 },
                          tooltip: "Min 49.875 -- Max 97.875",
                      })
                    : setGateWidthForm({
                          input: { min: 25.125, max: 49.125, step: 0.0001 },
                          tooltip: "Min 25.125 -- Max 49.125",
                      });
                setSystemTypes((prev) => [...prev.filter((option) => option.value !== "Quick Slide")]);
                break;
            case "304":
                watchFields.doubleGate
                    ? setGateWidthForm({
                          input: { min: 49.875, max: 97.875, step: 0.0001 },
                          tooltip: "Min 49.875 -- Max 97.875",
                      })
                    : setGateWidthForm({
                          input: { min: 25.125, max: 49.125, step: 0.0001 },
                          tooltip: "Min 25.125 -- Max 49.125",
                      });
                setSystemTypes((prev) => [...prev.filter((option) => option.value !== "Quick Slide")]);
                break;
        }
    }, [watchFields.material, watchFields.doubleGate]);

    const expandIcon = <ExpandMoreIcon className="text-white" />;

    const [expanded, setExpanded] = React.useState<string | false>("panel1");

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    // DOM Rendering *************************
    return (
        <div id="form-wrapper" className="container relative top-0 p-0 m-0 grid">
            <div className="w-full p-2 m-0 grid place-content-center">
                <Typography className="w-full text-primary" variant="h5">
                    Gate Generator
                </Typography>
            </div>

            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
                        <AccordionSummary
                            className="accordion"
                            expandIcon={expandIcon}
                            aria-label="Expand"
                            aria-controls="gate-dim-content"
                            id="gate-eng-header"
                        >
                            <div className="flex w-full p-2 text-white uppercase bg-primary">
                                <span>Engineering Data</span>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} className="p-2">
                                <Grid item xs={6}>
                                    <FormTextField
                                        variant="outlined"
                                        label="Revision"
                                        name="revision"
                                        InputProps={{
                                            inputProps: {
                                                min: 0,
                                            },
                                        }}
                                        tooltip=""
                                        type="number"
                                        className="bg-white"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormTextField
                                        variant="outlined"
                                        label="Engineer"
                                        name="engineer"
                                        tooltip=""
                                        className="bg-white"
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                        <AccordionSummary
                            className="accordion"
                            expandIcon={expandIcon}
                            aria-label="Expand"
                            aria-controls="gate-dim-content"
                            id="gate-dim-header"
                        >
                            <div className="flex w-full p-2 text-white uppercase bg-primary">
                                <span>Dimensions</span>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} className="p-2">
                                <Grid item xs={6}>
                                    <FormSelect
                                        name="systemHeight"
                                        label="Gate Height (in.)"
                                        options={[
                                            { label: "36 Inch", value: 36 },
                                            { label: "39 Inch", value: 39 },
                                            { label: "42 Inch", value: 42 },
                                        ]}
                                        control={control}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormTextField
                                        name="openingWidth"
                                        label="Opening Width (in.)"
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            inputProps: gateWidthForm.input,
                                        }}
                                        tooltip={gateWidthForm.tooltip}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            className="accordion"
                            expandIcon={expandIcon}
                            aria-controls="gate-ret-content"
                            id="gate-ret-header"
                        >
                            <Grid item xs={12}>
                                <div className="flex w-full p-2 text-white uppercase bg-primary">
                                    <span>Layout</span>
                                </div>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} className="p-2">
                                <Grid item xs={12}>
                                    <FormCheckbox name="doubleGate" label="Double Gate?" />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSelect
                                        name="systemType"
                                        label="System Type"
                                        options={systemTypes}
                                        control={control}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSelect
                                        name="infill"
                                        label="Infill"
                                        options={[
                                            {
                                                label: "Stainless Rod",
                                                value: "Stainless",
                                            },
                                            { label: "Onyx Rod", value: "Onyx" },
                                            { label: "Glass", value: "Glass" },
                                        ]}
                                        control={control}
                                    />
                                </Grid>
                                {watchFields.infill === "Glass" && (
                                    <Grid item xs={12}>
                                        <FormSelect
                                            name="glassThickness"
                                            label="Glass Thickness"
                                            options={getGlassThicknesses()}
                                            control={control}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <FormSelect
                                        name="latchSide"
                                        label="Latch Side"
                                        options={[
                                            {
                                                label: "Left",
                                                value: "Left",
                                            },
                                            {
                                                label: "Right",
                                                value: "Right",
                                            },
                                        ]}
                                        control={control}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            className="accordion"
                            expandIcon={expandIcon}
                            aria-controls="gate-mat-content"
                            id="gate-mat-header"
                        >
                            <Grid item xs={12}>
                                <div className="flex w-full p-2 text-white uppercase bg-primary">
                                    <span>Material</span>
                                </div>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} className="p-2">
                                <Grid item xs={6}>
                                    <FormSelect
                                        name="material"
                                        label="Material"
                                        options={GateMaterials.map((material) => ({
                                            label: material.name,
                                            value: material.generatorName,
                                        }))}
                                        control={control}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormSelect
                                        name="finish"
                                        label="Finish"
                                        options={getGateFinishes(watchFields.material)}
                                        control={control}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Grid item xs={12}>
                        <Grid item container className="relative p-2 place-content-end">
                            {loading && (
                                <Grid className="absolute grid place-items-end">
                                    <CircularProgress />
                                </Grid>
                            )}
                            {state.uuid && <Grid className="grid place-items-end"></Grid>}
                            <Grid className="container grid place-items-end">
                                <Button type="submit" tcolor="text-white">
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </FormProvider>
        </div>
    );
}

interface GateBuilderFormProps {
    onChange?: any;
    values?: any;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    connectedSocket: SocketIOClient.Socket | undefined;
    setReturnedItem: React.Dispatch<React.SetStateAction<ReturnedItem | undefined>>;
    returnedItem: ReturnedItem | undefined;
    setSelectedId: React.Dispatch<React.SetStateAction<string | null>>;
}
export default GateBuilderForm;
