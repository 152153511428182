import { GeneratorEnum } from "../../Context/OrderContext";
import { processReturnedTread } from "../../Pages/TreadBuilder/MultiTreadHelpers";
import { processReturnedFlight } from "../../Pages/Flight/FlightHelpers";
import { processReturnedGate } from "../../Pages/GateBuilder/GateFormHelpers";
import { processStraightReturnedFlight } from "../../Pages/Flight/StraightFlightHelpers";
import { processReturnedLanding } from "../../Pages/Landing/LandingHelpers";
import { processReturnedRiser } from "Pages/Riser/RiserHelpers";
import { processReturnedS4S } from "Pages/S4S/S4SHelpers";
import { processReturnedSwitchbackFlight } from "Pages/Flight/SwitchbackFlightHelpers";
import { processReturnedNinetyFlight } from "Pages/Flight/NinetyFlightHelpers";
import { processReturnedUFlight } from "Pages/UFlight/UFlightFormHelpers";
import { processReturnedLevelRun } from "Pages/LevelRun/LevelRunHelpers";
import { processReturnedLandingTread } from "Pages/LandingTreads/LandingTreadHelpers";

export async function processReturnedItem(data: any) {
    const generator = data.generator;
    if (generator === GeneratorEnum.MultiTreads) {
        let item = processReturnedTread(data);
        return item;
    } else if (data.generator === GeneratorEnum.FlightEconomy) {
        return;
    } else if (data.generator === GeneratorEnum.Flight) {
        let item = await processReturnedFlight(data);
        return item;
    } else if (data.generator === GeneratorEnum.StraightFlight) {
        let item = await processStraightReturnedFlight(data);
        return item;
    } else if (data.generator === GeneratorEnum.NinetyFlight) {
        let item = await processReturnedNinetyFlight(data);
        return item;
    } else if (data.generator === GeneratorEnum.SwitchbackFlight) {
        let item = await processReturnedSwitchbackFlight(data);
        return item;
    } else if (data.generator === GeneratorEnum.UFlight) {
        let item = await processReturnedUFlight(data);
        return item;
    } else if (data.generator === GeneratorEnum.Gate) {
        let item = processReturnedGate(data);
        return item;
    } else if (data.generator === GeneratorEnum.Landing) {
        let item = processReturnedLanding(data);
        return item;
    } else if (data.generator === GeneratorEnum.LandingTread) {
        let item = processReturnedLandingTread(data);
        return item;
    } else if (data.generator === GeneratorEnum.Riser) {
        let item = processReturnedRiser(data);
        return item;
    } else if (data.generator === GeneratorEnum.S4S) {
        let item = processReturnedS4S(data);
        return item;
    } else if (data.generator === GeneratorEnum.LevelRun) {
        let item = await processReturnedLevelRun(data);
        return item;
    } else {
        return;
    }
}

export function processReturnedFlightEconomy(data: any) {
    const orderNumber = data.orderNumber;
    const uuidParam = data.uuid;
    const ceilingGap = data.ceilingGap;
    const ceilingHeight = data.ceilingHeight;
    const fastenersFloor = data.fastenersFloor;
    const fastenersHeader = data.fastenersHeader;
    const floorToFloor = data.floorToFloor;
    const formTreadRise = data.formTreadRise;
    const handrailHeight = data.handrailHeight;
    const headerHeight = parseFloat(data.headerHeight);
    const treadMaterial = data.treadMaterial;
    const treadfinish = data.treadfinish;
    const treadWidth = data.treadWidth;
    const bomString = data.BOMString;

    return {
        orderNumber: orderNumber,
        uuid: uuidParam,
        ceilingGap: ceilingGap,
        ceilingHeight: ceilingHeight,
        fastenersFloor: fastenersFloor,
        fastenersHeader: fastenersHeader,
        floorToFloor: floorToFloor,
        formTreadRise: formTreadRise,
        handrailHeight: handrailHeight,
        headerHeight: headerHeight,
        treadMaterial: treadMaterial,
        treadfinish: treadfinish,
        treadWidth: treadWidth,
        bomString: bomString,
    };
}
