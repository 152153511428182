import React, { useRef, useState, useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem, OutlinedInput, SelectProps } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";

function FormSelect(props: FormSelectProps) {
    const { label, name, options, ...other } = props;
    const inputLabel = useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = useState(0);
    const { control, onChange = undefined } = useFormContext() as UseFormMethodsWithOnBlur;

    useEffect(() => {
        setLabelWidth(
            inputLabel && inputLabel.current && inputLabel.current.offsetWidth ? inputLabel.current.offsetWidth : 0
        );
    }, []);

    return (
        <FormControl size="small" variant="outlined" fullWidth>
            <InputLabel ref={inputLabel}>{label}</InputLabel>
            <Controller
                render={({ onBlur, ref, onChange: onChangeHook, value }) => (
                    <Select
                        onBlur={onBlur}
                        inputRef={ref}
                        fullWidth
                        {...other}
                        input={<OutlinedInput labelWidth={labelWidth} id={name} />}
                        onChange={(e) => {
                            onChangeHook(e.target.value);
                            if (onChange) {
                                onChange({ name, value: e.target.value });
                            }
                        }}
                        value={value}
                    >
                        {options.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                )}
                name={name}
                control={control}
                defaultValue=""
            />
        </FormControl>
    );
}

interface FormSelectOption {
    label: string;
    value: string | number;
}
interface FormSelectProps extends SelectProps {
    label: string;
    name: string;
    control: any;
    options: FormSelectOption[];
}
export default FormSelect;
