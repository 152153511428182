import React, { useContext } from "react";
import { ReturnedItem, Item, OrderContext, GeneratorEnum } from "Context/OrderContext";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import useFetch from "use-http";
import { ENDPOINT } from "Global/Constants/environment";
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, CircularProgress } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { S4SFormValues } from "Global/Constants/itemTypes";
import { initialS4SValues } from "Global/Constants/itemDefaults";
import FormTextField from "Components/FormComponents/FormTextField";
import FormSelect from "Components/FormComponents/FormSelect";
import { materials } from "Global/Constants/Materials";
import { getFinishOptions } from "Pages/TreadBuilder/MultiTreadHelpers";
import Button from "Components/Button";
import FormCheckbox from "Components/FormComponents/FormCheckbox";
import S4SFormLogic from "./S4SFormLogic";
import { runGenerator } from "Global/Helpers/RunGenerator";

export interface ReturnedS4S extends S4SFormValues, Item {
    finalWidth: number;
    finalDepth: number;
    finalThickness: number;
    boardFeet: number;
    chop: number;
    bunk: string;
    pdfUrl: string;
    rip: number;
    plane: number;
    chopQty: number;
    price: number;
}

function S4SForm(props: S4SFormProps) {
    const { state, dispatch } = useContext(OrderContext);
    const { orderNumber } = state;
    const { loading, setLoading, connectedSocket, setReturnedItem } = props;

    const [expanded, setExpanded] = React.useState<string | false>("panel1");
    const expandIcon = <ExpandMoreIcon className="text-white" />;
    const [initialValues] = React.useState(initialS4SValues());
    //Form Setup
    const methods = useForm<S4SFormValues>({
        defaultValues: initialValues,
    });
    const { handleSubmit, watch, control, reset } = methods;
    const { post, response } = useFetch(ENDPOINT);

    const watchFields = watch(["woodSpecies"]);

    //Form Submit
    const onSubmit: SubmitHandler<S4SFormValues> = async (values) => {
        setReturnedItem(undefined);
        setLoading(true);

        if (connectedSocket && orderNumber) {
            let data = {
                ...initialValues,
                ...values,
            };

            delete data.layout;
            if (state.uuid) {
                Object.assign(data, { uuid: state.uuid });
            }

            await runGenerator(GeneratorEnum.S4S, connectedSocket.id, data, orderNumber);
        }
    };

    function handleCancelEdit() {
        dispatch({ type: "CancelEditItem" });
        reset(initialValues);
    }
    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    // DOM Rendering ***********************************
    return (
        <div id="form-wrapper" className="relative top-0 p-0 m-0 grid">
            <div className="w-full p-2 m-0 grid place-content-center">
                <Typography className="w-full text-primary" variant="h5">
                    S4S Generator
                </Typography>
            </div>

            <FormProvider {...methods}>
                <S4SFormLogic>
                    {(test) => {
                        return (
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
                                    <AccordionSummary
                                        className="accordion"
                                        expandIcon={expandIcon}
                                        aria-label="Expand"
                                        aria-controls="tread-dim-content"
                                        id="tread-dim-header"
                                    >
                                        <div className="flex w-full p-2 text-white uppercase bg-primary">
                                            <span>Dimensions</span>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2} className="p-2">
                                            <Grid item xs={12}>
                                                <FormTextField
                                                    name="width"
                                                    variant="outlined"
                                                    label="Width (in.)"
                                                    type="number"
                                                    InputProps={{
                                                        inputProps: { min: 6, max: 120, step: 0.0001 },
                                                    }}
                                                    tooltip="Min 6 -- Max 120"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormTextField
                                                    variant="outlined"
                                                    name="depth"
                                                    label="Depth (in.)"
                                                    type="number"
                                                    InputProps={{
                                                        inputProps: { min: 2, max: 30, step: 0.0001 },
                                                    }}
                                                    tooltip="Min 2 -- Max 30"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormTextField
                                                    variant="outlined"
                                                    name="thickness"
                                                    label="Thickness (in.)"
                                                    type="number"
                                                    InputProps={{
                                                        inputProps: { min: 0.75, max: 2, step: 0.25 },
                                                    }}
                                                    tooltip={"Min 0.75 -- Max 2 -- Increments 0.25"}
                                                />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        className="accordion"
                                        expandIcon={expandIcon}
                                        aria-controls="tread-mat-content"
                                        id="tread-mat-header"
                                    >
                                        <Grid item xs={12}>
                                            <div className="flex w-full p-2 text-white uppercase bg-primary">
                                                <span>Material</span>
                                            </div>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2} className="p-2">
                                            <Grid item xs={6}>
                                                <FormSelect
                                                    name="woodSpecies"
                                                    label="Wood Type"
                                                    options={materials.map((material) => ({
                                                        label: material.name,
                                                        value: material.name,
                                                    }))}
                                                    control={control}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormSelect
                                                    name="finishOption"
                                                    label="Finish"
                                                    options={getFinishOptions(watchFields.woodSpecies)}
                                                    control={control}
                                                />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        className="accordion"
                                        expandIcon={expandIcon}
                                        aria-controls="landing-mat-content"
                                        id="landing-mat-header"
                                    >
                                        <Grid item xs={12}>
                                            <div className="flex w-full p-2 text-white uppercase bg-primary">
                                                <span>Features</span>
                                            </div>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2} className="p-2">
                                            <Grid item xs={6}>
                                                <FormCheckbox label="Distressed" name="distressed" />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormCheckbox label="Hand Scraped" name="handScraped" />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormCheckbox label="Wirebrushed" name="wireBrushed" />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Grid item xs={12}>
                                    <Grid item container className="relative p-2 place-content-end">
                                        {loading && (
                                            <Grid className="absolute grid place-items-end">
                                                <CircularProgress />
                                            </Grid>
                                        )}
                                        {state.uuid && (
                                            <Grid className="grid place-items-end">
                                                <Button onClick={() => handleCancelEdit()}>Cancel Edit</Button>
                                            </Grid>
                                        )}
                                        <Grid className="container grid place-items-end">
                                            <Button type="submit" tcolor="text-white">
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                </S4SFormLogic>
            </FormProvider>
        </div>
    );
}

interface S4SFormProps {
    values?: any;
    onChange?: any;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    connectedSocket: SocketIOClient.Socket | undefined;
    returnedItem: ReturnedItem | undefined;
    setReturnedItem: React.Dispatch<React.SetStateAction<ReturnedItem | undefined>>;
}
export default S4SForm;
