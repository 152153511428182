import React from "react";
import { AppBar } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import MenuButton from "./MenuButton";
import BugReport from "./BugReport/BugReport";

function VoeAppbar() {
    const history = useHistory();
    const location = useLocation();

    return (
        <div
            id="bar-wrapper"
            className="relative top-0 left-0 items-start inline-block w-full p-0 m-0 text-xl font-light voe-header-wrapper font-body"
        >
            <AppBar
                id="VOE-Header"
                position="fixed"
                className="{clsx(classes.appBar, {[classes.appBarShift]: open,})} p-4 m-0 grid place-content-center"
                style={{
                    opacity: "0.9",
                    background: "#0485A1",
                    height: "130px",
                }}
            >
                <div id="menu-wrapper" className="relative grid grid-rows-2 place-content-center">
                    <div id="logo" className="relative flex w-full p-1 m-auto place-content-center top-2">
                        <img
                            className="w-40 cursor-pointer"
                            src="/vr_logo.svg"
                            alt="StairSupplies Logo"
                            onClick={() => history.push("/")}
                        />
                    </div>

                    {location.pathname !== "/" ? (
                        <div className="flex items-center w-auto p-2 mt-4 text-base space-x-14">
                            <div className="">
                                <button onClick={() => history.push("/")}>Home</button>
                            </div>
                            {/*<div className="">
                                <MenuButton
                                    label="Wood Generators"
                                    menuItems={[
                                        { label: "Treads", route: "/generators/multiTreads" },
                                        { label: "Landings", route: "/generators/landing" },
                                        { label: "Landing Treads", route: "/generators/landingTreads" },
                                        { label: "Risers", route: "/generators/riser" },
                                        { label: "S4S", route: "/generators/s4s" },
                                    ]}
                                />
                            </div> */}
                            <div className="">
                                <button onClick={() => history.push("/generators/gates")}>Gates</button>
                            </div>
                            <BugReport />
                        </div>
                    ) : (
                        <div className="items-center p-3 ">
                            <div className="">
                                <div className="uppercase">Visual Order Entry</div>
                            </div>
                        </div>
                    )}
                </div>
            </AppBar>
        </div>
    );
}

export default VoeAppbar;
