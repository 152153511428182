import { OD1_TOKEN, OD1_DEV } from "../../Global/Constants/environment";
import { OrderContextState, GeneratorEnum } from "../../Context/OrderContext";
import { formatTreadForOD1 } from "./TreadFormHelpers";
import { ReturnedTread } from "./MultiTreadForm";

const options = {
    headers: {
        "X-api-auth": OD1_TOKEN,
    },
};
export const getOrderSummary = async (orderNumber: string) => {
    const response = await fetch(OD1_DEV + `/get_order_summary.cfm?OrderNumber=${orderNumber}`, options);
    const data = await response.json();
    return data;
};

export const sendToOd1 = async (order: OrderContextState) => {
    if (!order.orderNumber) return;
    let { items } = order;
    let products: any[] = [];
    items.forEach((item) => {
        if (item.generator === GeneratorEnum.MultiTreads) {
            products.push(formatTreadForOD1(item as ReturnedTread));
        }
    });

    let data = {
        OrderNumber: order.orderNumber,
        Products: products,
    };

    const response = await fetch(OD1_DEV + "/order_import_items.cfm", {
        method: "POST",
        ...options,
        body: JSON.stringify(data),
    });
    const responseData = await response.json();
    return responseData;
};
