import { OrderContextState, ReturnedItem, OrderDefaults, getBlankOrderContextState } from "./OrderContext";
import { updateIn } from "immutable";
import { Post, Label, Link, Arrow } from "Global/Constants/layout";
import { GeneratedStatusEnum } from "Global/Constants/itemTypes";

export type OrderContextAction =
    | { type: "setState"; state: OrderContextState }
    | {
          type: "UpdateOrderDetails";
          orderNumber: string;
          customerName: string;
      }
    | { type: "UpdateSelectedId"; selectedId: string }
    | { type: "AddOrderItem"; item: ReturnedItem }
    | { type: "EditOrderItem"; uuid: string | null }
    | { type: "moveItem"; uuid: string; position: { x: number; y: number; z?: number } }
    | { type: "CancelEditItem" }
    | { type: "UpdateOrderItem"; item: Partial<ReturnedItem> }
    | { type: "RemoveOrderItem"; uuid: string; index?: number }
    | { type: "UpdateOrderPosts"; posts: Record<string, Post> }
    | { type: "UpdateOrderLabels"; labels: Record<string, Label> }
    | { type: "UpdateLinks"; links: Array<Link> }
    | { type: "SetArrow"; arrow: Arrow }
    | { type: "DeleteArrow"; uuid: string }
    | { type: "AddLink"; link: Link }
    | { type: "UpdateDefaults"; defaults: OrderDefaults }
    | { type: "ResetState" }
    | { type: "ClearGeneratingStatus" };

export function OrderContextReducer(state: OrderContextState, action: OrderContextAction) {
    switch (action.type) {
        case "SetArrow":
            return {
                ...state,
                arrows: {
                    ...state.arrows,
                    [action.arrow.uuid]: action.arrow,
                },
            };
        case "DeleteArrow":
            const arrows = { ...state.arrows };

            delete arrows[action.uuid];

            return {
                ...state,
                arrows,
            };
        case "moveItem":
            return {
                ...state,
                items: state.items.map((item) => {
                    if (item.uuid === action.uuid) {
                        return updateIn(item, ["layout", "position"], (point) => ({ ...point, ...action.position }));
                    } else {
                        return item;
                    }
                }),
            };
        case "ClearGeneratingStatus":
            return {
                ...state,
                items: state.items.map((item: any) => {
                    if (item.generatedStatus === GeneratedStatusEnum.Generating) {
                        return {
                            ...item,
                            generatedStatus: GeneratedStatusEnum.Stale,
                        };
                    }

                    return item;
                }),
            };
        case "UpdateOrderDetails":
            return {
                ...state,
                orderNumber: action.orderNumber,
                customerName: action.customerName,
            };
        case "UpdateSelectedId":
            return {
                ...state,
                selectedId: action.selectedId,
            };
        case "AddOrderItem":
            return {
                ...state,
                items: [...state.items, action.item],
            };
        case "EditOrderItem":
            return {
                ...state,
                uuid: action.uuid,
            };
        case "CancelEditItem":
            return {
                ...state,
                uuid: null,
            };
        case "UpdateOrderItem":
            return {
                ...state,
                items: state.items.map((item) => {
                    if (item.uuid === action.item.uuid) {
                        return {
                            ...item,
                            ...action.item,
                        };
                    }
                    return item;
                }),
                uuid: null,
            };
        case "UpdateOrderPosts":
            return {
                ...state,
                posts: action.posts,
            };
        case "UpdateOrderLabels":
            return {
                ...state,
                labels: action.labels,
            };
        case "RemoveOrderItem":
            console.log(action);
            // if (action.index) {
            //     console.log("found index");
            //     return {
            //         ...state,
            //         items: state.items.filter((_, index) => index !== action.index),
            //     };
            // } else {
            //     return {
            //         ...state,
            //         items: state.items.filter((item) => item.uuid !== action.uuid),
            //     };
            // }
            return {
                ...state,
                items: state.items.filter((_, index) => index !== action.index),
            };
        case "ResetState":
            return {
                ...getBlankOrderContextState(),
            };
        case "UpdateLinks":
            return {
                ...state,
                links: [...action.links],
            };
        case "AddLink":
            return {
                ...state,
                links: [...state.links, action.link],
            };
        case "UpdateDefaults":
            return {
                ...state,
                defaults: action.defaults,
            };
        case "setState":
            return action.state;
        default:
            return state;
    }
}
