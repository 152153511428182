import React from "react";
import { Card, CardContent, Typography, CardActions, Button, makeStyles, Theme } from "@material-ui/core";
export interface Customer {
    name: string;
    email: string;
    address1: string;
    city: string;
    state: string;
    postalCode: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    cardBase: {
        "text-align": "center",
    },
    submitButton: {
        marginLeft: "auto",
    },
}));

function CustomerCard(props: CustomerCardProps) {
    const { customer, onConfirm, customerLoaded } = props;
    const classes = useStyles();

    return (
        <Card className={!customerLoaded ? classes.cardBase : ""}>
            {customerLoaded ? (
                <>
                    <CardContent>
                        <Typography variant="h5">{customer.name}</Typography>
                        <Typography variant="subtitle1">{customer.email}</Typography>
                        <Typography variant="body2">{customer.address1}</Typography>
                        <Typography variant="body2">
                            {customer.city},{customer.state} {customer.postalCode}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button className={classes.submitButton} onClick={onConfirm}>
                            Confirm
                        </Button>
                    </CardActions>
                </>
            ) : (
                <CardContent>
                    <Typography variant="h5">Load Customer Order</Typography>
                </CardContent>
            )}
        </Card>
    );
}
interface CustomerCardProps {
    customerLoaded: boolean;
    customer: Customer;
    onConfirm: () => void;
}
export default CustomerCard;
