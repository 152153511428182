import React, { Dispatch, useState, createContext, PropsWithChildren, useReducer, useEffect } from "react";
import { OrderContext, ReturnedItem } from "Context/OrderContext";
import { FlightCheck } from "Pages/Flight/FlightTypes";
import { processReturnedItem } from "Global/Helpers/ProcessGenerator";
import { processFlightCheck } from "Pages/Flight/FlightHelpers";
import {
    initiateSocket,
    socket,
    subscribeToGenerator,
    subscribeToSvf,
    disconnectSocket,
    subscribeToFlightCheck,
} from "Global/Helpers/SocketIO";

function OrderContextProvider(props: PropsWithChildren<{}>) {
    const [connectedSocket, setConnectedSocket] = useState<SocketIOClient.Socket>();
    const { state, dispatch } = React.useContext(OrderContext);
    const itemsRef = React.useRef(state.items);
    itemsRef.current = state.items;

    const onSubmitSuccess = (item: any) => {
        if (!item.uuid) return;

        const currentItem = itemsRef.current.find(({ uuid }) => uuid === item.uuid);

        dispatch({
            type: "UpdateOrderItem",
            item: { ...currentItem, ...item }, // preserve layout && w/e
        });
    };

    useEffect(() => {
        //connect to socket
        initiateSocket();
        //get socket connection data
        setConnectedSocket(socket);

        //subscribe to socket results
        subscribeToGenerator((err, msg) => {
            if (err) {
                console.log(err);
            } else {
                processReturnedItem(msg).then((returnedItem) => {
                    if (onSubmitSuccess && returnedItem) onSubmitSuccess(returnedItem);
                });
            }
        });
        subscribeToSvf((err, msg) => {
            if (err) {
                console.log(err);
            } else {
                // setSelectedId(`${msg.uuid}.json`);
                // setLoading(false);
            }
        });
        subscribeToFlightCheck((err, msg) => {
            if (err) {
                console.log(err);
            } else {
                let returnedCheck = processFlightCheck(msg);
                // setFlightCheck(returnedCheck);
                console.log(returnedCheck);
                // setLoading(false);
            }
        });

        return () => {
            disconnectSocket();
        };
    }, []); //eslint-disable-line

    return (
        <GeneratorContext.Provider
            value={{
                connectedSocket,
                // loading,
                // setLoading,
                // flightCheck,
                // selectedId,
                // setSelectedId,
            }}
        >
            {props.children}
        </GeneratorContext.Provider>
    );
}

export const GeneratorContext = createContext({} as any);

export const useGeneratorContext = () => React.useContext(GeneratorContext);

export default OrderContextProvider;
