export const materials = [
    {
        id: 1,
        name: "Red Oak",
        pricing: {
            Tread: 9.3,
            ThickTread: 14.14,
            Replacement: 10.34,
            Landing: 12.7,
            ThickLanding: 14.14,
            ReplacementLanding: 12.7,
            LandingTread: 11.09,
            Riser: 11.09,
            S4S: 11.09,
        },
    },
    {
        id: 2,
        name: "White Oak",
        pricing: {
            Tread: 11.68,
            ThickTread: 23.68,
            Replacement: 13.22,
            Landing: 21.12,
            ThickLanding: 23.68,
            ReplacementLanding: 21.12,
            LandingTread: 16.9,
            Riser: 16.89,
            S4S: 16.89,
        },
    },
    {
        id: 3,
        name: "Hard Maple",
        pricing: {
            Tread: 8.72,
            ThickTread: 15.94,
            Replacement: 10.13,
            Landing: 14.79,
            ThickLanding: 15.94,
            ReplacementLanding: 14.79,
            LandingTread: 23.24,
            Riser: 11.97,
            S4S: 11.97,
        },
    },
    {
        id: 4,
        name: "Walnut",
        pricing: {
            Tread: 28.86,
            ThickTread: 34.68,
            Replacement: 33.64,
            Landing: 33.45,
            ThickLanding: 34.68,
            ReplacementLanding: 33.45,
            LandingTread: 41.54,
            Riser: 31.68,
            S4S: 31.68,
        },
    },
    {
        id: 5,
        name: "Hickory",
        pricing: {
            Tread: 10.41,
            ThickTread: 17.49,
            Replacement: 11.82,
            Landing: 15.49,
            ThickLanding: 17.49,
            ReplacementLanding: 15.49,
            LandingTread: 16.19,
            Riser: 13.38,
            S4S: 13.38,
        },
    },
    {
        id: 6,
        name: "Poplar",
        pricing: {
            Tread: 7.17,
            ThickTread: 11.6,
            Replacement: 8.15,
            Landing: 10.27,
            ThickLanding: 11.6,
            ReplacementLanding: 10.27,
            LandingTread: 8.15,
            Riser: 9.15,
            S4S: 9.15,
        },
    },
    {
        id: 7,
        name: "African Mahogany",
        pricing: {
            Tread: 19.48,
            ThickTread: 25.39,
            Replacement: 22.53,
            Landing: 23.24,
            ThickLanding: 25.39,
            ReplacementLanding: 23.24,
            LandingTread: 43.65,
            Riser: 22.19,
            S4S: 22.19,
        },
    },
    {
        id: 8,
        name: "Ash",
        pricing: {
            Tread: 7.45,
            ThickTread: 16.72,
            Replacement: 8.72,
            Landing: 14.79,
            ThickLanding: 16.72,
            ReplacementLanding: 14.79,
            LandingTread: 13.38,
            Riser: 11.27,
            S4S: 11.27,
        },
    },
    {
        id: 9,
        name: "American Cherry",
        pricing: {
            Tread: 13.65,
            ThickTread: 21.52,
            Replacement: 15.76,
            Landing: 19.71,
            ThickLanding: 21.52,
            ReplacementLanding: 19.71,
            LandingTread: 32.39,
            Riser: 17.12,
            S4S: 17.12,
        },
    },
    {
        id: 12,
        name: "Brazilian Cherry",
        pricing: {
            Tread: 16.27,
            ThickTread: 28.48,
            Replacement: 19,
            Landing: 26.05,
            ThickLanding: 37.57,
            ReplacementLanding: 26.05,
            LandingTread: 34.16,
            Riser: 20.42,
            S4S: 20.42,
        },
    },
    {
        id: 15,
        name: "Sapele",
        pricing: {
            Tread: 19.7,
            ThickTread: 25.39,
            Replacement: 22.8,
            Landing: 23.94,
            ThickLanding: 25.39,
            ReplacementLanding: 23.94,
            LandingTread: 43.65,
            Riser: 21.12,
            S4S: 21.12,
        },
    },
    {
        id: 16,
        name: "Alder",
        pricing: {
            Tread: 10.97,
            ThickTread: 17.16,
            Replacement: 12.38,
            Landing: 14.44,
            ThickLanding: 17.16,
            ReplacementLanding: 14.44,
            LandingTread: 30.98,
            Riser: 13.03,
            S4S: 13.03,
        },
    },
    {
        id: 17,
        name: "Quartersawn White Oak",
        pricing: {
            Tread: 20.41,
            ThickTread: 31.43,
            Replacement: 24.63,
            Landing: 28.71,
            ThickLanding: 31.43,
            ReplacementLanding: 28.71,
            LandingTread: 43.65,
            Riser: 25.35,
            S4S: 25.35,
        },
    },
    {
        id: 26,
        name: "Tigerwood",
        pricing: {
            Tread: 27.73,
            ThickTread: 37.01,
            Replacement: 32.38,
            Landing: 35.2,
            ThickLanding: 37.01,
            ReplacementLanding: 35.2,
            LandingTread: 49.65,
            Riser: 31.68,
            S4S: 31.68,
        },
    },
    {
        id: 42,
        name: "Soft Maple",
        pricing: {
            Tread: 11.62,
            ThickTread: 15.02,
            Replacement: 13.37,
            Landing: 15.13,
            ThickLanding: 15.02,
            ReplacementLanding: 15.13,
            LandingTread: 13.74,
            Riser: 13.38,
            S4S: 13.38,
        },
    },
];

export interface Material {
    id: number;
    name: string;
    price_per_bf: number;
}

export interface FinishOption {
    id: number;
    material_id: number;
    name: string;
    finish_multiplier: number;
    finish_operator: FinishOperatorEnum;
}

export enum FinishOperatorEnum {
    "ADD",
    "SUBTRACT",
}

export const finishOptions = [
    {
        id: 0,
        material_id: 1,
        name: "No Finish",
        finish_multiplier: 1,
        finish_operator: FinishOperatorEnum.SUBTRACT,
    },
    {
        id: 1,
        material_id: 1,
        name: "STANDARD Clearcoat 30 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 244,
        material_id: 1,
        name: "Waiting for Stain Number (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 263,
        material_id: 1,
        name: "Red Oak: FC-12151",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 264,
        material_id: 1,
        name: "Red Oak: FC-10857",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 265,
        material_id: 1,
        name: "Red Oak: FC-12117",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 266,
        material_id: 1,
        name: "Red Oak: SW-S64T50",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 267,
        material_id: 1,
        name: "Red Oak: FC-91574",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 289,
        material_id: 1,
        name: "Standard Clearcoat 5 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 360,
        material_id: 1,
        name: "Red Oak: FC-15928",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 361,
        material_id: 1,
        name: "Red Oak: Gunstock-231",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 405,
        material_id: 1,
        name: "Red Oak: FC-9912",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 406,
        material_id: 1,
        name: "Red Oak: FC-10704",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 407,
        material_id: 1,
        name: "Red Oak: FC-12003",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 408,
        material_id: 1,
        name: "Red Oak: FC-12353",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 409,
        material_id: 1,
        name: "Red Oak: FC-16194",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 410,
        material_id: 1,
        name: "Red Oak: FC-17230",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 411,
        material_id: 1,
        name: "Red Oak: FC-17687",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 412,
        material_id: 1,
        name: "Red Oak: LV-0016",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 414,
        material_id: 1,
        name: "Red Oak: LV-0025",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 415,
        material_id: 1,
        name: "Red Oak: LV-0027",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 416,
        material_id: 1,
        name: "Red Oak: LV-0029",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 417,
        material_id: 1,
        name: "Red Oak: LV-0033",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 675,
        material_id: 1,
        name: "Red Oak: Acorn",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 676,
        material_id: 1,
        name: "Red Oak: Acres",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 677,
        material_id: 1,
        name: "Red Oak: Coffee",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 678,
        material_id: 1,
        name: "Red Oak: Copper",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 679,
        material_id: 1,
        name: "Red Oak: Dark Knight",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 680,
        material_id: 1,
        name: "Red Oak: Golden Brown",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 681,
        material_id: 1,
        name: "Red Oak: Golden Honey",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 682,
        material_id: 1,
        name: "Red Oak: Kona",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 683,
        material_id: 1,
        name: "Red Oak: Malaguania",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 684,
        material_id: 1,
        name: "Red Oak: New Carrington",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 686,
        material_id: 1,
        name: "Red Oak: Sealy",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 687,
        material_id: 1,
        name: "Red Oak: Wheat",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 753,
        material_id: 1,
        name: "Red Oak: Antique Gray",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 770,
        material_id: 1,
        name: "Waiting for Stain Match (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 868,
        material_id: 1,
        name: "Red Oak: Ebony",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 869,
        material_id: 1,
        name: "Red Oak: Pewter",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 874,
        material_id: 1,
        name: "Red Oak: Storm Gray",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 875,
        material_id: 1,
        name: "Red Oak: Varathane Weathered Gray",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 883,
        material_id: 1,
        name: "Red Oak: LV-0027 w/ 5 Sheen",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 0,
        material_id: 2,
        name: "No Finish",
        finish_multiplier: 1,
        finish_operator: FinishOperatorEnum.SUBTRACT,
    },
    {
        id: 1,
        material_id: 2,
        name: "STANDARD Clearcoat 30 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 244,
        material_id: 2,
        name: "Waiting for Stain Number (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 289,
        material_id: 2,
        name: "Standard Clearcoat 5 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 355,
        material_id: 2,
        name: "WO Legacy: FC-10704",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 356,
        material_id: 2,
        name: "WO Legacy: FC-12353",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 357,
        material_id: 2,
        name: "WO Legacy: FC-14606",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 358,
        material_id: 2,
        name: "WO Legacy: FC-14615",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 362,
        material_id: 2,
        name: "WO Legacy: Michaels Cherry",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 363,
        material_id: 2,
        name: "WO Legacy: MW-EBOY2718",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 637,
        material_id: 2,
        name: "White Oak: Asbury",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 638,
        material_id: 2,
        name: "White Oak: Briar",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 639,
        material_id: 2,
        name: "White Oak: Copper",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 640,
        material_id: 2,
        name: "White Oak: Dark Knight",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 641,
        material_id: 2,
        name: "White Oak: Driftwood",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 642,
        material_id: 2,
        name: "White Oak: Golden Brown",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 643,
        material_id: 2,
        name: "White Oak: Light Asbury",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 644,
        material_id: 2,
        name: "White Oak: Storm Gray",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 688,
        material_id: 2,
        name: "White Oak: Chocolate Spice",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 689,
        material_id: 2,
        name: "White Oak: Malaguania",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 690,
        material_id: 2,
        name: "White Oak: Sealy",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 752,
        material_id: 2,
        name: "White Oak: Antique Gray",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 770,
        material_id: 2,
        name: "Waiting for Stain Match (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 876,
        material_id: 2,
        name: "White Oak: Varathane Weathered Gray",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 0,
        material_id: 3,
        name: "No Finish",
        finish_multiplier: 1,
        finish_operator: FinishOperatorEnum.SUBTRACT,
    },
    {
        id: 1,
        material_id: 3,
        name: "STANDARD Clearcoat 30 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 244,
        material_id: 3,
        name: "Waiting for Stain Number (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 254,
        material_id: 3,
        name: "Hard Maple: JV-0006",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 255,
        material_id: 3,
        name: "Hard Maple: FC-1204",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 256,
        material_id: 3,
        name: "Hard Maple: MW-2716",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 257,
        material_id: 3,
        name: "Hard Maple: FC-10857",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 258,
        material_id: 3,
        name: "Hard Maple: FC-14636",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 289,
        material_id: 3,
        name: "Standard Clearcoat 5 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 413,
        material_id: 3,
        name: "Hard Maple: LV-0022",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 418,
        material_id: 3,
        name: "Hard Maple: LV-0033",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 419,
        material_id: 3,
        name: "Hard Maple: LV-0034",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 691,
        material_id: 3,
        name: "Hard Maple: Acres",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 692,
        material_id: 3,
        name: "Hard Maple: Chocolate Spice",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 693,
        material_id: 3,
        name: "Hard Maple: Coffee",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 694,
        material_id: 3,
        name: "Hard Maple: Kona",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 695,
        material_id: 3,
        name: "Hard Maple: Malaguania",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 696,
        material_id: 3,
        name: "Hard Maple: New Carrington",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 698,
        material_id: 3,
        name: "Hard Maple: Sealy",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 699,
        material_id: 3,
        name: "Hard Maple: Wheat",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 750,
        material_id: 3,
        name: "Hard Maple: Antique Gray",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 770,
        material_id: 3,
        name: "Waiting for Stain Match (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 0,
        material_id: 4,
        name: "No Finish",
        finish_multiplier: 1,
        finish_operator: FinishOperatorEnum.SUBTRACT,
    },
    {
        id: 1,
        material_id: 4,
        name: "STANDARD Clearcoat 30 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 244,
        material_id: 4,
        name: "Waiting for Stain Number (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 289,
        material_id: 4,
        name: "Standard Clearcoat 5 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 770,
        material_id: 4,
        name: "Waiting for Stain Match (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 0,
        material_id: 5,
        name: "No Finish",
        finish_multiplier: 1,
        finish_operator: FinishOperatorEnum.SUBTRACT,
    },
    {
        id: 1,
        material_id: 5,
        name: "STANDARD Clearcoat 30 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 244,
        material_id: 5,
        name: "Waiting for Stain Number (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 289,
        material_id: 5,
        name: "Standard Clearcoat 5 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 700,
        material_id: 5,
        name: "Hickory: Acres",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 701,
        material_id: 5,
        name: "Hickory: Asbury",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 702,
        material_id: 5,
        name: "Hickory: Coffee",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 703,
        material_id: 5,
        name: "Hickory: Dark Knight",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 704,
        material_id: 5,
        name: "Hickory: Kona",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 705,
        material_id: 5,
        name: "Hickory: New Carrington",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 706,
        material_id: 5,
        name: "Hickory: Storm Gray ",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 707,
        material_id: 5,
        name: "Hickory: Wheat",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 740,
        material_id: 5,
        name: "Hickory: Antique Gray",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 770,
        material_id: 5,
        name: "Waiting for Stain Match (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 877,
        material_id: 5,
        name: "Hickory: Varathane Weathered Gray",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 0,
        material_id: 6,
        name: "No Finish",
        finish_multiplier: 1,
        finish_operator: FinishOperatorEnum.SUBTRACT,
    },
    {
        id: 1,
        material_id: 6,
        name: "STANDARD Clearcoat 30 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 3,
        material_id: 6,
        name: "PRIMED Flat White",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 227,
        material_id: 6,
        name: "Standard Clear Finish/Primed",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 289,
        material_id: 6,
        name: "Standard Clearcoat 5 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 621,
        material_id: 6,
        name: "Black Prime",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 804,
        material_id: 6,
        name: "Black Prime w/ Clear Finish",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 861,
        material_id: 6,
        name: "Black Prime w/5sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 0,
        material_id: 7,
        name: "No Finish",
        finish_multiplier: 1,
        finish_operator: FinishOperatorEnum.SUBTRACT,
    },
    {
        id: 1,
        material_id: 7,
        name: "STANDARD Clearcoat 30 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 244,
        material_id: 7,
        name: "Waiting for Stain Number (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 289,
        material_id: 7,
        name: "Standard Clearcoat 5 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 770,
        material_id: 7,
        name: "Waiting for Stain Match (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 0,
        material_id: 8,
        name: "No Finish",
        finish_multiplier: 1,
        finish_operator: FinishOperatorEnum.SUBTRACT,
    },
    {
        id: 1,
        material_id: 8,
        name: "STANDARD Clearcoat 30 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 244,
        material_id: 8,
        name: "Waiting for Stain Number (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 289,
        material_id: 8,
        name: "Standard Clearcoat 5 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 754,
        material_id: 8,
        name: "Ash: Antique Gray",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 757,
        material_id: 8,
        name: "Ash: Blonde",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 758,
        material_id: 8,
        name: "Ash: Kona",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 759,
        material_id: 8,
        name: "Ash: Blush",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 760,
        material_id: 8,
        name: "Ash: Coffee",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 761,
        material_id: 8,
        name: "Ash: Charcoal",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 762,
        material_id: 8,
        name: "Ash: Pewter",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 763,
        material_id: 8,
        name: "Ash: Lodge Brown",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 764,
        material_id: 8,
        name: "Ash: Acres",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 765,
        material_id: 8,
        name: "Ash: Storm Gray",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 766,
        material_id: 8,
        name: "Ash: Acorn",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 767,
        material_id: 8,
        name: "Ash: Midnight",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 768,
        material_id: 8,
        name: "Ash: Copper",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 769,
        material_id: 8,
        name: "Ash: Varathane Weathered Gray",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 770,
        material_id: 8,
        name: "Waiting for Stain Match (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 0,
        material_id: 9,
        name: "No Finish",
        finish_multiplier: 1,
        finish_operator: FinishOperatorEnum.SUBTRACT,
    },
    {
        id: 1,
        material_id: 9,
        name: "STANDARD Clearcoat 30 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 244,
        material_id: 9,
        name: "Waiting for Stain Number (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 289,
        material_id: 9,
        name: "Standard Clearcoat 5 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 359,
        material_id: 9,
        name: "American Cherry: FC-15928",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 770,
        material_id: 9,
        name: "Waiting for Stain Match (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 0,
        material_id: 12,
        name: "No Finish",
        finish_multiplier: 1,
        finish_operator: FinishOperatorEnum.SUBTRACT,
    },
    {
        id: 1,
        material_id: 12,
        name: "STANDARD Clearcoat 30 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 244,
        material_id: 12,
        name: "Waiting for Stain Number (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 289,
        material_id: 12,
        name: "Standard Clearcoat 5 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 770,
        material_id: 12,
        name: "Waiting for Stain Match (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 0,
        material_id: 15,
        name: "No Finish",
        finish_multiplier: 1,
        finish_operator: FinishOperatorEnum.SUBTRACT,
    },
    {
        id: 1,
        material_id: 15,
        name: "STANDARD Clearcoat 30 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 244,
        material_id: 15,
        name: "Waiting for Stain Number (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 289,
        material_id: 15,
        name: "Standard Clearcoat 5 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 770,
        material_id: 15,
        name: "Waiting for Stain Match (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 0,
        material_id: 16,
        name: "No Finish",
        finish_multiplier: 1,
        finish_operator: FinishOperatorEnum.SUBTRACT,
    },
    {
        id: 1,
        material_id: 16,
        name: "STANDARD Clearcoat 30 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 244,
        material_id: 16,
        name: "Waiting for Stain Number (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 770,
        material_id: 16,
        name: "Waiting for Stain Match (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 0,
        material_id: 17,
        name: "No Finish",
        finish_multiplier: 1,
        finish_operator: FinishOperatorEnum.SUBTRACT,
    },
    {
        id: 1,
        material_id: 17,
        name: "STANDARD Clearcoat 30 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 244,
        material_id: 17,
        name: "Waiting for Stain Number (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 289,
        material_id: 17,
        name: "Standard Clearcoat 5 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 770,
        material_id: 17,
        name: "Waiting for Stain Match (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 0,
        material_id: 26,
        name: "No Finish",
        finish_multiplier: 1,
        finish_operator: FinishOperatorEnum.SUBTRACT,
    },
    {
        id: 1,
        material_id: 26,
        name: "STANDARD Clearcoat 30 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 244,
        material_id: 26,
        name: "Waiting for Stain Number (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 289,
        material_id: 26,
        name: "Standard Clearcoat 5 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 770,
        material_id: 26,
        name: "Waiting for Stain Match (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 0,
        material_id: 42,
        name: "No Finish",
        finish_multiplier: 1,
        finish_operator: FinishOperatorEnum.SUBTRACT,
    },
    {
        id: 1,
        material_id: 42,
        name: "STANDARD Clearcoat 30 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 212,
        material_id: 42,
        name: "PRIME box only",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 244,
        material_id: 42,
        name: "Waiting for Stain Number (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 259,
        material_id: 42,
        name: "Soft Maple: JV-0005",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 260,
        material_id: 42,
        name: "Soft Maple: FC-14894",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 261,
        material_id: 42,
        name: "Soft Maple: FC-14606",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 262,
        material_id: 42,
        name: "Soft Maple: FC-12353",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 289,
        material_id: 42,
        name: "Standard Clearcoat 5 Sheen",
        finish_multiplier: 0,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 751,
        material_id: 42,
        name: "Soft Maple: Antique Gray",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
    {
        id: 770,
        material_id: 42,
        name: "Waiting for Stain Match (DO NOT RELEASE ORDER)",
        finish_multiplier: 0.5,
        finish_operator: FinishOperatorEnum.ADD,
    },
];

interface MaterialSize {
    id: number;
    name: string;
}

export const materialSizes: MaterialSize[] = [
    {
        id: 3,
        name: "5/4",
    },
    {
        id: 4,
        name: "6/4",
    },
    {
        id: 5,
        name: "8/4",
    },
    {
        id: 38,
        name: "WOOD OFF-ALL",
    },
    {
        id: 46,
        name: "4/4",
    },
];
