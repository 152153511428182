import { materials, finishOptions, FinishOperatorEnum, materialSizes } from "../../Global/Constants/Materials";
import { replacementTread, thickTread } from "../../Global/Constants/Pricing";
import { GeneratorEnum } from "../../Context/OrderContext";
import { ENDPOINT } from "../../Global/Constants/environment";
import axios from "axios";
import { FlightTread } from "../Flight/FlightTypes";
import { ReturnedTread } from "./MultiTreadForm";
import { GeneratedStatusEnum } from "Global/Constants/itemTypes";

export function processReturnedTread(data: any): ReturnedTread {
    const orderNumberParam = data.orderNumber.replace(/"/g, "", "");
    const treadTypeParam = data.treadType.replace(/"/g, "", "''");
    const generatorParam = GeneratorEnum.MultiTreads;
    /*  data.treadType.replace(/"/g, "", "''") === GeneratorEnum.ReplacementTread
      ? GeneratorEnum.ReplacementTread
      : GeneratorEnum.ThickTread;*/
    const uuidParam = data.uuid.replace(/"/g, "", "");
    const widthParam = parseFloat(data.treadWidth);
    const depthParam = parseFloat(data.treadDepth);
    const thicknessParam = parseFloat(data.treadThickness);
    const returnRightParam = data.returnRight === "True";
    const returnLeftParam = data.returnLeft === "True";
    const returnLengthLeftParam = parseFloat(data.returnLengthLeft);
    const returnLengthRightParam = parseFloat(data.returnLengthRight);
    const roundOverLeftParam = data.roundOverLeft === "True";
    const roundOverRightParam = data.roundOverRight === "True";
    const roundOverFrontParam = data.roundOverFront === "True";
    const roundOverBack = data.roundOverBack;
    const nosingDepthParam = parseFloat(data.nosingDepth);
    const nosingThicknessParam = parseFloat(data.nosingThickness);
    const roundOverParam = parseFloat(data.roundOver);
    const woodSpeciesParam = data.woodSpecies.replace(/"/g, "", "");
    const finishOptionParam = data.finishOption.replace(/"/g, "", "");
    const boardFeetParam = parseFloat(data.boardFeet);
    const ripParam = parseFloat(data.rip);
    const chopParam = parseFloat(data.chop);
    const planeParam = parseFloat(data.plane);
    const chopQtyParam = parseInt(data.chopQty);
    const pdfUrlParam = data.pdfUrl;
    const bunkParam = data.bunk.replace(/"/g, "", "");
    const wireBrushed = data.wireBrushed;
    /*const glueTypeParam = data.glueType
    ? data.glueType.replace(/"/g, "", "")
    : "";*/
    const bullnoseParam = data.bullnose && data.bullnose === "True";
    const finalWidth = parseFloat(data.finalWidth);
    const finalDepth = parseFloat(data.finalDepth);
    const finalThickness = parseFloat(data.finalThickness);

    let tread: Omit<ReturnedTread, "price"> = {
        generatedStatus: GeneratedStatusEnum.Generated,
        name: `Custom ${treadTypeParam.replace(
            /Tread/g,
            "",
            ""
        )} Tread (${widthParam} x ${depthParam} x ${thicknessParam})`,
        orderNumber: orderNumberParam,
        generator: generatorParam,
        uuid: uuidParam,
        treadWidth: widthParam,
        treadDepth: depthParam,
        treadThickness: thicknessParam,
        returnRight: returnRightParam,
        returnLeft: returnLeftParam,
        returnLengthLeft: returnLengthLeftParam,
        returnLengthRight: returnLengthRightParam,
        roundOverLeft: roundOverLeftParam,
        roundOverRight: roundOverRightParam,
        roundOverFront: roundOverFrontParam,
        roundOverBack: roundOverBack,
        nosingDepth: nosingDepthParam,
        nosingThickness: nosingThicknessParam,
        roundOver: roundOverParam,
        woodSpecies: woodSpeciesParam,
        finishOption: finishOptionParam,
        distressed: false,
        handScraped: false,
        wireBrushed,
        boardFeet: boardFeetParam,
        rip: ripParam,
        chop: chopParam,
        plane: planeParam,
        chopQty: chopQtyParam,
        pdfUrl: pdfUrlParam,
        bunk: bunkParam,
        treadType: treadTypeParam,
        bullnose: bullnoseParam,
        finalWidth: finalWidth,
        finalDepth: finalDepth,
        finalThickness: finalThickness,
        quantity: 1,
    };

    const priceParam = calculateTreadPrice(tread);
    return Object.assign(tread, { price: priceParam });
}

//TODO: add pricing for landing
export function calculateTreadPrice(tread: Omit<ReturnedTread, "price"> | FlightTread) {
    let material = materials.find((material) => material.name === tread.woodSpecies);
    let finishOption = finishOptions.find((option) => option.name === tread.finishOption);

    let depth = tread.treadDepth;
    let width = tread.treadWidth;
    let thickness = tread.treadThickness;
    let board_feet = 0;
    let labor_total = 0;
    let finish_price = 0;
    let wood_total = 0;
    let returns_total = 0;
    let returnsCount = [tread.returnLeft, tread.returnRight].filter((item) => item === true).length;

    switch (tread.treadType) {
        case "ThickTread":
            depth += thickTread.depth_addon;
            width += thickTread.width_addon;
            thickness += thickTread.thickness_addon;

            board_feet = (depth * width * thickness) / 144;

            if (board_feet < thickTread.board_foot_limit) {
                labor_total = thickTread.low_labor;
                finish_price = thickTread.low_finish;
            } else {
                labor_total = thickTread.high_labor;
                finish_price = thickTread.high_finish;
            }

            returns_total = returnsCount * thickTread.each_return;
            wood_total = material ? material.pricing.ThickTread * board_feet : 0;
            break;

        case "ReplacementTread":
            depth += replacementTread.depth_addon;
            width += replacementTread.width_addon;
            thickness += replacementTread.thickness_addon;
            if (thickness < 1) {
                thickness = 1;
            }

            let bullnose_bf = 0;
            let main_bf = 0;
            let original_thickness = tread.treadThickness;
            let bn_depth = tread.nosingDepth + replacementTread.bullnose_depth_addon;
            let bn_thickness = tread.nosingThickness + replacementTread.bullnose_thickness_addon - original_thickness;

            main_bf = depth * width * thickness;
            bullnose_bf = bn_depth * bn_thickness * width;
            board_feet = (main_bf + bullnose_bf) / 144;

            if (board_feet < replacementTread.board_foot_limit) {
                labor_total = replacementTread.low_labor;
                finish_price = replacementTread.low_finish;
            } else {
                labor_total = replacementTread.high_labor;
                finish_price = replacementTread.high_finish;
            }

            returns_total = returnsCount * replacementTread.each_return;
            wood_total = material ? material.pricing.Replacement * board_feet : 0;

            break;
    }

    if (!material || !finishOption) return 0.0;

    let finish_total = 0;

    if (finishOption.finish_operator === FinishOperatorEnum.ADD) {
        finish_total = finish_price + finish_price * finishOption.finish_multiplier;
    } else {
        finish_total = finish_price - finish_price * finishOption.finish_multiplier;
    }

    //no custom features pricing yet
    let custom_features_price = 0;

    let total_cost = wood_total + labor_total + finish_total + returns_total + custom_features_price;
    return total_cost * 1.15;
}

export async function formatTreadForOD1(returnedTread: ReturnedTread) {
    let productID: number;
    if (returnedTread.generator === GeneratorEnum.MultiTreads) {
        productID = 403714;
    } else if (returnedTread.treadThickness > 1) {
        productID = 403753;
    } else {
        productID = 403752;
    }
    let material = materials.find((material) => material.name === returnedTread.woodSpecies);

    let finishOption = finishOptions
        .filter((option) => option.material_id === material?.id)
        .find((option) => option.name === returnedTread.finishOption);

    let materialSize = materialSizes.find((size) => size.name === returnedTread.bunk);

    let pdfUrl = await updateUrl(returnedTread.orderNumber, returnedTread.uuid);

    return {
        ProductID: productID,
        Quantity: returnedTread.quantity,
        MaterialID: material?.id,
        MaterialSizeID: materialSize?.id,
        FinishOptionID: finishOption?.id === 0 ? "" : finishOption?.id,
        filename: "Tread Print.pdf",
        fileURL: pdfUrl,
        Price: returnedTread.price.toFixed(2),
        Description: `Width: ${returnedTread.treadWidth} \n Depth: ${returnedTread.treadDepth} \n Thickness: ${returnedTread.treadThickness}`,
        BoardFootage: returnedTread.boardFeet,
        WoodDimRipWidth1: returnedTread.rip,
        WoodDimPlaneThickness1: returnedTread.plane,
        WoodDimPRCChop1: returnedTread.chop,
        WoodDimChopQuantity1: returnedTread.chopQty,
        WoodDimFinalLength1: returnedTread.finalDepth,
        WoodDimFinalWidth1: returnedTread.finalWidth,
        WoodDimFinalHeight1: returnedTread.finalThickness,
        WoodDimGlueType1: "",
    };
}

const updateUrl = async (orderNumber: string, uuid: string) => {
    const data = {
        orderNumber: orderNumber,
        fileName: `${uuid}.pdf`,
        generator: "treadGenerator",
    };
    //get url
    let response = await axios.post(ENDPOINT + "/forge/s3Url", data);
    return response.data.url;
};
export function getFinishOptions(material_name: string) {
    if (!material_name) {
        return [];
    }
    let material_id = materials.find((material) => material.name === material_name)?.id;

    if (!material_id) {
        return [];
    }

    let options = finishOptions.filter((option) => option.material_id === material_id);

    return options.map((option) => {
        return { label: option.name, value: option.name };
    });
}
