import { DelegatorProps } from "./index";
import { LevelRunValues } from "Global/Constants/itemTypes";
import { GeneratorEnum } from "Context/OrderContext";

const deleteLevelRun = ({ order, item, dispatch }: DelegatorProps) => {
    const newOrderPosts = { ...order.posts };
    const newOrderLabels = { ...order.labels };
    const termPostIDs = item.layout.startPostIDs.concat(item.layout.endPostIDs) as Array<string>;
    const savePosts = [] as Array<string>;
    const saveLabels = [] as Array<string>;

    order.items.forEach((i: any) => {
        if (i.uuid !== item.uuid && i.layout && i.layout.postIDs) {
            termPostIDs.some((id) => {
                if (i.layout.postIDs.includes(id)) {
                    const type = GeneratorEnum.LevelRun === i.generator ? "A" : "B Down";

                    savePosts.push(id);
                    newOrderPosts[id].type = type;
                    newOrderPosts[id].itemIDs = [i.uuid, false];
                    const labelID = newOrderPosts[id].labelID;
                    if (labelID) {
                        saveLabels.push(labelID);

                        newOrderLabels[labelID].text = type;
                    }

                    return true;
                }
            });
        }
    });

    // delete posts
    item.layout.postIDs.forEach((id: string) => {
        if (savePosts.includes(id) || !newOrderPosts[id]) return;

        if (newOrderPosts[id].labelID) {
            // @ts-ignore how can TS be this dumb, clearly not false
            delete newOrderLabels[newOrderPosts[id].labelID];
        }

        delete newOrderPosts[id];
    });

    // delete link data
    const links = order.links.filter((link) => link.from !== item.uuid && link.to !== item.uuid);
    dispatch({ type: "UpdateLinks", links });

    dispatch({ type: "RemoveOrderItem", uuid: item.uuid });
    dispatch({ type: "UpdateOrderPosts", posts: newOrderPosts });
    dispatch({ type: "UpdateOrderLabels", labels: newOrderLabels });
};

export default deleteLevelRun;
