import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";

interface MultiTreadFormChildren {
    roundOverOptions: Array<{ label: string; value: number }>;
}

interface MultiTreadFormLogicProps {
    children: (props: MultiTreadFormChildren) => React.ReactNode;
}

function MultiTreadFormLogic(props: MultiTreadFormLogicProps) {
    const { watch, setValue } = useFormContext();
    const watchFields = watch();

    const [roundOverOptions, setRoundoverOptions] = useState([
        { label: "Square Edges", value: 0 },
        { label: '1/8" Roundover', value: 0.125 },
        { label: '1/4" Roundover', value: 0.25 },
        { label: "Bullnose", value: 0.5 },
    ]);

    //RoundOver Right & Left
    useEffect(() => {
        if (watchFields.returnLeft) {
            setValue("roundOverLeft", true);
        } else {
            setValue("returnLengthLeft", 0);
        }
        if (watchFields.returnRight) {
            setValue("roundOverRight", true);
        } else {
            setValue("returnLengthRight", 0);
        }
    }, [watchFields.returnLeft, watchFields.returnRight, setValue]);

    //Fix for roundover options normal/thick
    useEffect(() => {
        if (watchFields.treadType === "Tread") {
            console.log(watchFields.treadThickness);
            if (watchFields.treadThickness === 1.0) {
                setRoundoverOptions((prevOptions) => [
                    { label: "Square Edges", value: 0 },
                    { label: '1/8" Roundover', value: 0.125 },
                    { label: '1/4" Roundover', value: 0.25 },
                    { label: "Bullnose", value: 0.5 },
                ]);
            } else if (watchFields.treadThickness === 0.75) {
                setRoundoverOptions((prevOptions) => [
                    { label: "Square Edges", value: 0 },
                    { label: '1/8" Roundover', value: 0.125 },
                    { label: '1/4" Roundover', value: 0.25 },
                ]);
            } else {
                setRoundoverOptions((prevOptions) => [
                    { label: "Square Edges", value: 0 },
                    { label: '1/8" Roundover', value: 0.125 },
                    { label: '1/4" Roundover', value: 0.25 },
                ]);
            }
        }
    }, [watchFields.treadThickness, watchFields.treadType]);

    //fix for roundover options on replacement
    useEffect(() => {
        if (watchFields.treadType === "Replacement Tread") {
            if (watchFields.nosingThickness === 1) {
                setRoundoverOptions((prevOptions) => [
                    { label: "Square Edges", value: 0 },
                    { label: '1/8" Roundover', value: 0.125 },
                    { label: '1/4" Roundover', value: 0.25 },
                    { label: "Bullnose", value: 0.5 },
                ]);
            } else if (watchFields.nosingThickness === 0.75) {
                setRoundoverOptions((prevOptions) => [
                    { label: "Square Edges", value: 0 },
                    { label: '1/8" Roundover', value: 0.125 },
                    { label: '1/4" Roundover', value: 0.25 },
                ]);
            } else {
                setRoundoverOptions((prevOptions) => [
                    { label: "Square Edges", value: 0 },
                    { label: '1/8" Roundover', value: 0.125 },
                    { label: '1/4" Roundover', value: 0.25 },
                ]);
            }
        }
    }, [watchFields.treadType, watchFields.nosingThickness]);

    useEffect(() => {
        if (
            watchFields.treadType === "Replacement Tread" ||
            watchFields.returnRight ||
            watchFields.returnLeft ||
            watchFields.treadThickness <= 1
        ) {
            setValue("roundOverBack", false);
        }
    }, [watchFields.treadType, watchFields.returnRight, watchFields.returnLeft, watchFields.treadThickness]); //eslint-disable-line

    // Finish Options **********************************
    useEffect(() => {
        setValue("finishOption", "No Finish");
    }, [watchFields.woodSpecies, setValue]);

    return <>{props.children({ roundOverOptions: roundOverOptions })}</>;
}

export default MultiTreadFormLogic;
