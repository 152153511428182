import React from "react";
import { OutlinedTextFieldProps, TextField, Tooltip } from "@material-ui/core";
import { useFormContext } from "react-hook-form";

function FormTextField(props: FormTextFieldProps) {
    const { variant = "outlined", tooltip, ...other } = props;
    const { register, onChange = undefined, setValue } = useFormContext() as UseFormMethodsWithOnBlur;

    const field = (
        <TextField
            variant={variant}
            inputRef={register({
                valueAsNumber: props.type === "number" ? true : false,
            })}
            fullWidth
            size="small"
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                if (props.type !== "number") return;

                const el = e.target as HTMLInputElement;
                let value = +el.value;

                // ! ! ! this screws with the "outlined" TextField variant, but since we're replacing
                // material-ui i figured that'll be resolved when that happens
                if (el.min && value < +el.min) setValue(el.name, el.min);
                if (el.max && value > +el.max) setValue(el.name, el.max);
            }}
            onChange={
                !onChange
                    ? undefined
                    : (e: React.ChangeEvent<HTMLInputElement>) => {
                          const el = e.target as HTMLInputElement;
                          const name = el.name;
                          let value = el.value as string | number;

                          if (props.type === "number") {
                              value = +value;
                              if (el.min) value = Math.max(+el.min, value);
                              if (el.max) value = Math.min(+el.max, value);
                          }

                          onChange({ name, value });
                      }
            }
            {...other}
        />
    );

    return tooltip ? (
        <Tooltip title={tooltip} placement="top">
            {field}
        </Tooltip>
    ) : (
        field
    );
}

interface FormTextFieldProps extends OutlinedTextFieldProps {
    tooltip?: string;
}
export default FormTextField;
