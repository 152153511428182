import React, { useState, useContext } from "react";

const ModalContext = React.createContext({} as any);
ModalContext.displayName = "ModalContext";
const ModalProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const [isOpen, setOpen] = useState(false);

    const open = () => setOpen(true);
    const close = () => setOpen(false);

    return (
        <ModalContext.Provider value={{ isOpen, open, close }}>
            <div id="modal-container"></div>
            {children}
        </ModalContext.Provider>
    );
};
const useModal = () => useContext(ModalContext);

export { ModalProvider, useModal };
