import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

interface S4SFormLogicProps {
    children?: (test: string) => React.ReactNode;
}
function S4SFormLogic(props: S4SFormLogicProps) {
    const { watch } = useFormContext();
    const [test, setTest] = useState("test");

    const watchFields = watch(["woodSpecies"]);

    useEffect(() => {
        console.log(watchFields.woodSpecies);
    }, [watchFields.woodSpecies]);

    return <>{props.children && props.children(test)}</>;
}

export default S4SFormLogic;
