import { useState, useEffect } from "react";
import { ReturnedItem } from "Context/OrderContext";
import { FlightCheck } from "Pages/Flight/FlightTypes";
import { processReturnedItem } from "Global/Helpers/ProcessGenerator";
import { processFlightCheck } from "Pages/Flight/FlightHelpers";
import {
    initiateSocket,
    socket,
    subscribeToGenerator,
    subscribeToSvf,
    disconnectSocket,
    subscribeToFlightCheck,
} from "Global/Helpers/SocketIO";

/**
 * Houses a socket connection to the forge generators
 *
 * @param {function} onSubmitSuccess fires on the callback of a successful generator submission
 */
const useGeneratorSocket = (onSubmitSuccess?: (item: ReturnedItem) => void) => {
    const [returnedItem, setReturnedItem] = useState<ReturnedItem | undefined>(undefined);
    const [flightCheck, setFlightCheck] = useState<FlightCheck | undefined>(undefined);
    const [selectedId, setSelectedId] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [connectedSocket, setConnectedSocket] = useState<SocketIOClient.Socket>();

    useEffect(() => {
        //connect to socket
        initiateSocket();
        //get socket connection data
        setConnectedSocket(socket);

        //subscribe to socket results
        subscribeToGenerator((err, msg) => {
            if (err) {
                console.log(err);
            } else {
                console.log(msg);
                processReturnedItem(msg).then((returnedItem) => {
                    setReturnedItem(returnedItem);
                    setLoading(false);
                    if (onSubmitSuccess && returnedItem) onSubmitSuccess(returnedItem);
                });
                // let returnedItem = processReturnedItem(msg);
                // console.log(returnedItem);
                // setReturnedItem(returnedItem);
                // setLoading(false);
                // if (onSubmitSuccess && returnedItem) onSubmitSuccess(returnedItem);
            }
        });
        subscribeToSvf((err, msg) => {
            if (err) {
                console.log(err);
            } else {
                setSelectedId(null);
                setSelectedId(`${msg.uuid}.json`);
                setLoading(false);
            }
        });
        subscribeToFlightCheck((err, msg) => {
            if (err) {
                console.log(err);
            } else {
                let returnedCheck = processFlightCheck(msg);
                setFlightCheck(returnedCheck);
                console.log(returnedCheck);
                setLoading(false);
            }
        });

        return () => {
            disconnectSocket();
        };
    }, []); //eslint-disable-line

    return {
        loading,
        setLoading,
        connectedSocket,
        flightCheck,
        returnedItem,
        setReturnedItem,
        selectedId,
        setSelectedId,
    };
};

export default useGeneratorSocket;
