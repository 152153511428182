import { ReturnedS4S } from "./S4SForm";
import { materials, finishOptions, FinishOperatorEnum, materialSizes } from "Global/Constants/Materials";
import { s4sAddon } from "Global/Constants/Pricing";
import { ENDPOINT } from "Global/Constants/environment";
import axios from "axios";
import { GeneratedStatusEnum } from "Global/Constants/itemTypes";

export function processReturnedS4S(data: any): ReturnedS4S {
    const orderNumber = data.orderNumber;
    const generator = data.generator;
    const uuid = data.uuid;
    const width = data.width;
    const depth = data.depth;
    const thickness = data.thickness;
    const woodSpecies = data.woodSpecies;
    const finishOption = data.finishOption;
    const distressed = data.distressed;
    const handScraped = data.handScraped;
    const wireBrushed = data.wireBrushed;
    const finalWidth = data.finalWidth;
    const finalDepth = data.finalDepth;
    const finalThickness = data.finalThickness;
    const boardFeet = data.boardFeet;
    const rip = data.rip;
    const chop = data.chop;
    const plane = data.plane;
    const bunk = data.bunk;
    const chopQty = data.chopQty;
    const pdfUrl = data.pdfUrl;

    let s4s: Omit<ReturnedS4S, "price"> = {
        generatedStatus: GeneratedStatusEnum.Generated,
        name: `Custom S4S (${width} x ${depth} x ${thickness})`,
        orderNumber,
        generator,
        uuid,
        width,
        depth,
        thickness,
        woodSpecies,
        finishOption,
        distressed,
        handScraped,
        wireBrushed,
        finalWidth,
        finalDepth,
        finalThickness,
        boardFeet,
        rip,
        chop,
        plane,
        bunk,
        chopQty,
        pdfUrl,
        quantity: 1,
    };

    const price = calculateS4SPrice(s4s);
    return Object.assign(s4s, { price: price });
}

export function calculateS4SPrice(s4s: Omit<ReturnedS4S, "price">) {
    let material = materials.find((material) => material.name === s4s.woodSpecies);
    let finishOption = finishOptions.find((option) => option.name === s4s.finishOption);

    if (!material || !finishOption) return 0.0;

    let depth = s4s.depth;
    let width = s4s.width;
    let thickness = s4s.thickness;
    let board_feet = 0;
    let labor_total = 0;
    let finish_price = 0;
    let wood_total = 0;

    depth += s4sAddon.depth_addon;
    width += s4sAddon.width_addon;
    thickness += s4sAddon.thickness_addon;

    if (thickness < 1) {
        thickness = 1;
    }

    board_feet = (depth * width * thickness) / 144;

    if (board_feet < s4sAddon.board_foot_limit) {
        labor_total = s4sAddon.low_labor;
        finish_price = s4sAddon.low_finish;
    } else {
        labor_total = s4sAddon.high_labor;
        finish_price = s4sAddon.high_finish;
    }

    wood_total = material ? material.pricing.S4S * board_feet : 0;

    let finish_total = 0;

    if (finishOption.finish_operator === FinishOperatorEnum.ADD) {
        finish_total = finish_price + finish_price * finishOption.finish_multiplier;
    } else {
        finish_total = finish_price - finish_price * finishOption.finish_multiplier;
    }

    let custom_features_price = 0;
    if (s4s.distressed) {
        custom_features_price += s4sAddon.distressed;
    }
    if (s4s.handScraped) {
        custom_features_price += s4sAddon.hand_scraped;
    }

    let total_cost = wood_total + labor_total + finish_total + custom_features_price;
    let materialIncrease = 1.1;
    if (material.id === 2) materialIncrease = 1.3;
    const nonWirebrushModifier = s4s.wireBrushed ? 1.0 : 1.0;
    const inflationIncrease = 1.03;
    return total_cost * 1.15 * materialIncrease * nonWirebrushModifier * inflationIncrease;
}

export async function formatS4SForOD1(s4s: ReturnedS4S) {
    const productID = 436710;

    let material = materials.find((material) => material.name === s4s.woodSpecies);

    let finishOption = finishOptions
        .filter((option) => option.material_id === material?.id)
        .find((option) => option.name === s4s.finishOption);

    let materialSize = materialSizes.find((size) => size.name === s4s.bunk);

    let pdfUrl = await updateUrl(s4s.orderNumber, s4s.uuid);

    return {
        ProductID: productID,
        Quantity: s4s.quantity,
        MaterialID: material?.id,
        MaterialSizeID: materialSize?.id,
        FinishOptionID: finishOption?.id === 0 ? "" : finishOption?.id,
        filename: "Custom S4S.pdf",
        fileURL: pdfUrl,
        Price: s4s.price.toFixed(2),
        Description: "",
        InHouseNotes: s4s.name,
        BoardFootage: s4s.boardFeet,
        WoodDimRipWidth1: s4s.rip,
        WoodDimPlaneThickness1: s4s.plane,
        WoodDimPRCChop1: s4s.chop,
        WoodDimChopQuantity1: s4s.chopQty,
        WoodDimFinalLength1: s4s.finalDepth,
        WoodDimFinalWidth1: s4s.finalWidth,
        WoodDimFinalHeight1: s4s.finalThickness,
        WoodDimGlueType1: "",
    };
}
const updateUrl = async (orderNumber: string, uuid: string) => {
    const data = {
        orderNumber: orderNumber,
        fileName: `${uuid}.pdf`,
        generator: "treadGenerator",
    };
    //get url
    let response = await axios.post(ENDPOINT + "/forge/s3Url", data);
    return response.data.url;
};
