import io from "socket.io-client";
import { ENDPOINT } from "../Constants/environment";
import { FlightCheck } from "../../Pages/Flight/FlightTypes";

export let socket: SocketIOClient.Socket;

export const initiateSocket = () => {
    socket = io(ENDPOINT);
    console.log(`Connecting socket...`);
};

export const disconnectSocket = () => {
    console.log("Disconnecting socket...");
    if (socket) socket.disconnect();
};

export const subscribeToGenerator = (cb: (err: string | null, msg: string) => void) => {
    if (!socket) return true;
    socket.on("downloadResult", (msg: string) => {
        console.log("Websocket event received!");
        return cb(null, msg);
    });
};

export const subscribeToSvf = (cb: (err: string | null, msg: { uuid: string }) => void) => {
    if (!socket) return true;
    socket.on("viewerReady", (msg: { uuid: string }) => {
        console.log("Websocket event received!");
        return cb(null, msg);
    });
};

export const subscribeToFlightCheck = (cb: (err: string | null, msg: FlightCheck) => void) => {
    if (!socket) return true;
    socket.on("flightCheck", (msg: FlightCheck) => {
        return cb(null, msg);
    });
};
