import { WarehouseItem } from ".";
import { OD1_Item } from "Global/Constants/OD1_Types";

export function formatWarehouseItemForOd1(warehouseItem: WarehouseItem): OD1_Item {
    return {
        ProductID: warehouseItem.productID,
        Quantity: warehouseItem.quantity,
        Description: warehouseItem.notes,
        FinishOptionID: warehouseItem.finishOptionID,
        fileURL: "",
    };
}
