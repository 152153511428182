import { ReturnedTread } from "./MultiTreadForm";
import { materials, finishOptions, FinishOperatorEnum, materialSizes } from "Global/Constants/Materials";
import { thickTread, replacementTread, treadAddon } from "Global/Constants/Pricing";
import { ENDPOINT } from "Global/Constants/environment";
import axios from "axios";
import { OD1_Item } from "Global/Constants/OD1_Types";
import { GeneratedStatusEnum } from "Global/Constants/itemTypes";

// Data processing *********************
export function processReturnedTread(data: any): ReturnedTread {
    const orderNumberParam = data.orderNumber;
    const generatorParam = data.generator;
    const uuidParam = data.uuid;
    const treadType = data.treadType;
    const treadWidth = data.treadWidth;
    const treadDepth = data.treadDepth;
    const treadThickness = data.treadThickness;
    const returnRight = data.returnRight;
    const returnLeft = data.returnLeft;
    const returnLengthLeft = data.returnLengthLeft;
    const returnLengthRight = data.returnLengthRight;
    const nosingDepth = data.nosingDepth;
    const nosingThickness = data.nosingThickness;
    const roundOverLeft = data.roundOverLeft;
    const roundOverRight = data.nosingRight;
    const roundOverFront = data.nosingFront;
    const roundOverBack = data.roundOverBack;
    const roundOver = data.roundOver;
    const woodSpecies = data.woodSpecies;
    const finishOption = data.finishOption;
    const distressed = data.distressed;
    const handScraped = data.handScraped;
    const wireBrushed = data.wireBrushed;

    const finalWidth = parseFloat(data.finalWidth);
    const finalDepth = parseFloat(data.finalDepth);
    const finalThickness = parseFloat(data.finalThickness);
    const boardFeet = data.boardFeet;
    const rip = data.rip;
    const chop = data.chop;
    const plane = data.plane;
    const bunk = data.bunk;
    const chopQty = data.chopQty;
    const pdfUrl = data.pdfUrl;
    const bullnose = data.bullnose && data.bullnose === "True";

    let typeName = data.treadType === "Tread" ? "Normal/Thick" : "Replacement";
    let tread = {
        generatedStatus: GeneratedStatusEnum.Generated,
        name: `Custom ${typeName} Tread (${treadWidth} x ${treadDepth} x ${treadThickness})`,
        orderNumber: orderNumberParam,
        generator: generatorParam,
        uuid: uuidParam,
        treadType: treadType,
        treadWidth: treadWidth,
        treadDepth: treadDepth,
        treadThickness: treadThickness,
        returnLeft: returnLeft,
        returnRight: returnRight,
        returnLengthLeft: returnLengthLeft,
        returnLengthRight: returnLengthRight,
        nosingDepth: nosingDepth,
        nosingThickness: nosingThickness,
        roundOverLeft: roundOverLeft,
        roundOverRight: roundOverRight,
        roundOverFront: roundOverFront,
        roundOverBack: roundOverBack,
        roundOver: roundOver,
        woodSpecies: woodSpecies,
        finishOption: finishOption,
        distressed: distressed,
        handScraped: handScraped,
        wireBrushed: wireBrushed,
        boardFeet: boardFeet,
        rip: rip,
        chop: chop,
        plane: plane,
        chopQty: chopQty,
        bunk: bunk,
        pdfUrl: pdfUrl,
        finalWidth: finalWidth,
        finalDepth: finalDepth,
        finalThickness: finalThickness,
        quantity: 1,
        bullnose: bullnose,
    };

    const price = calculateTreadPrice(tread);

    return Object.assign(tread, { price: price });
}

export function mapTreadToInitialValues(item: ReturnedTread) {
    return {
        generator: item.generator,
        orderNumber: item.orderNumber,
        uuid: item.uuid,
        treadType: item.treadType,
        treadWidth: item.treadWidth,
        treadDepth: item.treadDepth,
        treadThickness: item.treadThickness,
        returnLeft: item.returnLeft,
        returnRight: item.returnRight,
        returnLengthRight: item.returnLengthRight,
        returnLengthLeft: item.returnLengthLeft,
        nosingDepth: item.nosingDepth,
        nosingThickness: item.nosingThickness,
        roundOverRight: item.roundOverRight,
        roundOverLeft: item.roundOverLeft,
        roundOverFront: item.roundOverFront,
        roundOverBack: item.roundOverBack,
        roundOver: item.roundOver,
        woodSpecies: item.woodSpecies,
        finishOption: item.finishOption,
        layout: item.layout,
    };
}

export function calculateTreadPrice(tread: Omit<ReturnedTread, "price">) {
    let material = materials.find((material) => material.name === tread.woodSpecies);
    let finishOption = finishOptions.find((option) => option.name === tread.finishOption);

    let depth = tread.treadDepth;
    let width = tread.treadWidth;
    let thickness = tread.treadThickness;
    let board_feet = 0;
    let labor_total = 0;
    let finish_price = 0;
    let wood_total = 0;
    let returns_total = 0;
    let returnsCount = [tread.returnLeft, tread.returnRight].filter((item) => item === true).length;

    let addon;
    let materialAddon;
    if (tread.treadType === "Tread") {
        if (tread.treadThickness > 1) {
            addon = thickTread;
            materialAddon = material ? material.pricing.ThickTread : 0;
        } else {
            addon = treadAddon;
            materialAddon = material ? material.pricing.Tread : 0;
        }
    } else {
        addon = replacementTread;
        materialAddon = material ? material.pricing.Replacement : 0;
    }

    switch (tread.treadType) {
        case "Tread":
            depth += addon.depth_addon;
            width += addon.width_addon;
            thickness += addon.thickness_addon;
            if (thickness < 1) {
                thickness = 1;
            }

            board_feet = (depth * width * thickness) / 144;

            if (board_feet < addon.board_foot_limit) {
                labor_total = addon.low_labor;
                finish_price = addon.low_finish;
            } else {
                labor_total = addon.high_labor;
                finish_price = addon.high_finish;
            }
            returns_total = returnsCount * addon.each_return;
            wood_total = materialAddon * board_feet;
            break;

        case "Replacement Tread":
            depth += addon.depth_addon;
            width += addon.width_addon;
            thickness += addon.thickness_addon;
            if (thickness < 1) {
                thickness = 1;
            }

            let bullnose_bf = 0;
            let main_bf = 0;
            let original_thickness = tread.treadThickness;
            let bn_depth = tread.nosingDepth + addon.bullnose_depth_addon;
            let bn_thickness = tread.nosingThickness + addon.bullnose_thickness_addon - original_thickness;

            main_bf = depth * width * thickness;
            bullnose_bf = bn_depth * bn_thickness * width;
            board_feet = (main_bf + bullnose_bf) / 144;

            if (board_feet < addon.board_foot_limit) {
                labor_total = addon.low_labor;
                finish_price = addon.low_finish;
            } else {
                labor_total = addon.high_labor;
                finish_price = addon.high_finish;
            }

            returns_total = returnsCount * addon.each_return;
            wood_total = materialAddon * board_feet;

            break;
    }

    if (!material || !finishOption) return 0.0;

    let finish_total = 0;

    if (finishOption.finish_operator === FinishOperatorEnum.ADD) {
        finish_total = finish_price + finish_price * finishOption.finish_multiplier;
    } else {
        finish_total = finish_price - finish_price * finishOption.finish_multiplier;
    }

    //no custom features pricing yet
    let custom_features_price = 0;

    if (tread.distressed) {
        custom_features_price += addon.distressed;
    }
    if (tread.handScraped) {
        custom_features_price += addon.hand_scraped;
    }
    let total_cost = wood_total + labor_total + finish_total + returns_total + custom_features_price;
    let materialIncrease = 1.1;
    if (material.id === 2) materialIncrease = 1.3;
    total_cost = total_cost * materialIncrease;

    const nonWirebrushModifier = tread.wireBrushed ? 1.0 : 1.0;
    const inflationIncrease = 1.03;
    return total_cost * 1.15 * nonWirebrushModifier * inflationIncrease;
}

export async function formatTreadForOD1(returnedTread: ReturnedTread): Promise<OD1_Item> {
    let productID: number;
    if (returnedTread.treadType === "Tread") {
        if (returnedTread.treadThickness > 1) {
            productID = 403753;
        } else {
            productID = 444816;
        }
    } else {
        productID = 403714;
    }
    let material = materials.find((material) => material.name === returnedTread.woodSpecies);

    let finishOption = finishOptions
        .filter((option) => option.material_id === material?.id)
        .find((option) => option.name === returnedTread.finishOption);

    let materialSize = materialSizes.find((size) => size.name === returnedTread.bunk);

    let pdfUrl = await updateUrl(returnedTread.orderNumber, returnedTread.uuid);

    return {
        ProductID: productID,
        Quantity: returnedTread.quantity,
        MaterialID: material?.id,
        MaterialSizeID: materialSize?.id,
        FinishOptionID: finishOption?.id === 0 ? "" : finishOption?.id,
        filename: "Tread Print.pdf",
        fileURL: pdfUrl,
        Price: returnedTread.price.toFixed(2),
        Description: `Custom Tread ${returnedTread.distressed ? "distressed" : ""} ${
            returnedTread.handScraped ? "Hand Scraped" : ""
        }`,
        InHouseNotes: returnedTread.name,
        BoardFootage: returnedTread.boardFeet,
        WoodDimRipWidth1: returnedTread.rip,
        WoodDimPlaneThickness1: returnedTread.plane,
        WoodDimPRCChop1: returnedTread.chop,
        WoodDimChopQuantity1: returnedTread.chopQty,
        WoodDimFinalLength1: returnedTread.finalDepth,
        WoodDimFinalWidth1: returnedTread.finalWidth,
        WoodDimFinalHeight1: returnedTread.finalThickness,
        WoodDimGlueType1: "",
    };
}

const updateUrl = async (orderNumber: string, uuid: string) => {
    const data = {
        orderNumber: orderNumber,
        fileName: `${uuid}.pdf`,
        generator: "treadGenerator",
    };
    //get url
    let response = await axios.post(ENDPOINT + "/forge/s3Url", data);
    return response.data.url;
};
export function getFinishOptions(material_name: string) {
    if (!material_name) {
        return [];
    }
    let material_id = materials.find((material) => material.name === material_name)?.id;

    if (!material_id) {
        return [];
    }

    let options = finishOptions.filter((option) => option.material_id === material_id);

    return options.map((option) => {
        return { label: option.name, value: option.name };
    });
}
