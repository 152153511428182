import { OD1_TOKEN, OD1_DEV } from "Global/Constants/environment";
import QueryString from "query-string";
import { FlightItem } from "./FlightTypes";
import { accurateRound } from "Global/Helpers/Math";
import { findPostInfillID } from "Global/Constants/Posts";
import { ReturnedFlight } from "./FlightForm";
import { ReturnedUFlight } from "Pages/UFlight/UFlightForm";
import { StraightReturnedFlight } from "./StraightFlightForm";
import { ReturnedSwitchbackFlight } from "./SwitchbackFlightForm";
import { ReturnedNinetyFlight } from "./NinetyFlightForm";
import { OD1_Item } from "Global/Constants/OD1_Types";
import { materials, finishOptions } from "Global/Constants/Materials";
import {
    getFlightTreadPrice,
    getFlightLandingPrice,
    getFlightNosingPrice,
} from "Global/Constants/FlightTreadsLandings";
import { findHandrailFinish, findHandrailMaterial } from "Global/Constants/Handrail";
import { findMetalMaterial, findMetalFinish } from "Global/Constants/MetalMaterials";

interface OD1_Product {
    ProductID: number;
    ProductName: string;
    ProductDescription: string;
    UnitPrice: number;
}

interface FlightParsingParams {
    bomString: string;
    postString: string;
    handrailQty: string;
    treadWidth?: number;
    treadWidthUpper?: number;
    treadWidthLower?: number;
    treadWidthMiddle?: number;
    treadDepth: number;
    treadThickness: number;
    treadMaterial: string;
    treadFinish: string;
    handrail: string;
    handrailHeight: number;
    handrailMaterial: string;
    handrailFinish: string;
    postInfill: string;
}

export async function parseFlightItems(params: FlightParsingParams): Promise<Array<FlightItem>> {
    const {
        bomString,
        postString,
        handrailQty,
        handrail,
        handrailHeight,
        handrailMaterial,
        handrailFinish,
        postInfill,
    } = params;

    let items: FlightItem[] = [];

    //
    // used for further parsing
    //
    let standardProductsStrings: Array<string> = [];
    let customProductStrings: Array<string> = [];

    //
    // Split strings into array
    //
    const bomArray = bomString.split("|");
    const postArray = postString.split("|");
    const handrailArray = handrailQty.split("|");

    //
    // Split bom array into standard and custom products
    //
    bomArray.forEach((item: string) => {
        if (item.includes(":")) {
            customProductStrings.push(item);
        } else {
            standardProductsStrings.push(item);
        }
    });

    //
    // Parse custom Products in the bom array
    //
    const customItems = await parseCustomProducts(customProductStrings, params);
    customItems.forEach((item) => items.push(item));

    //
    // Parse and apply standard items from bom array
    //
    const standardItems = await parseStandardProducts(standardProductsStrings);
    standardItems.forEach((item) => items.push(item));

    //
    // Parse post array
    //
    const postItems = await parsePosts(postString, postArray, postInfill, handrailHeight);
    postItems.forEach((post) => items.push(post));

    //
    // Parse handrail array
    //
    const handrailItems = await parseHandrail(handrailQty, handrailArray, handrail, handrailMaterial, handrailFinish);
    handrailItems.forEach((handrail) => items.push(handrail));

    return items;
}

function parseFlightTreads(treadWidth: number, woodSpecies: string, treadFinish: string) {
    let price = getFlightTreadPrice(treadWidth, woodSpecies, treadFinish);
    return price;
}
function parseFlightLandings(landingWidth: number, woodSpecies: string, landingFinish: string) {
    let price = getFlightLandingPrice(landingWidth, woodSpecies, landingFinish);
    return price;
}
function parseFlightNosing(nosingWidth: number, woodSpecies: string, nosingFinish: string) {
    let price = getFlightNosingPrice(nosingWidth, woodSpecies, nosingFinish);
    return price;
}

async function parseStandardProducts(standardProductsStrings: Array<string>): Promise<Array<FlightItem>> {
    let standardProducts: Array<{ productID: string; quantity: string }> = [];
    let items: Array<FlightItem> = [];

    standardProductsStrings.forEach((item) => {
        const product = item.split(",");
        standardProducts.push({ productID: product[1], quantity: product[0] });
    });

    const standardProductIds = standardProducts.map((product) => product.productID);
    const results = await getProducts(`ProductIDs=${standardProductIds.toString()}`);
    if (results) {
        if (results.products) {
            results.products.forEach((product: OD1_Product) => {
                let foundProduct = standardProducts.find((prod) => parseInt(prod.productID) === product.ProductID);

                if (foundProduct) {
                    items.push({
                        productId: product.ProductID,
                        name: product.ProductName,
                        description: product.ProductDescription,
                        quantity: parseInt(foundProduct.quantity),
                        price: product.UnitPrice,
                        type: "Hardware",
                    });
                }
            });
        }
    }
    return items;
}

async function parseCustomProducts(
    customProductStrings: Array<string>,
    params: FlightParsingParams
): Promise<Array<FlightItem>> {
    let items: Array<FlightItem> = [];
    let flightTreadPrice = 0;
    customProductStrings.forEach((item) => {
        // [qty, part#:name, length]
        const itemSplit = item.split(",");
        // [part#,name]
        const customSplit = itemSplit[1].split(":");

        switch (customSplit[0]) {
            case "329731":
                if (customSplit[1] === "Tread 1") {
                    if (params.treadWidthLower) {
                        flightTreadPrice = parseFlightTreads(
                            params.treadWidthLower,
                            params.treadMaterial,
                            params.treadFinish
                        );
                    } else if (params.treadWidth) {
                        flightTreadPrice = parseFlightTreads(
                            params.treadWidth,
                            params.treadMaterial,
                            params.treadFinish
                        );
                    }
                } else if (customSplit[1] === "Treads") {
                    if (params.treadWidth) {
                        flightTreadPrice = parseFlightTreads(
                            params.treadWidth,
                            params.treadMaterial,
                            params.treadFinish
                        );
                    }
                } else if (customSplit[1] === "Treads Lower") {
                    if (params.treadWidthLower)
                        flightTreadPrice = parseFlightTreads(
                            params.treadWidthLower,
                            params.treadMaterial,
                            params.treadFinish
                        );
                } else if (customSplit[1] === "Treads Upper") {
                    if (params.treadWidthUpper)
                        flightTreadPrice = parseFlightTreads(
                            params.treadWidthUpper,
                            params.treadMaterial,
                            params.treadFinish
                        );
                } else if (customSplit[1] === "Treads Middle") {
                    if (params.treadWidthMiddle)
                        flightTreadPrice = parseFlightTreads(
                            params.treadWidthMiddle,
                            params.treadMaterial,
                            params.treadFinish
                        );
                }
                items.push({
                    productId: parseInt(customSplit[0]),
                    quantity: parseInt(itemSplit[0]),
                    name: `Custom Flight ${customSplit[1]}`,
                    description: `Custom Flight ${customSplit[1]}`,
                    price: flightTreadPrice,
                    material: params.treadMaterial,
                    finishOption: params.treadFinish,
                    type: "Tread",
                });
                break;
            case "329730":
                items.push({
                    productId: parseInt(customSplit[0]),
                    quantity: parseInt(itemSplit[0]),
                    name: "Custom Flight Nosing",
                    description: "Custom Flight Nosing",
                    //TODO: how to handle nosing when there are multiple prices for treadWidth possible
                    price: parseFlightNosing(
                        params.treadWidthUpper ? params.treadWidthUpper : params.treadWidth ? params.treadWidth : 36,
                        params.treadMaterial,
                        params.treadFinish
                    ),
                    material: params.treadMaterial,
                    finishOption: params.treadFinish,
                    type: "Nosing",
                });
                break;
            case "329729":
                items.push({
                    productId: parseInt(customSplit[0]),
                    quantity: parseInt(itemSplit[0]),
                    name: "Flight Nosing Insert",
                    description: customSplit[1],
                    material: params.treadMaterial,
                    finishOption: params.treadFinish,
                    price: 17.31,
                    type: "Nosing Insert",
                });
                break;
            case "329728":
                // [width, depth, height]
                //1,329728:LL,38.25x39.625x4
                let landingDims = itemSplit[2].split("x");
                items.push({
                    productId: parseInt(customSplit[0]),
                    quantity: parseInt(itemSplit[0]),
                    name: "Custom Flight Landing Platform",
                    description: customSplit[1],
                    material: params.treadMaterial,
                    finishOption: params.treadFinish,
                    price: landingDims[0]
                        ? parseFlightLandings(parseFloat(landingDims[0]), params.treadMaterial, params.treadFinish)
                        : 0.0,
                    type: "Landing Platform",
                });
                break;
            case "329726":
                items.push({
                    productId: parseInt(customSplit[0]),
                    quantity: parseInt(itemSplit[0]),
                    name: "Custom Stringer",
                    description: customSplit[1],
                    price: accurateRound(calculateFlightStringerPrice(parseFloat(itemSplit[2])), 2),
                    type: "Stringer",
                });
                break;
            case "329727":
                items.push({
                    productId: parseInt(customSplit[0]),
                    quantity: parseInt(itemSplit[0]),
                    name: "Custom Stringer",
                    description: customSplit[1],
                    price: calculateFlightStringerPrice(parseFloat(itemSplit[2])),
                    type: "Stringer",
                });
                break;
        }
    });

    return items;
}

async function parsePosts(
    postString: string,
    postArray: Array<string>,
    postInfill: string,
    handrailHeight: number
): Promise<Array<FlightItem>> {
    let items: FlightItem[] = [];
    if (postString.length > 0) {
        for (let i = 0; i < postArray.length; i++) {
            // [post type, quantity]
            const postSplit = postArray[i].split(",");
            const postConfig = findPostInfillID(postInfill);

            const postObject = {
                MaterialID: 73,
                PostFootStyleID: 1,
                PostTopStyleID: 1,
                PostConfiguration: postSplit[0],
                PostSystemHeight: handrailHeight,
                ProductTypeGroupID: postConfig?.productTypeGroupID,
                ProductTypeID: postConfig?.productTypeID,
            };

            let searchParams = QueryString.stringify(postObject, { encode: false });
            let results = await getProducts(searchParams);

            if (results) {
                if (results.products && results.products.length > 0) {
                    let returnedPost = results.products[0];
                    if (postSplit[0] === "B Down") {
                        items.push({
                            productId: 416706,
                            name: `Custom Flight ${handrailHeight}" B-Down Post`,
                            description: `Custom Flight ${handrailHeight}" B-Down Post`,
                            quantity: parseInt(postSplit[1]),
                            price: returnedPost.UnitPrice,
                            material: "Aluminum",
                            type: "Custom Post",
                        });
                    } else {
                        items.push({
                            productId: returnedPost.ProductID,
                            name: returnedPost.ProductName,
                            description: returnedPost.ProductDescription,
                            quantity: parseInt(postSplit[1]),
                            price: returnedPost.UnitPrice,
                            material: "Aluminum",
                            type: "Standard Post",
                        });
                    }
                }
            }
        }
    }
    return items;
}
async function parseHandrail(
    handrailQty: string,
    handrailArray: Array<string>,
    handrail: string,
    handrailMaterial: string,
    handrailFinish: string
): Promise<Array<FlightItem>> {
    let items: Array<FlightItem> = [];

    if (handrailQty.length > 0) {
        for (let i = 0; i < handrailArray.length; i++) {
            // [quantity, length]
            let handrailSplit = handrailArray[i].split(",");
            let handrailType = handrail === "Aluminum" ? "metal" : "wood";
            let foundHandrailMaterial = findHandrailMaterial(handrailMaterial, handrailType);

            let handrailObject = {
                HandrailLength: parseFloat(handrailSplit[1]),
                HandrailProfile: handrail === "Aluminum" ? "1x2" : handrail,
                MaterialID: foundHandrailMaterial ? foundHandrailMaterial.id : "",
            };
            const isMetal = handrail === "Aluminum";

            let searchParams = QueryString.stringify(handrailObject, { encode: false });
            let results = await getProducts(searchParams);
            if (results) {
                if (results.products && results.products.length > 0) {
                    let returnedHandrail = results.products[0];
                    if (isMetal) {
                        results.products.forEach((product: OD1_Product) => {
                            if (product.ProductDescription.includes("One End Finished")) {
                                returnedHandrail = product;
                            }
                        });
                        let handrailPlugId = 149651;

                        let plugResults = await getProducts(`ProductIDs=${handrailPlugId}`);
                        if (plugResults) {
                            if (plugResults.products && plugResults.products.length > 0) {
                                items.push({
                                    productId: handrailPlugId,
                                    name: plugResults.products[0].ProductName,
                                    description: plugResults.products[0].Description,
                                    quantity: parseFloat(handrailSplit[0]),
                                    type: "Hardware",
                                    price: plugResults.products[0].UnitPrice,
                                });
                            }
                        }
                    }
                    items.push({
                        productId: returnedHandrail.ProductID,
                        name: returnedHandrail.ProductName,
                        description: returnedHandrail.ProductDescription,
                        quantity: parseInt(handrailSplit[0]),
                        price: returnedHandrail.UnitPrice,
                        material: handrailMaterial,
                        finishOption: handrailFinish,
                        type: "Handrail",
                    });
                }
            }
        }
    }
    return items;
}
async function getProducts(queryParams: string) {
    const config = {
        headers: {
            "X-api-auth": OD1_TOKEN,
        },
    };

    let response = await fetch(OD1_DEV + `/products_get_info.cfm?${queryParams}`, config);

    let results = await response.json();

    return results;
}

function calculateFlightStringerPrice(stringerLength: number) {
    const minimumStringerPrice = 1090.0;

    if (stringerLength > 15) {
        return minimumStringerPrice + (Math.ceil(stringerLength) - 15) * 22.4;
    } else {
        return minimumStringerPrice;
    }
}

export async function formatFlightItemsForOD1(
    returnedFlight:
        | ReturnedSwitchbackFlight
        | ReturnedFlight
        | ReturnedNinetyFlight
        | ReturnedUFlight
        | StraightReturnedFlight
): Promise<Array<OD1_Item>> {
    let items = returnedFlight.items;
    let od1Items: any[] = [];
    let treadMaterial = materials.find((material) => material.name === returnedFlight.treadMaterial);
    let treadFinish = finishOptions.find((finish) => finish.name === returnedFlight.treadFinish);

    let handrailFinish = findHandrailFinish(
        returnedFlight.handrail,
        returnedFlight.handrailMaterial,
        returnedFlight.handrailFinish
    );
    let stringerMaterial = findMetalMaterial(returnedFlight.stringerMaterial);
    let stringerFinish = findMetalFinish(returnedFlight.finishOption);
    let postMaterial = findMetalMaterial("Aluminum");

    items.forEach((item) => {
        let formattedItem = {};

        switch (item.type) {
            case "Standard Post":
                formattedItem = {
                    ProductID: item.productId,
                    Quantity: item.quantity,
                    Description: item.description,
                    FinishOptionID: stringerFinish ? stringerFinish.id : "",
                    fileURL: "",
                };
                break;
            case "Custom Post":
                formattedItem = {
                    ProductID: item.productId,
                    Quantity: item.quantity,
                    Price: item.price,
                    Description: item.description,
                    MaterialID: postMaterial ? postMaterial.id : 73,
                    FinishOptionID: stringerFinish ? stringerFinish.id : "",
                    fileURL: "",
                };
                break;
            case "Handrail":
                formattedItem = {
                    ProductID: item.productId,
                    Quantity: item.quantity,
                    Description: item.description,
                    FinishOptionID: handrailFinish ? handrailFinish.id : "",
                    fileURL: "",
                };
                break;
            case "Hardware":
                formattedItem = {
                    ProductID: item.productId,
                    Quantity: item.quantity,
                    Description: item.description,
                    FinishOptionID: "",
                    fileURL: "",
                };
                break;
            case "Stringer":
                formattedItem = {
                    ProductID: item.productId,
                    Quantity: item.quantity,
                    Price: item.price,
                    Description: item.description,
                    MaterialID: stringerMaterial ? stringerMaterial.id : 72,
                    FinishOptionID: stringerFinish ? stringerFinish.id : "",
                    fileURL: "",
                };
                break;
            case "Nosing":
                formattedItem = {
                    ProductID: item.productId,
                    Quantity: item.quantity,
                    Price: item.price,
                    Description: item.description,
                    MaterialID: treadMaterial ? treadMaterial.id : "",
                    FinishOptionID: treadFinish && treadFinish.id !== 0 ? treadFinish.id : "",
                    fileURL: "",
                };
                break;
            case "Nosing Insert":
                formattedItem = {
                    ProductID: item.productId,
                    Quantity: item.quantity,
                    Price: item.price,
                    Description: item.description,
                    MaterialID: treadMaterial ? treadMaterial.id : "",
                    FinishOptionID: treadFinish && treadFinish.id !== 0 ? treadFinish.id : "",
                    fileURL: "",
                };
                break;
            case "Tread":
                formattedItem = {
                    ProductID: item.productId,
                    Quantity: item.quantity,
                    Price: item.price,
                    Description: item.description,
                    MaterialID: treadMaterial ? treadMaterial.id : "",
                    FinishOptionID: treadFinish && treadFinish.id !== 0 ? treadFinish.id : "",
                    fileURL: "",
                };
                break;
            case "Landing Platform":
                formattedItem = {
                    ProductID: item.productId,
                    Quantity: item.quantity,
                    Price: item.price,
                    Description: item.description,
                    MaterialID: treadMaterial ? treadMaterial.id : "",
                    FinishOptionID: treadFinish && treadFinish.id !== 0 ? treadFinish.id : "",
                    fileURL: "",
                };
                break;
            default:
                break;
        }

        od1Items.push(formattedItem);
    });

    return od1Items;
}
