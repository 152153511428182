import React, { useContext } from "react";
import { ReturnedItem, Item, OrderContext, GeneratorEnum } from "Context/OrderContext";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, CircularProgress } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { RiserFormValues } from "Global/Constants/itemTypes";
import { initialRiserValues } from "Global/Constants/itemDefaults";
import FormTextField from "Components/FormComponents/FormTextField";
import FormSelect from "Components/FormComponents/FormSelect";
import { materials } from "Global/Constants/Materials";
import { getFinishOptions } from "Pages/TreadBuilder/MultiTreadHelpers";
import Button from "Components/Button";
import FormCheckbox from "Components/FormComponents/FormCheckbox";
import { runGenerator } from "Global/Helpers/RunGenerator";

export interface ReturnedRiser extends RiserFormValues, Item {
    finalWidth: number;
    finalDepth: number;
    finalThickness: number;
    boardFeet: number;
    chop: number;
    bunk: string;
    pdfUrl: string;
    rip: number;
    plane: number;
    chopQty: number;
    price: number;
}

function RiserForm(props: RiserFormProps) {
    const { state, dispatch } = useContext(OrderContext);
    const { orderNumber } = state;
    const { loading, setLoading, connectedSocket, setReturnedItem } = props;

    const [expanded, setExpanded] = React.useState<string | false>("panel1");
    const expandIcon = <ExpandMoreIcon className="text-white" />;
    const [initialValues] = React.useState(initialRiserValues());
    //Form Setup
    const methods = useForm<RiserFormValues>({
        defaultValues: initialValues,
    });
    const { handleSubmit, watch, control, reset } = methods;

    const watchFields = watch(["woodSpecies"]);

    //Form Submit
    const onSubmit: SubmitHandler<RiserFormValues> = async (values) => {
        setReturnedItem(undefined);
        setLoading(true);

        if (connectedSocket && orderNumber) {
            let data = {
                ...initialValues,
                ...values,
            };

            delete data.layout;
            if (state.uuid) {
                Object.assign(data, { uuid: state.uuid });
            }

            await runGenerator(GeneratorEnum.Riser, connectedSocket.id, data, orderNumber);
        }
    };

    function handleCancelEdit() {
        dispatch({ type: "CancelEditItem" });
        reset(initialValues);
    }
    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div id="form-wrapper" className="relative top-0 p-0 m-0 grid">
            <div className="w-full p-2 m-0 grid place-content-center">
                <Typography className="w-full text-primary" variant="h5">
                    Riser Generator
                </Typography>
            </div>

            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
                        <AccordionSummary
                            className="accordion"
                            expandIcon={expandIcon}
                            aria-label="Expand"
                            aria-controls="tread-dim-content"
                            id="tread-dim-header"
                        >
                            <div className="w-full p-2 text-white uppercase grid bg-primary">
                                <span>Dimensions</span>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} className="p-2">
                                <Grid item xs={12}>
                                    <FormTextField
                                        name="riserWidth"
                                        variant="outlined"
                                        label="Width (in.)"
                                        type="number"
                                        InputProps={{
                                            inputProps: { min: 6, max: 96, step: 0.0001 },
                                        }}
                                        tooltip="Min 6 -- Max 96"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        variant="outlined"
                                        name="riserDepth"
                                        label="Depth (in.)"
                                        type="number"
                                        InputProps={{
                                            inputProps: { min: 6, max: 9, step: 0.0001 },
                                        }}
                                        tooltip="Min 6 -- Max 9"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        variant="outlined"
                                        name="riserThickness"
                                        label="Thickness (in.)"
                                        type="number"
                                        InputProps={{
                                            inputProps: { min: 0.25, max: 0.75, step: 0.25 },
                                        }}
                                        tooltip={"Min 0.25 -- Max 0.75 -- Increments 0.25"}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            className="accordion"
                            expandIcon={expandIcon}
                            aria-controls="tread-mat-content"
                            id="tread-mat-header"
                        >
                            <Grid item xs={12}>
                                <div className="flex w-full p-2 text-white uppercase bg-primary">
                                    <span>Material</span>
                                </div>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} className="p-2">
                                <Grid item xs={6}>
                                    <FormSelect
                                        name="woodSpecies"
                                        label="Wood Type"
                                        options={materials.map((material) => ({
                                            label: material.name,
                                            value: material.name,
                                        }))}
                                        control={control}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormSelect
                                        name="finishOption"
                                        label="Finish"
                                        options={getFinishOptions(watchFields.woodSpecies)}
                                        control={control}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            className="accordion"
                            expandIcon={expandIcon}
                            aria-controls="landing-mat-content"
                            id="landing-mat-header"
                        >
                            <Grid item xs={12}>
                                <div className="flex w-full p-2 text-white uppercase bg-primary">
                                    <span>Features</span>
                                </div>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} className="p-2">
                                <Grid item xs={6}>
                                    <FormCheckbox label="Distressed" name="distressed" />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormCheckbox label="Hand Scraped" name="handScraped" />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormCheckbox label="Wirebrushed" name="wireBrushed" />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Grid item xs={12}>
                        <Grid item container className="relative p-2 place-content-end">
                            {loading && (
                                <Grid className="absolute grid place-items-end">
                                    <CircularProgress />
                                </Grid>
                            )}
                            {state.uuid && (
                                <Grid className="grid place-items-end">
                                    <Button onClick={() => handleCancelEdit()}>Cancel Edit</Button>
                                </Grid>
                            )}
                            <Grid className="container grid place-items-end">
                                <Button type="submit" tcolor="text-white">
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </FormProvider>
        </div>
    );
}

interface RiserFormProps {
    values?: any;
    onChange?: any;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    connectedSocket: SocketIOClient.Socket | undefined;
    returnedItem: ReturnedItem | undefined;
    setReturnedItem: React.Dispatch<React.SetStateAction<ReturnedItem | undefined>>;
}
export default RiserForm;
