import React, { Dispatch, createContext, PropsWithChildren, useReducer, useEffect } from "react";
import { OrderContextReducer, OrderContextAction } from "./OrderReducer";
import { ReturnedGate } from "../Pages/GateBuilder/GateBuilderForm";
import { ReturnedFlight } from "../Pages/Flight/FlightForm";
import { StraightReturnedFlight } from "../Pages/Flight/StraightFlightForm";
import { ReturnedLanding } from "../Pages/Landing/LandingForm";
import { ReturnedTread } from "../Pages/TreadBuilder/MultiTreadForm";
import { ReturnedNinetyFlight } from "Pages/Flight/NinetyFlightForm";
import { ReturnedSwitchbackFlight } from "Pages/Flight/SwitchbackFlightForm";
import { WarehouseItem } from "Pages/Warehouse";
import { ReturnedRiser } from "Pages/Riser/RiserForm";
import { ReturnedS4S } from "Pages/S4S/S4SForm";
import { ReturnedUFlight } from "Pages/UFlight/UFlightForm";
import { Post, Label, Link, Arrow } from "Global/Constants/layout";
import { GeneratedStatusEnum } from "Global/Constants/itemTypes";
import { ReturnedLevelRun } from "Pages/LevelRun/LevelRunHelpers";
import { useSnackbar, SnackbarAction } from "notistack";
import { ReturnedLandingTread } from "Pages/LandingTreads/LandingTreadForm";

//TODO: consistant naming convention
export enum GeneratorEnum {
    "LevelRun" = "levelRunGenerator",
    "MultiTreads" = "unifiedTreadGenerator",
    "Gate" = "gateGenerator",
    "GlassPanel" = "GlassPanel",
    "FlightEconomy" = "FlightEconomy",
    "FlightCheck" = "wallToWallFlightCheck",
    "Flight" = "flightGenerator",
    "StraightFlight" = "straightFlightGenerator",
    "NinetyFlight" = "ninetyFlightGenerator",
    "SwitchbackFlight" = "switchbackFlightGenerator",
    "UFlight" = "uFlightGenerator",
    "Landing" = "landingGenerator",
    "LandingTread" = "landingTreadGenerator",
    "Riser" = "riserGenerator",
    "S4S" = "s4sGenerator",
    "WarehouseItem" = "WarehouseItem",
}

export type ReturnedItem =
    | ReturnedTread
    | ReturnedGate
    | ReturnedFlight
    | StraightReturnedFlight
    | ReturnedNinetyFlight
    | ReturnedSwitchbackFlight
    | ReturnedUFlight
    | ReturnedLanding
    | ReturnedLandingTread
    | ReturnedRiser
    | ReturnedS4S
    | ReturnedLevelRun
    | WarehouseItem;

export interface Item {
    generator: GeneratorEnum;
    uuid: string;
    name: string;
    pdfUrl: string;
    quantity: number;
}

export interface OrderDefaults extends Record<string, any> {
    readonly infill: string;

    readonly postTop: string;
    readonly postFoot: string;
    readonly postMaterial: string;
    readonly footCoverOption: boolean;

    readonly systemType: string;
    readonly panelThickness: string;
    readonly glassStructureMaterial: string;
    readonly isInterior: boolean;

    readonly handrailScrewOption: string;
    readonly handrail: string;
    readonly handrailMaterial: string;
    readonly handrailFinish: string;
}

export interface OrderContextState {
    readonly orderNumber: string | null;
    readonly customerName: string | null;
    readonly items: Array<ReturnedItem>;
    readonly uuid: string | null;
    readonly posts: Record<string, Post>;
    readonly labels: Record<string, Label>;
    readonly links: Array<Link>;
    readonly defaults: false | OrderDefaults;
    readonly arrows: Record<string, Arrow>;
}

interface DefaultOrderContextValues {
    state: OrderContextState;
    dispatch: Dispatch<OrderContextAction>;
}

export const OrderContext = createContext({} as DefaultOrderContextValues);

export const getBlankOrderContextState = (): OrderContextState => ({
    orderNumber: null,
    customerName: null,
    uuid: null,
    items: [],
    posts: {},
    labels: {},
    links: [],
    defaults: false as false,
    arrows: {},
});

const initialReducerState: OrderContextState = getBlankOrderContextState();

function OrderContextProvider(props: PropsWithChildren<{}>) {
    const stateFromStorage = localStorage.getItem("OrderState");
    const [state, dispatch] = useReducer(
        OrderContextReducer,
        stateFromStorage ? JSON.parse(stateFromStorage) : initialReducerState
    );
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const action: SnackbarAction = (key) => {
        return (
            <>
                <button
                    onClick={() => {
                        window.location.reload(false);
                        closeSnackbar(key);
                    }}
                >
                    Reload
                </button>
            </>
        );
    };

    function stateUpToDate() {
        const initialKeys = Object.keys(initialReducerState);
        let keysFromStorage: Array<string>;
        if (stateFromStorage) {
            keysFromStorage = Object.keys(JSON.parse(stateFromStorage));

            if (JSON.stringify(initialKeys) !== JSON.stringify(keysFromStorage)) {
                return false;
            }
        }
        return true;
    }

    React.useEffect(() => {
        const event = (e: any) => {
            const generating = state.items.find(
                ({ generatedStatus }: any) => generatedStatus === GeneratedStatusEnum.Generating
            );

            if (generating) {
                dispatch({ type: "ClearGeneratingStatus" });
                e.preventDefault();
                e.returnValue = "";
            }
        };

        window.addEventListener("beforeunload", event);

        return () => {
            window.removeEventListener("beforeunload", event);
        };
    });

    useEffect(() => {
        if (!stateUpToDate()) {
            localStorage.setItem("OrderState", JSON.stringify(initialReducerState));
            enqueueSnackbar("Update Application", { variant: "warning", persist: true, action });
        } else {
            localStorage.setItem("OrderState", JSON.stringify(state));
        }
    }, [state]); //eslint-disable-line

    return <OrderContext.Provider value={{ state, dispatch }}>{props.children}</OrderContext.Provider>;
}

export default OrderContextProvider;
