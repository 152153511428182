export const PostInfill = [
    {
        name: "Cable",
        topStyle: [
            {
                name: "Universal Top",
                footStyle: [
                    {
                        name: "Standard Foot",
                        productTypeGroupID: 3,
                        productTypeID: 73,
                    },
                    {
                        name: "Cordedrill",
                        productTypeGroupID: 3,
                        productTypeID: 107,
                    },
                    {
                        name: "Sidemount",
                        productTypeGroupID: 3,
                        productTypeID: 75,
                    },
                    {
                        name: "Slim Sidemount",
                        productTypeGroupID: 3,
                        productTypeID: 102,
                    },
                    {
                        name: "Slim Sidemount Bumpout",
                        productTypeGroupID: 3,
                        productTypeID: 104,
                    },
                    {
                        name: "Special Application Foot",
                        productTypeGroupID: 3,
                        productTypeID: 105,
                    },
                    {
                        name: "Tread Sidemount 3.5",
                        productTypeGroupID: 3,
                        productTypeID: 210,
                    },
                    {
                        name: "Tread Sidemount 4",
                        productTypeGroupID: 3,
                        productTypeID: 210,
                    },
                ],
            },
            {
                name: "Flat Top",
                footStyle: [
                    {
                        name: "Standard Foot",
                        productTypeGroupID: 3,
                        productTypeID: 108,
                    },
                    {
                        name: "Cordedrill",
                        productTypeGroupID: 3,
                        productTypeID: 113,
                    },
                    {
                        name: "Sidemount",
                        productTypeGroupID: 3,
                        productTypeID: 112,
                    },
                    {
                        name: "Slim Sidemount",
                        productTypeGroupID: 3,
                        productTypeID: 110,
                    },
                    {
                        name: "Slim Sidemount Bumpout",
                        productTypeGroupID: 3,
                        productTypeID: 111,
                    },
                    {
                        name: "Special Application Foot",
                        productTypeGroupID: 3,
                        productTypeID: 109,
                    },
                    {
                        name: "Tread Sidemount 3.5",
                        productTypeGroupID: 3,
                        productTypeID: 210,
                    },
                    {
                        name: "Tread Sidemount 4",
                        productTypeGroupID: 3,
                        productTypeID: 210,
                    },
                ],
            },
        ],
        productTypeGroupID: 3,
        productTypeID: 73,
    },
    {
        name: "Rod",
        topStyle: [
            {
                name: "Universal Top",
                footStyle: [
                    {
                        name: "Standard Foot",
                        productTypeGroupID: 26,
                        productTypeID: 175,
                    },
                    {
                        name: "Cordedrill",
                        productTypeGroupID: 26,
                        productTypeID: 183,
                    },
                    {
                        name: "Sidemount",
                        productTypeGroupID: 26,
                        productTypeID: 184,
                    },
                    {
                        name: "Slim Sidemount",
                        productTypeGroupID: 26,
                        productTypeID: 195,
                    },
                    {
                        name: "Slim Sidemount Bumpout",
                        productTypeGroupID: 26,
                        productTypeID: 191,
                    },
                    {
                        name: "Special Application Foot",
                        productTypeGroupID: 26,
                        productTypeID: 189,
                    },
                    {
                        name: "Tread Sidemount 3.5",
                        productTypeGroupID: 26,
                        productTypeID: 212,
                    },
                    {
                        name: "Tread Sidemount 4",
                        productTypeGroupID: 26,
                        productTypeID: 212,
                    },
                ],
            },
            {
                name: "Flat Top",
                footStyle: [
                    {
                        name: "Standard Foot",
                        productTypeGroupID: 26,
                        productTypeID: 176,
                    },
                    {
                        name: "Cordedrill",
                        productTypeGroupID: 26,
                        productTypeID: 182,
                    },
                    {
                        name: "Sidemount",
                        productTypeGroupID: 26,
                        productTypeID: 185,
                    },
                    {
                        name: "Slim Sidemount",
                        productTypeGroupID: 26,
                        productTypeID: 194,
                    },
                    {
                        name: "Slim Sidemount Bumpout",
                        productTypeGroupID: 26,
                        productTypeID: 190,
                    },
                    {
                        name: "Special Application Foot",
                        productTypeGroupID: 26,
                        productTypeID: 188,
                    },
                    {
                        name: "Tread Sidemount 3.5",
                        productTypeGroupID: 26,
                        productTypeID: 212,
                    },
                    {
                        name: "Tread Sidemount 4",
                        productTypeGroupID: 26,
                        productTypeID: 212,
                    },
                ],
            },
        ],
        productTypeGroupID: 26,
        productTypeID: 175,
    },
    {
        name: "Onyx Rod",
        topStyle: [
            {
                name: "Universal Top",
                footStyle: [
                    {
                        name: "Standard Foot",
                        productTypeGroupID: 3,
                        productTypeID: 175,
                    },
                    {
                        name: "Cordedrill",
                        productTypeGroupID: 3,
                        productTypeID: 185,
                    },
                    {
                        name: "Sidemount",
                        productTypeGroupID: 3,
                        productTypeID: 184,
                    },
                    {
                        name: "Slim Sidemount",
                        productTypeGroupID: 3,
                        productTypeID: 195,
                    },
                    {
                        name: "Slim Sidemount Bumpout",
                        productTypeGroupID: 3,
                        productTypeID: 191,
                    },
                    {
                        name: "Special Application Foot",
                        productTypeGroupID: 3,
                        productTypeID: 189,
                    },
                    {
                        name: "Tread Sidemount 3.5",
                        productTypeGroupID: 3,
                        productTypeID: 212,
                    },
                    {
                        name: "Tread Sidemount 4",
                        productTypeGroupID: 3,
                        productTypeID: 212,
                    },
                ],
            },
            {
                name: "Flat Top",
                footStyle: [
                    {
                        name: "Standard Foot",
                        productTypeGroupID: 3,
                        productTypeID: 176,
                    },
                    {
                        name: "Cordedrill",
                        productTypeGroupID: 3,
                        productTypeID: 182,
                    },
                    {
                        name: "Sidemount",
                        productTypeGroupID: 3,
                        productTypeID: 185,
                    },
                    {
                        name: "Slim Sidemount",
                        productTypeGroupID: 3,
                        productTypeID: 194,
                    },
                    {
                        name: "Slim Sidemount Bumpout",
                        productTypeGroupID: 3,
                        productTypeID: 190,
                    },
                    {
                        name: "Special Application Foot",
                        productTypeGroupID: 3,
                        productTypeID: 188,
                    },
                    {
                        name: "Tread Sidemount 3.5",
                        productTypeGroupID: 3,
                        productTypeID: 212,
                    },
                    {
                        name: "Tread Sidemount 4",
                        productTypeGroupID: 3,
                        productTypeID: 212,
                    },
                ],
            },
        ],
        productTypeGroupID: 26,
        productTypeID: 175,
    },
    {
        name: "Glass",
        productTypeGroupID: 5,
        productTypeID: 130,
    },
];

export const PostFootStyles = [
    {
        name: "Standard Foot",
        PostFootStyleID: 1,
    },
    {
        name: "Coredrill",
        PostFootStyleID: 11,
    },
    {
        name: "Sidemount",
        PostFootStyleID: 3,
    },
    {
        name: "Slim Sidemount",
        PostFootStyleID: 6,
    },
    {
        name: "Slim Sidemount Bumpout",
        PostFootStyleID: 7,
    },
    {
        name: "Special Application Foot",
        PostFootStyleID: 8,
    },
    {
        name: "Tread Sidemount 3.5",
        PostFootStyleID: 14,
    },
    {
        name: "Tread Sidemount 4",
        PostFootStyleID: 15,
    },
];

export const PostTopStyles = [
    {
        name: "Universal Top",
        PostTopStyleID: 1,
    },
    {
        name: "Flat Top",
        PostTopStyleID: 5,
    },
    {
        name: "Barrier Top",
        PostTopStyleID: 9,
    },
];

export function getPostTopStyles() {
    return PostTopStyles.map((style) => ({ label: style.name, value: style.name }));
}
export function findPostTopStyle(topStyle: string) {
    let foundTopStyle = PostTopStyles.find((style) => style.name === topStyle);
    return foundTopStyle;
}
export function getPostFootStyles() {
    return PostFootStyles.map((style) => ({ label: style.name, value: style.name }));
}
export function findPostFootStyle(footStyle: string) {
    let foundFootStyle = PostFootStyles.find((style) => style.name === footStyle);
    return foundFootStyle;
}
export function findPostInfillID(infillName: string) {
    return PostInfill.find((infill) => infill.name === infillName);
}
